import { Button } from "@fluentui/react-northstar";

export default function Privacy(props:{}) {

    return (
    <div className="privacyarea">
        <h2>What is GDPR?</h2>
        <p>General Data Protection Regulation (GDPR) is designed to harmonize and protect data privacy laws across Europe, taking effect in May 2018. It empowers all EU citizens’ data policy and reshapes the way organizations across Europe approach data privacy.</p>
        

       
        <p className="bullet"></p>
       
        <h2>What is Annabell’s approach to GDPR?</h2>

        <p>We care about your data privacy as much as you do. In Annabell we continuously review and improve our data policy to ensure the best compliance to our legal obligations. GDPR offers yet another opportunity to continue Annabell’s tradition of protecting our users’ personal and organizational data.</p>

        <h2>Collection and usage of personal data</h2>

        <p>In Annabell, the personal information we collect includes name, email, job title, company and phone number when you sign up for our events and subscribe to our newsletter. By providing us with your data, you are also giving your consent to receive communication about our services and products.</p>

        <p>You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose not to provide data that is necessary to a product, service or event, you may not be able to use that product or service or attend the event.</p>

        <p>The data we collect will be used to (1) improve your personal experience with our products and services; (2) improve our communication with you; (3) and analyze and improve Annabell products and services. For example, we may send you email invitations or call you by phone to inform you about specific events or topics that are relevant to your interests.</p>

        <p>You can withdraw your consent and unsubscribe from our newsletter at any time.</p>

        <h2>Collection and Use of Information about Your Computer</h2>

        <p>When you use software with Internet-enabled features, information about your computer (“standard computer information”) is sent to the pages you visit and online services you use. Annabell uses standard computer information to provide you with Internet-enabled services, to help improve our products and services, and for statistical analysis. Standard computer information typically includes information such as your IP address, operating system version, browser version, and regional and language settings, but all these are NOT stored by Annabell except for statistical counting without the before mentioned Standard computer information.</p>

        <h2>Changes to this privacy statement</h2>

        <p>We will continuously update this privacy statement to reflect changes in our products, services, and customer feedback. When we post changes, we will revise the “last updated” date at the bottom of this statement. We encourage you to periodically review this Statement to be informed of how Annabell software is protecting your information.</p>

        <h2>Privacy App Statement</h2>

        <p>The information we collect from you will be used to enable the features you are using and provide the service(s) or carry out the transaction(s) you have requested or authorized. It may also be used to analyze and improve Annabell products and services.</p>

        <p>Personal information is used for managing the subscription-only or for delivering the purchased services and goods from the website. With respect to purchases, the information will be applied on invoices and kept in our accounts for up to 10 years.</p>
        
        <h1>Cookie Policy</h1>
        <h2>About this cookie policy</h2>
        <p>This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used, and how to control the cookie preferences. For further information on how we use, store, and keep your personal data secure, see our Privacy Policy.</p>

        <p>You can at any time change or withdraw your consent from the Cookie Declaration on our website.</p>

        <p>Learn more about who we are, how you can contact us, and how we process personal data in our Privacy Policy on the top of this page.</p>

        <p>Your consent applies to the following domains: annabell.online</p>


        <h2>What are cookies?</h2>
        <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
        <h2>How do we use cookies?</h2>
        <p>As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</p>

        <p>The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website</p>

        <h2>What types of cookies do we use?</h2>
        <p>Essential: Some cookies are essential for you to be able to experience the full functionality of our site. They allow us to maintain user sessions and prevent any security threats. They do not collect or store any personal information. For example, these cookies allow you to log-in to your account and add products to your basket, and checkout securely.</p>

        <p>Statistics: These cookies store information like the number of visitors to the website, the number of unique visitors, which pages of the website have been visited, the source of the visit, etc. These data help us understand and analyze how well the website performs and where it needs improvement.</p>

        <p>Marketing: Our website displays advertisements. These cookies are used to personalize the advertisements that we show to you so that they are meaningful to you. These cookies also help us keep track of the efficiency of these ad campaigns.</p>

        <p>The information stored in these cookies may also be used by the third-party ad providers to show you ads on other websites on the browser as well.</p>

        <p>Functional: These are the cookies that help certain non-essential functionalities on our website. These functionalities include embedding content like videos or sharing content of the website on social media platforms.</p>

        <p>Preferences: These cookies help us store your settings and browsing preferences like language preferences so that you have a better and efficient experience on future visits to the website.</p>
        
        <p>Technical: ‍Some cookies ensure that certain parts of the website work properly and that your user preferences remain known. By placing technical cookies, we make it easier for you to visit our website. This way, you do not need to repeatedly enter the same information when visiting our website and, for example, the items remain in your shopping cart until you have paid. We may place these cookies without your consent.</p>

        <h2>How can I control the cookie preferences?</h2>
        <p>Should you decide to change your preferences later through your browsing session, you can click on the “Delete All Cokies” on the bottom of this page. This will delete all cookies including cookies used in any of our apps.</p>

        <p>In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies.</p>
        <div style={{float:'right'}}>Last Updated: 2024/04/29</div>
        <div style={{margin:'auto'}}><Button primary onClick={()=>{
            const cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            alert("All cookies are deleted successfully!");
        } }>Delete All Cokies</Button></div>
   </div>);
}