import { Checkbox, Flex, Tooltip } from "@fluentui/react-northstar";
import { ViewMode } from "../../gantt";
import { ZoomControl } from "./ZoomControl";
import { useState } from "react";
import { PrintGantt } from "./print/PrintGantt";
import { TeamsFx } from "@microsoft/teamsfx";
import { GanttPlan, GanttTask, Person } from "../../../ganttmods/publictypes";

export function GanttChatControls(
    props: 
    { 
        periodvalue:ViewMode, 
        onChangeZoom:Function, 
        onChangePeriod:Function, 
        viewstart:Date|undefined, 
        viewend:Date|undefined, 
        planid:string, 
        showCompletedTasksDefaultValue:boolean, 
        onShowCompletedTasksChanged:Function, 
        teamsfx?: TeamsFx, plans:GanttPlan[], 
        people:Person[] | null, 
        getTasksForPrint:Function | null,
        viewMode:ViewMode
    }) 
{
    const [printdialogopen, setPrintDialogOpen] = useState<boolean>(false); 
    return (<>
        <Flex gap="gap.small" style={{marginTop:20}} hAlign="center" vAlign="center">
            <PrintGantt teamsfx={props.teamsfx} plans={props.plans} people={props.people} getTasksForPrint={props.getTasksForPrint as any} />
            <Tooltip content={props.showCompletedTasksDefaultValue?"Hide completed tasks":"Show completed tasks"} pointing trigger=
                {
                    <Checkbox checked={props.showCompletedTasksDefaultValue} onChange={(v)=>props.onShowCompletedTasksChanged(v)} label="" toggle />
                } />
            <ZoomControl periodvalue={ViewMode.Year} onChangeZoom={props.onChangeZoom} onChangePeriod={props.onChangePeriod} viewstart={props.viewstart} viewend={props.viewend} planid={props.planid} />
        </Flex>
    </>);
}

 /*<Flex gap="gap.small" style={{marginTop:50}} hAlign="center" vAlign="center">
<Tooltip content="Show completed tasks" pointing trigger=
    {
    <Checkbox checked={showCompletedTasks} onChange={(v)=>{setShowCompletedTasks(!showCompletedTasks);}} label="" toggle />
    } />
<ZoomControl periodvalue={ViewMode.Year} onChangeZoom={zoomchanged} onChangePeriod={periodchanged} viewstart={viewstart} viewend={viewend} planid={planid} />
</Flex>*/