import React, { useState } from "react";
import { getProgressPoint } from "../../../helpers/bar-helper";
import { BarDisplay } from "./bar-display";
import { BarProgressHandle } from "./bar-progress-handle";
import { TaskItemProps } from "../task-item";
import styles from "./bar.module.css";

export const BarSmall: React.FC<TaskItemProps> = ({
  task,
  isProgressChangeable,
  isDateChangeable,
  onEventStart,
  isSelected,
  onLinkStartDrag,
  highlightOnMouseHover,
  onLinkStopDrag,
  labels,
  labelsclicked,
  showLabelTitles,
}) => {
  const progressPoint = getProgressPoint(
    task.progressWidth + task.x1,
    task.y,
    task.height
  );
  const [hovertskid, sethovertskid] = useState<string | null>('');
  
  return (
    <g className={!isSelected?styles.barWrapperAni:styles.barWrapper} tabIndex={0}>
      <BarDisplay
        x={task.x1}
        y={task.y}
        width={task.x2 - task.x1}
        height={task.height}
        progressX={task.progressX}
        progressWidth={task.progressWidth}
        barCornerRadius={task.barCornerRadius}
        styles={task.styles}
        isSelected={isSelected}
        onMouseDown={e => {
          isDateChangeable && onEventStart("move", task, e);
        }}
        onMouseEnter={(e)=>{console.log('enter task2 ', task.id); sethovertskid(task.id);}}
        onMouseLeave={(e)=>{sethovertskid(null);}}
        onMouseUp={(e)=>{onLinkStopDrag(task);}}
        highlight={highlightOnMouseHover}
        labels={labels}
        labelsclicked={labelsclicked}
        showLabelTitles={showLabelTitles}
      />
      <g className="handleGroup">
        <g>
          <rect className={styles.dependencyrect} x={task.x2} y={task.y} width={task.height} height={task.height} stroke="none" strokeWidth="0" fill="transparent" />
          <circle className={styles.dependencyLink} cx={task.x2+task.height/2} cy={task.y+task.height/2} r={task.height/4} stroke="none" strokeWidth="0" onMouseDown={()=>onLinkStartDrag(task)}  />
        </g>
        {isProgressChangeable && (
          <BarProgressHandle
            progressPoint={progressPoint}
            onMouseDown={e => {
              onEventStart("progress", task, e);
            }}
          />
        )}
      </g>
    </g>
  );
};
