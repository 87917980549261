// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Checkbox, ComponentEventHandler, Dialog, Dropdown, DropdownProps, Flex, Grid, Input, Loader, Menu, MenuProps, ParticipantRemoveIcon, TeamCreateIcon, TextArea, Tooltip } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";

import { getFromLocalCache, log, saveToLocalCache, shhlog } from "../helper";
import "./Subscriptions.css"

export function LicensedUser(props: {orgid:string, user:string, adminchecked:boolean, unassignlicense:Function, admincheckchanged:Function}) {
 


 //const [lisencedusers, setLicensedusers] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(true);

  
  

  useEffect(()=>{
    setLoading(false);
     
    }, []);
  
    const unassignlicense = async (email:string):Promise<void> => 
    {
      try
      {
          setLoading(true);
          let formattedorgid = props.orgid;
          let usermail = email;
          const fullfillmentrequestOptions = {
          method: 'POST',
          };
          let assignresponse = await fetch('https://plannerganttapi.azurewebsites.net/LicenseUser?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&useremail='+email.toLowerCase()+'&orgid=' + formattedorgid + '&license=unassign', fullfillmentrequestOptions)
          if (assignresponse.status == 200)
          {
            props.unassignlicense(email);
          } else
          {
              log("unassignlicense error", assignresponse);
          }
          log("unassignlicense", assignresponse);
          setLoading(false);
      } catch (ex:any)
      {
        log("unassignlicense error", ex);
      }
    }
 
    const admincheckchanged = async (e:any,v:any):Promise<void> => 
    {//(e,v)=>{log("toggle",e,v);}
      try
      {
        log('Admin check changed', props.adminchecked, v.checked);
        
        setLoading(true);
        let formattedorgid = props.orgid;
        let usermail = 'email';
        const fullfillmentrequestOptions = {
        method: 'POST',
        };
        let perm = v.checked ? 'assign' : 'unassign';
        let adminresponse = await fetch('https://plannerganttapi.azurewebsites.net/AdminUser?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&useremail='+props.user.toLowerCase()+'&orgid=' + formattedorgid + '&license=' + perm , fullfillmentrequestOptions)
        if (adminresponse.status == 200)
        {
          props.admincheckchanged(props.user, v.checked);
        } else
        {
            log("unassignlicense error", adminresponse);
        }
        
        setLoading(false);
      } catch (ex:any)
      {
        log("admincheckchanged error", ex);
        setLoading(false);
      }
    }

  return (
    <> {/*Improtant as the below rows are part of a grid */}
        {loading? (<Loader  size="small" style={{gridColumn: 'span 3'}} />) : 
            <>
                <div className="subemail" style={{marginLeft:10, maxWidth:'30rem'}}>{props.user.length > 62 ? (<Tooltip content={<span style={{overflowWrap:'break-word'}}>{props.user}</span>} trigger={<div>{props.user.slice(0, 59) + '...'}</div>}  />)   :props.user}</div>
                <div><Tooltip content="Give access to manage licenses" trigger={<Checkbox label="" checked={props.adminchecked} toggle onClick={admincheckchanged} />} /></div>
                <Button className="subemailbtn" onClick={()=>{unassignlicense(props.user);}} title="Remove" icon={<Tooltip content="Remove license from user" trigger={<ParticipantRemoveIcon />} />}  iconOnly circular/>
            </>
        }
    </>
  );
}
