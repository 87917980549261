// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Card, Dropdown, DropdownProps, Flex, Loader, TextArea, Tooltip, Text, CloseIcon, Avatar, AcceptIcon } from "@fluentui/react-northstar";

import { useState } from "react";
import { log, randomNumberFromText } from "../../helper";
import { GanttPlan } from "../../../ganttmods/publictypes";

export function PlanCard(props: { title:string, subtitle:string, plan:GanttPlan, onSelect:(plan:GanttPlan)=>void, closeBtn:boolean, selectected?:boolean }) 
{
  const [content, setContent] = useState<string | undefined>(undefined);
  

  return (
    <Card aria-roledescription="Plan, please select the plan you wish to open" elevated style={{height:80, maxHeight:80, marginTop:20, marginBottom:0, marginRight:30, marginLeft:0, width:'20em', minWidth:'20em', backgroundColor:props.selectected?'#0f6cbd':''}} className="avatarcard" 
      onClick={()=>
        {
          if (!props.closeBtn) 
          {props.onSelect(props.plan)}
        }
      }>
    <Card.Header>
      <Flex space="between">
        <Flex gap="gap.small" >
          
          <Avatar name={props.title} square size={'large'} className={"planavatarclstxt planavatarcls" + randomNumberFromText(props.plan.planid, 20)} />
          
          <Flex column>
            {(props.title.length>=23)?
              <Tooltip content={props.title}><Text content={props.title.substring(0,23) + "..."} weight="bold" /></Tooltip>
            :
              <Text content={props.title} weight="bold" />
            }
            <Text content={props.subtitle} size="small" />
          </Flex>
          
        </Flex>
        
          {props.closeBtn?<Button icon={<CloseIcon />} iconOnly text title="Close Plan" onClick={()=>{props.onSelect(props.plan);}} />:<></>}
          {props.selectected && <Flex hAlign="end" vAlign="center"><AcceptIcon /></Flex>}
      </Flex>
    </Card.Header>
    {/*<Card.Body>
      <Flex column gap="gap.small">
        
        <Text content="Content text" />
      </Flex>
    </Card.Body>*/}
  </Card>
  );
}
