import React from 'react';

export function PrintIcon(props:{ width:number, height:number, color:string, onClick:()=>void }) 
{
  
  return (<div style={{cursor:'pointer', position:'absolute', right:'5rem', bottom:'2rem', zIndex:100}} onClick={props.onClick}>
        <svg 
            width={props.width}
            height={props.height} 
            viewBox="0 0 64 64" 
            xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" strokeWidth={2}>
            
            
            <polyline points="20 16 20 8 44 8 44 16" fill='white'/>
            <polyline points="16 40 8 40 8 16 56 16 56 40 48 40" fill='white'/>
            <rect x="16" y="32" width="32" height="24" fill='white'/>
            <line x1="24" y1="48" x2="40" y2="48"/>
            <line x1="24" y1="40" x2="40" y2="40"/>
        </svg>
    </div>)
}