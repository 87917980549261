// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Dialog, Dropdown, DropdownProps, Flex, Grid, Loader, Pill, Popup, Segment, Text } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState, useContext, SyntheticEvent } from "react";
import { GanttTableHeader } from "../../../ganttmods/GanttTableHeader";
import GanttTooltipContent from "../../../ganttmods/GanttTooltipContent";
import { ErrorSection } from "../../errorandlogs/ErrorSection";
import { Gantt, Task, ViewMode } from "../../gantt";
import { TaskListTableDefault } from "../../gantt/components/task-list/task-list-table";
import { getMaxDate, getMinDate, getNextTaskOrProject, getStartEndDateForProject, getStartEndDateForPlan, getTaskAfter, getTaskBefore, getTaskProject, log, ordinalCompareStr1BeforeStr2 } from "../../helper";
import { GanttTaskDialog, TaskAction } from "./taskdetails/GanttTaskDialog";
import { addDaysToDate } from "../../helper";
import { TeamsFxContext } from "../../Context";
import { GanttPlan, GanttPlanDetails, GanttTask, Label, Person } from "../../../ganttmods/publictypes";
import { AssignmentDialog } from "./AssignmentDialog";
import { teams } from "@microsoft/teams-js";

export function PlanGanttOperator(
    props: 
    { 
        teamsfx?: TeamsFx, plans:GanttPlanDetails[], tasks:GanttTask[], viewMode:ViewMode, viewModeZoomLevel:number, showCompletedTasks:boolean, planloaded:Function,  
        savePlannerTask: (planid:string, taskid:string, start:Date, end:Date) => Promise<string>,
        savePlannerTaskDetails: (planid:string, taskid:string, start:Date, end:Date, name:string, percentComplete:number, labels:Label[]) => Promise<string>,
        newPlannerTask:  (planid:string, bucketid:string, start:Date, end:Date, name:string, beforetask:Task | undefined, aftertask:Task | undefined) => Promise<void>,
        //newTaskOrder: (planid:string, taskid:string, orderHint:string) => Promise<any>,
        rearrangetasks:(planid:string, beforetask:Task | undefined, aftertask:Task | undefined, tasktomove:Task) => Promise<string>
        savePlannerAttacmentDependencies: (taskid:string, dependentOnTaskId:string, dependentOnTaskName:string, dependentOnPlanId:string, dependentOnGroupId:string, add:boolean) => Promise<void>
        askforpermissions:()=>void,
        addAssignment:(planid:string, taskid:string, userid:string)=>Promise<void> 
        deleteAssignment:(planid:string, taskid:string, assignmentid:string)=>Promise<void> 
        people:Person[] | null,
        peopleLoaded:(people:Person[])=>void,
    }) 
{
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
  };
  const [loadedplansprogress, setloadedplansprogress] = useState<number>(0);
  
  const [tasks, setTasks] = useState<GanttTask[]>([]);
  //const [loadedPlanId, setLoadedPlanId] = useState<string | null>(props.planid);
  //const [loadedPlanName, setLoadedPlanName] = useState<string>(props.planName?props.planName:"");
  const [detailsmenyPlan, setDetailsmenyPlan] = useState<GanttPlanDetails | null>(null);
  //const [showCompletedTasks, setShowCompletedTasks] = useState<boolean>(props.showCompletedTasks);
  const [detailsMenuVisible, setDetailsMenuVisible] = useState<boolean>(false);
  const [detailsMenuTask, setDetailsMenuTask] = useState<Task | null>(null);
  const [menuaction, setMenuaction] = useState<TaskAction>("update");
  const { themeString } = useContext(TeamsFxContext);
  
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    setTasks(props.tasks);
    
  }, [props.tasks]);

 

  useEffect(()=>
  {
    
  }, [props.viewMode, props.viewModeZoomLevel]);
  const handleTaskChange = (task: Task) => {
    let gantttask:GanttTask = task as GanttTask;
    let newTasks = tasks.map(t => (t.id === task.id ? task as GanttTask : t));
    //update bucket
    if (task.project) 
    {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
      {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t => t.id === task.project ? changedProject : t);
      }
      //Update plan if more than one plan is selected
      if (props.plans.length >= 2)
      {
        const plan = newTasks[newTasks.findIndex(t => t.id === task.planid)];
        const [start, end] = getStartEndDateForPlan(newTasks, task.planid);
        if (plan.start.getTime() !== start.getTime() || plan.end.getTime() !== end.getTime()) 
        {
          const changedPlan = { ...plan, start, end };
          newTasks = newTasks.map(t => t.id === task.planid ? changedPlan : t);
        }
      }
    }
    props.savePlannerTask(task.planid, task.id, task.start, task.end);
    setTasks(newTasks);
  };
  const handleExpanderClick = (task: Task) => {
      
    setTasks(tasks.map(t => (t.id === task.id ? task as GanttTask : t)));
  };
  const handlePlanExpanded = (task: Task) => { 
    let hideChildren:boolean = task.hideChildren == undefined?false:task.hideChildren;
    let newTasks = tasks.map(t => {
      if (t.id == task.id)
      {
        let newtask = {...t};
        newtask.hideChildren = !hideChildren;
        return newtask;
      }
      if (t.planid === task.id && t.type == 'project')
      {
        let newtask = {...t};
        newtask.hideChildren = !hideChildren;
        return newtask;
        
      } else 
      {
        return t;
      }
    });
    setTasks(newTasks);
  };
  const updateTask = (newtasks:any[], planid:string, taskid:string, taskStart:Date, taskDue:Date, taskName:string, percentComplete:number, action:TaskAction, labels:Label[]) => {
    
    setTasks(newtasks);
    if (props.savePlannerTaskDetails != undefined)
    {
        props.savePlannerTaskDetails(planid, taskid, taskStart, taskDue, taskName, percentComplete, labels);
    } else {log("ERROR: UpdateTasks is not ready");}
    setDetailsMenuVisible(false);
    setDetailsMenuTask(null);
  }
  const marktaskcompleted = (tsk:GanttTask) => {
    log('Task Marked as Completed', tsk);
    let progress = tsk.progress == undefined?0:tsk.progress;
    let newTasks = tasks.map(t => {
      if (t.id === tsk.id)
      {
        let newtask = {...t};
        if (newtask.progress != 100)
        {
          //newtask.percentComplete = 100;
          newtask.progress = 100;
          progress = 100;
          newtask.styles = { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' };
        } else
        {
          //newtask.percentComplete = 0;
          newtask.progress = 0;
          progress = 0;
          newtask.styles = { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' };
        }
        return newtask;
        
      } else 
      {
        return t;
      }
    });
    updateTask(newTasks, tsk.planid, tsk.id, tsk.start, tsk.end, tsk.name, progress, "update", tsk.labels);
    
  }
  const insertTask = async (planid:string, bucketid:string, newtasks:any[], taskafterid:string, taskStart:Date, taskDue:Date, taskName:string, action:TaskAction, beforetask:Task | undefined, aftertask:Task | undefined) => {
    
    if (props.newPlannerTask != undefined)
    {
      return props.newPlannerTask(planid, bucketid, taskStart, taskDue, taskName, beforetask, aftertask)
        .then((v:any)=>
        {
          //update orderhint in list
         
          let newtasklist = newtasks.map((t)=>{return t.name == taskName?{...t, id:v.id, orderHint:v.orderHint}:t});
          log("Task-List updated with new task", newtasklist);
          setTasks(newtasklist);
          setDetailsMenuVisible(false);
          setDetailsMenuTask(null);
          setDetailsMenuVisible(false);
          setDetailsMenuTask(null);
          
          return v;
        });
    } else 
    {
      setDetailsMenuVisible(false);
      setDetailsMenuTask(null);
      setDetailsMenuVisible(false);
      setDetailsMenuTask(null);
      log("ERROR: InsertTask is not ready");
    }
  }
  const moveTaskUp = async (tsk:Task) => 
  {
    log("Move Task Up", tsk, tasks);
    let tskbefore = getTaskBefore(tsk, tasks);
    if (tskbefore == undefined)
    {return;}
    let tskbeforebefore = getTaskBefore(tskbefore, tasks);
    let neworderhint = await props.rearrangetasks(tsk.planid, tskbeforebefore, tskbefore, tsk);

    
    /*
        let neworderhint = " ";
        if (props.newTaskOrder != undefined)
        {
          
          let neworderhintrslt = await props.newTaskOrder(tsk.planid, tsk.id, (tskbeforebefore != undefined && tskbeforebefore.orderHint != undefined?tskbeforebefore.orderHint as string:"")+ ' ' + (tskbefore != undefined && tskbefore.orderHint != undefined?tskbefore.orderHint:"")  + "!");
          neworderhint = neworderhintrslt.orderHint;
          
        }
    */

    let newtasklist:Task[] = [...tasks];
    for (let i=0;i<newtasklist.length;i++)
    {
      if (newtasklist[i].id == tsk.id)
      {
        if (tskbefore != undefined && tskbeforebefore != undefined && tskbefore.displayOrder != undefined && tskbeforebefore.displayOrder != undefined)
        {
          newtasklist[i].displayOrder = tskbeforebefore?.displayOrder + (tskbefore?.displayOrder-tskbeforebefore?.displayOrder)/2;
          newtasklist[i].orderHint =  neworderhint;
        }
        if (tskbefore != undefined && tskbeforebefore == undefined && tskbefore.displayOrder != undefined)
        {
          let projecttsk:Task = getTaskProject(tskbefore, tasks);
          if (projecttsk?.displayOrder != undefined)
          {
            newtasklist[i].displayOrder = projecttsk?.displayOrder + (tskbefore?.displayOrder-projecttsk?.displayOrder)/2;
          }
        }
      }
    }
    log("Task-List updated after move up", newtasklist);
    setTasks([...tasks]);
    
  }
  const moveTaskDown = async (tsk:Task) => 
  {
    log("MoveTaskDown", tsk, tasks);
    let tskafter = getTaskAfter(tsk, tasks);
    if (tskafter == undefined)
    {return;}
    let tskafterafter = getTaskAfter(tskafter, tasks);
    let neworderhint = await props.rearrangetasks(tsk.planid, tskafter, tskafterafter, tsk);
    /*if (props.newTaskOrder != undefined)
    {
        props.newTaskOrder(tsk.planid, tsk.id, (tskafter != undefined && tskafter.orderHint != undefined?tskafter.orderHint as string:"")+ ' ' + (tskafterafter != undefined && tskafterafter.orderHint != undefined?tskafterafter.orderHint:"")  + "!");
    }*/
    let newtasklist:Task[] = [...tasks];
    for (let i=0;i<newtasklist.length;i++)
    {
      if (newtasklist[i].id == tsk.id)
      {
        if (tskafter != undefined && tskafterafter != undefined && tskafter.displayOrder != undefined && tskafterafter.displayOrder != undefined)
        {
          newtasklist[i].displayOrder = tskafter?.displayOrder + (tskafterafter?.displayOrder-tskafter?.displayOrder)/2;
        }
        if (tskafter != undefined && tskafterafter == undefined && tskafter.displayOrder != undefined)
        {
          //let nxttaskorproj:Task = getNextTaskOrProject(tskafter, tasks);
          //if (getNextTaskOrProject != undefined && nxttaskorproj?.displayOrder != undefined)
          {
            newtasklist[i].displayOrder = tskafter.displayOrder + 1;
          }
        }
      }
    }
    log("Task-List updated with move down", newtasklist);
    setTasks([...tasks]);
    
  }
  const cancelUpdate = () => {
    
   
    setDetailsMenuVisible(false);
    setDetailsMenuTask(null);
  }
  const ganttbardoubleclick = (tsk:Task) =>
  {
    if (tsk.type == 'project')
    {
      tsk.hideChildren = !tsk.hideChildren?true:!tsk.hideChildren;
      handleExpanderClick(tsk);
    } else
    {
      detailsmenuaction(tsk, 1);
    }           
  }
  const detailsmenuaction = (tsk:Task, action:number) =>
  {
    let plan = props.plans.filter((p)=>p.planid == tsk.planid)[0];
    setDetailsmenyPlan(plan);
    if (action == 1)
    {
      setMenuaction("update");
      setDetailsMenuVisible(true);
      setDetailsMenuTask(tsk);
    } else if (action == 2)
    {
      setMenuaction("insertabove");
      setDetailsMenuVisible(true);
      setDetailsMenuTask(tsk);
    }
    else if (action == 3)
    {
      setMenuaction("insertbelow");
      setDetailsMenuVisible(true);
      setDetailsMenuTask(tsk);
    }
    else if (action == 4)
    {
      setMenuaction("moveup");
      moveTaskUp(tsk);
    }
    else if (action == 5)
    {
      setMenuaction("movedown");
      moveTaskDown(tsk);
    }
  }
  const addAssignment = (planid:string, taskid:string, userid:string): Promise<void>  =>
  {
    if (props.people == null || props.people == undefined)
    {
      log("ERROR: People not loaded");
      return new Promise<void>((resolve, reject)=>{resolve();});
    } else
    {
      let newtasklist:GanttTask[] = tasks.map(
        (t)=>
        {
          let asigneeperson = props.people?.filter((p)=>p.id == userid);
          if (t.id == taskid && t.planid == planid)
          {
            return {
              ...t, assignments: t.assignments 
              ? 
              [...t.assignments, {id:userid, name:asigneeperson?asigneeperson[0].name:userid}]
              :
              [{id:userid, name:asigneeperson?asigneeperson[0].name:userid}]
            }
          } else 
          {
            return t;
          }
        });
      setTasks(newtasklist);
      return props.addAssignment(planid, taskid, userid);
    }
  }
  const deleteAssignment = (planid:string, taskid:string, assignmentid:string):Promise<void> =>
  {
    let newtasklist:GanttTask[] = tasks.map(
      (t)=>
      {
        if (t.id == taskid && t.planid == planid)
        {
          return {...t, assignments:  t.assignments.filter((a)=>a.id != assignmentid)}
        } else 
        {
          return t;
        }
      });
    setTasks(newtasklist);
    return props.deleteAssignment(planid, taskid, assignmentid);
  }
  
  
  useEffect(()=>{log("Zoom", props.viewMode, props.viewModeZoomLevel);},[props.viewModeZoomLevel]);
  return (
    <div className="ganttcontainer" onScroll={(event: SyntheticEvent<HTMLDivElement>)=>{
      if (scrollY !== event.currentTarget.scrollTop) {
        setScrollY(event.currentTarget.scrollTop);
      }}} >
      
      
      {tasks.length > 0 &&
      (
        <>
          
          <Gantt viewMode={props.viewMode}
            tasks={(props.showCompletedTasks)?tasks:tasks.filter((t)=>{return t.progress == undefined || t.progress < 100 || t.isplan == true || t.type == 'project'})}
            onDateChange={handleTaskChange}
            TooltipContent={(tsk)=>{
              return <GanttTooltipContent tsk={tsk.task} />}}
            todayColor='transparent'
            barCornerRadius={2}
            //listCellWidth={true ? 155+"px" : ""}
            columnWidth={props.viewModeZoomLevel}
            onExpanderClick={handleExpanderClick}
            onDoubleClick={ganttbardoubleclick}
            onSelect={(tsk,a)=>{log('Task Selected',tsk,a);}}
            TaskListHeader={({headerHeight,
              rowWidth,
              fontFamily,
              fontSize})=>{return <GanttTableHeader headerHeight={headerHeight} rowWidth={rowWidth} fontFamily={fontFamily} fontSize={fontSize} />}}
            onDetailsMenuClick={detailsmenuaction}
            onMarkCompletedClick={(tsk:Task)=>{marktaskcompleted(tsk as GanttTask);}}
            onPlanClick={handlePlanExpanded}
            onAssignmentsMenuClick={(tsk:Task)=>{detailsmenuaction(tsk as GanttTask, 1);}}
            hidechart={false}
            externalScrollY={scrollY}
            linkCreated={(from: string, to: string)=>
              {
                log("Link Created", from, to);
                let fromtask = tasks.filter((t)=>t.id == from)[0];
                props.savePlannerAttacmentDependencies(to, from, fromtask.name, fromtask.planid, fromtask.groupid, true);
                let newtasks = tasks.map((t)=>
                {
                  if (t.id == to)
                  { 
                    if (t.dependencies)
                    {
                      if (t.dependencies.indexOf(from) == -1)
                      {
                        t.dependencies.push(from);
                      }
                    } else 
                    {
                      t.dependencies = [from];
                    }
                    log("Save Link", t.dependencies);
                    //props.savePlannerAttacmentDependencies(t.id, t.dependencies);
                  }
                  return t;
                });
                
                setTasks(newtasks);
              }}
            linkDeleted={(from: string, to: string)=>
              {
                log("Link Delete", from, to);
                let fromtask = tasks.filter((t)=>t.id == from)[0];
                props.savePlannerAttacmentDependencies(to, from, fromtask.name,  fromtask.planid, fromtask.groupid, false);
                let newtasks = tasks.map((t)=>
                {
                  if (t.id == to)
                  { 
                    if (t.dependencies)
                    {
                      t.dependencies.splice(t.dependencies.indexOf(from), 1);
                    } else 
                    {
                      t.dependencies = [];
                    }
                    log("Link deleted", t.dependencies);
                    //props.savePlannerDependencies(t.id, t.dependencies);
                  }
                  return t;
                });
                
                setTasks(newtasks);
              }
            }
          />
          <GanttTaskDialog teamsfx={teamsfx} tasks={tasks} open={detailsMenuVisible} task={detailsMenuTask as GanttTask} plan={detailsmenyPlan} planName={detailsmenyPlan? detailsmenyPlan.planName:""} action={menuaction} askforpermissions={props.askforpermissions} people={props.people} peopleLoaded={props.peopleLoaded} updateTask={updateTask} insertTask={insertTask} cancelUpdate={cancelUpdate} deleteAssignment={deleteAssignment} addAssignment={addAssignment} />
          
        </>
      )}
      
      
    </div>
  );
}
