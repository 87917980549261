import allplans from './images/AllPlans.png';
import amazingplan from './images/AmazingPlan.png';
import taskdetails from './images/TaskDetails.png';

export default function PGPresentation(props:{}) {

    return (
    <div>
        <div className="webheading4 webheading4Right">Effortlessly Manage Your Planner Tasks</div>
        <div className="divTable">
            <div className="divTableBody">
                <div className="divTableRow">
                    <div className="divTableCell cellleft"><img title='image' className="webpageimg"  src={amazingplan} /></div>
                    <div className="divTableCell cellright">
                                       
                        <div className="subwebheading4">
                            <div>Introducing Planner Gantt, your go-to tool for seamlessly planning and replanning your tasks. With Planner Gantt, you can get a comprehensive overview of all your plans, forecast tasks, and key deliverable dates in one place. Easily modify and create tasks directly from the intuitive interface, making adjustments as needed.</div>
                            <br />
                            <div>Replanning tasks is a breeze with the drag-and-drop feature, allowing you to quickly and efficiently organize your workload. Use customizable labels to categorize and prioritize tasks for better organization. Additionally, set and visualize task dependencies to ensure smooth project progression.</div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div className="webheading4 webheading4Left">Stay Organized with a Comprehensive Planner Overview</div>
        <div className="divTable">
            <div className="divTableBody">
                <div className="divTableRow">
                    <div className="divTableCell cellleft">

                    <div className="subwebheading4">Gain a complete overview of all your MS Planner plans with ease. Dive into the details of individual tasks within each plan. Open any MS Planner plan you have access to and view the details of a single plan. Easily gain a portfolio overview by viewing across multiple plans, ensuring you stay organized and informed. All plans are immediately synchronized with MS Planner, allowing you to stay up-to-date with real-time changes.</div>  
                    </div>
                    <div className="divTableCell cellright"><img title='image' className="webpageimg"  src={allplans} /></div>
                </div>
            </div>
        </div>
        <div className="webheading4 webheading4Right">Manage and Collaborate on Your Tasks</div>
        <div className="divTable">
            <div className="divTableBody">
                <div className="divTableRow">
                    <div className="divTableCell cellleft"><img title='image' className="webpageimg"  src={taskdetails} /></div>
                    <div className="divTableCell cellright">
                        
                        <div className="subwebheading4">Take control of your workflow with ease. Seamlessly reorder tasks to match your priorities, set precise start and end dates, and keep track of your progress. Enhance team collaboration by updating everyone during stand-up meetings, ensuring everyone stays aligned and projects move forward smoothly.</div>  
                    </div>
                </div>
            </div>
        </div>
   </div>);
}