// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { getCookie, log, setCookie, shhlog } from "../helper";

export function AuthorizeUser(props: { teamsfx?: TeamsFx, loggedinComplete:Function, reasonmessage:string }) {
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
  };
  const [reloaderrormsg, setreloaderrormsg] = useState<string>('');
  const [newUser, setNewUser] = useState<boolean>(false);
  // For usage of useGraph(), please refer to: https://www.npmjs.com/package/@microsoft/teamsfx-react#usegraph.
  const { loading, error, data, reload } = useGraph(
    async (graph, teamsfx, scope) => {
        setreloaderrormsg('');
        log('profile read start');
        const profile = await graph.api("/me").get();
        log('profile read end', profile);
        
        log('error?', error);
        props.loggedinComplete();
        
        return { profile };
      
    },
    // Add scope for your Azure AD app. For example: Mail.Read, etc.
    // Use teamsfx instance from `InitTeamsFx`
    { scope: ["User.Read", "Mail.Read", "Tasks.ReadWrite", "offline_access"], teamsfx: teamsfx }
  );
  useEffect(()=>{
    log('AuthorizeUser teamsfx', teamsfx, props);
    let loadedbefore = getCookie('loadedbefore');
    log('AuthorizeUser loadedbefore', loadedbefore);
    if (loadedbefore == undefined || loadedbefore == "" || loadedbefore != 'yes')
    {
      shhlog('Setting cookie');
      setCookie('loadedbefore', 'yes', 365*5);
      shhlog('Setting NewUser true');
      setNewUser(true);
    }
    
  }, []);
  useEffect(()=>{
    try
    {
      log('AuthorizeUser Error in UseGraph', error);
      if (error != undefined && error != null && (error as any).message != undefined && (error as any).message != null)
      {
        setreloaderrormsg((error as any).message);
      } else 
      {
        setreloaderrormsg('');
      }
    } catch (ex)
    {

    }
  }, [error]);

  return (
    <div className="authBtn">
      {document.referrer.indexOf("teams.microsoft.com") < 0? <div className="authContent"><h3>Please open the app in Microsoft Teams and click Grant Permissions to use the app. <br /> 
        <Button primary content="Open in Teams" onClick={()=>
              {
                window.open(
                  'https://teams.microsoft.com/l/app/63cd9420-78db-4656-a2c9-d06215a625fd?source=app-details-dialog',
                  '_blank' // <- This is what makes it open in a new window.
                );
              }} /> </h3></div>
      :
      <>
      
        {(loading) && <Flex vAlign="center" hAlign="center" style={{width:'95vw', height:'95vh'}}><Loader label={'Authenticating user...'} /></Flex>}
        <div className="authContent">
          {!loading && newUser? (<><h3>We need to request a few more permissions, please click the button below to grant permissions.</h3><br /><Button primary content="Grant Permissions" disabled={loading} onClick={reload} /></>):
          <>
            {!loading && error && (
              <>
                <Text className="error">Failed to connect to Microsoft Login</Text>
              </>
            )}
            
            {!loading && (props.reasonmessage || error) && (<h4>Please try again.</h4>)}
            <br />
            {!loading && !data && <Button primary content="Sign in" disabled={loading} onClick={reload} />}
            
            
            {!loading && props.reasonmessage && (
              <>
                <br /><br />
                <Text className="warning">Message from login.microsoft.com was: </Text>
                <Text className="warning">{props.reasonmessage}</Text>
                <Text className="warning">{reloaderrormsg}</Text>
              </>
            )}
          </>}
        </div>
      </>}
      
    </div>
  );
}
