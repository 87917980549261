// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button } from "@fluentui/react-northstar";


import "./Subscriptions.css"


import clickonapps from '../images/clickonapps.jpg';
import searchforgantt from '../images/searchforgantt.jpg';
import clickonplannergantt from '../images/clickonplannergantt.jpg';
import clickbuysubscription from '../images/clickbuysubscription.jpg';
import completesubscription from '../images/completesubscription.jpg';
import completesubscriptionconfig from '../images/completesubscriptionconfig.jpg';

import { useState } from "react";

export function AssignLicenseGuide() {
  const [showGuide, setShowGuide] = useState<boolean>(false);
  
  return (
    
    <div className="newsubscribercontent" style={{textAlign:'center', marginLeft:'2vw', marginRight:'2vw', marginTop:'5vw', height:'90vh', overflowY:'scroll', minWidth:'38rem'}}>
        
        <h1 style={{textAlign:'center'}}>Thank you for chosing Planner Gantt</h1>
        <div>  
            <ul>
            <li>You currently have <b>0 licenses </b>in your subscription.</li>
            <li></li>
            <li>To purchase a subscription, please <Button primary content="Click Here" onClick={()=>
              {
                window.open(
                  'https://teams.microsoft.com/l/app/63cd9420-78db-4656-a2c9-d06215a625fd?source=app-details-dialog',
                  '_blank' // <- This is what makes it open in a new window.
                );
              }} /> (will open in seperate tab) or follow this guide <Button content="Open Guide" onClick={()=>{setShowGuide(!showGuide)}} /></li>
            {showGuide &&
            <>
            <li></li>
            <li><h3>Click on Apps from the left menu</h3></li>
            <li></li>
            <li><a href={clickonapps} target="_blank"><img className="subscriptionimg"  src={clickonapps} /> </a></li>
            <li></li>
            <li><h3>In the search box type in "Planner Gantt" and hit Enter</h3></li>
            <li></li>
            <li><a href={searchforgantt} target="_blank"><img className="subscriptionimg"  src={searchforgantt} /> </a></li>
            <li></li>
            <li><h3>Click on the Planner Gantt logo (<u>not</u> the open button)</h3></li>
            <li></li>
            <li><a href={clickonplannergantt} target="_blank"><img className="subscriptionimg"  src={clickonplannergantt} /> </a></li>
            <li></li>
            <li><h3>Click on the "Buy subscription" button</h3></li>
            <li></li>
            <li><a href={clickbuysubscription} target="_blank"><img className="subscriptionimg"  src={clickbuysubscription} /> </a></li>
            <li></li>
            <li><h3>Follow the purchasing wizard and click "Place order"</h3></li>
            <li></li>
            <li><a href={completesubscription} target="_blank"><img className="subscriptionimg"  src={completesubscription} /> </a></li>
            <li></li>
            <li><h3>When the purchase is complete remember to activate the subscription by following the link from Microsoft</h3></li>
            <li></li>
            <li><a href={completesubscriptionconfig} target="_blank"><img className="subscriptionimg"  src={completesubscriptionconfig} /> </a></li>
            <li></li>
            <li><h3>When the subscription is activated return to this page to allocate the licenses</h3></li>
            <li></li>
            </>}
            </ul>
            <br /><div style={{position:'absolute', bottom:'20px', right:'calc(50vw - 80px)'}}><Button primary style={{width:160, margin:'auto'}} onClick={()=>{window.open("mailto:help@annabell.online?subject=Help on purchaseing licenses&body=Please describe your challenge here...%0d%0a %0d%0a %0d%0a") }}>I Need Help</Button></div>
            <br />
            <br />
        </div>
    </div>
          
  );
}
