import { useContext } from "react";
import { Task } from "../components/gantt";
import { TeamsFxContext } from "../components/Context";


export default function GanttTooltipContent(props:{tsk:Task}) { /* Tooltip for Mon 24 Jul 2023 - Thu 01 Aug 2023 */
    const { themeString } = useContext(TeamsFxContext);
    /*return (
        <div className="pgpopup" style={{backgroundColor:(themeString === "default" ? "#fff" : "#666")}}>
            <div className="pgdate pgdayname">{props.tsk.start.toLocaleString('default', {weekday: 'short'})}</div>
            <div className="pgdate pgdaynumber">{props.tsk.start.getDate()}</div>
            <div className="pgdate pgmonthname">{props.tsk.start.toLocaleString('default', { month: 'short' }).substring(0, 3)}</div>
            <div className="pgdate pgyearnumber">{props.tsk.start.getFullYear()}</div>
            <div className="pgdatesep">-</div>
            <div className="pgdate pgdayname">{props.tsk.end.toLocaleString('default', {weekday: 'short'})}</div>
            <div className="pgdate pgdaynumber">{props.tsk.end.getDate()}</div>
            <div className="pgdate pgmonthname">{(props.tsk.end.toLocaleString('default', { month: 'short' }).substring(0, 3))}</div>
            <div className="pgdate pgyearnumber">{props.tsk.end.getFullYear()}</div>
        </div>
    );*/
    /*return (
    <div className="pgpopup" style={{backgroundColor:(themeString === "default" ? "#fff" : "#666")}}>
        {props.tsk.start.toLocaleString('default', {weekday: 'short'})  +" "}
        {props.tsk.start.getDate()+" "}
        {props.tsk.start.toLocaleString('default', { month: 'short' }).substring(0, 3)+" "}
        {props.tsk.start.getFullYear()}
        {" - "}
        {props.tsk.end.toLocaleString('default', {weekday: 'short'})+" "}
        {props.tsk.end.getDate()+" "}
        {(props.tsk.end.toLocaleString('default', { month: 'short' }).substring(0, 3))+" "}
        {props.tsk.end.getFullYear()+" "}
    </div>);*/
    /*return (
        <div className="pgpopup" style={{backgroundColor:(themeString === "default" ? "#fff" : "#666")}}>
            <div className="pgdate pgdayname">{props.tsk.start.toLocaleString('default', {weekday: 'short'})}</div>
            <div className="pgdate pgdaynumber">{props.tsk.start.getDate()}</div>
            <div className="pgdate pgmonthname">{props.tsk.start.toLocaleString('default', { month: 'short' }).substring(0, 3)}</div>
            <div className="pgdate pgyearnumber">{props.tsk.start.getFullYear()}</div>
            <div className="pgdatesep">-</div>
            <div className="pgdate pgdayname">{props.tsk.end.toLocaleString('default', {weekday: 'short'})}</div>
            <div className="pgdate pgdaynumber">{props.tsk.end.getDate()}</div>
            <div className="pgdate pgmonthname">{(props.tsk.end.toLocaleString('default', { month: 'short' }).substring(0, 3))}</div>
            <div className="pgdate pgyearnumber">{props.tsk.end.getFullYear()}</div>
        </div>
    );*/
    return (
        <div className="datetooltipparent" style={{backgroundColor:(themeString === "default" ? "#fff" : "#666")}}>
            <div className="datetooltipdiv1">{props.tsk.start.toLocaleString('default', {weekday: 'short'})}</div>
            <div className="datetooltipdiv2">{props.tsk.start.getFullYear()!=props.tsk.end.getFullYear()?props.tsk.start.getFullYear():""}</div>
            <div className="datetooltipdiv3"></div>
            <div className="datetooltipdiv4">{props.tsk.end.toLocaleString('default', {weekday: 'short'})}</div>
            <div className="datetooltipdiv5">{props.tsk.start.getFullYear()!=props.tsk.end.getFullYear()?props.tsk.end.getFullYear():""}</div>
            <div className="datetooltipdiv6">{props.tsk.start.getDate()}</div>
            <div className="datetooltipdiv7">{props.tsk.start.toLocaleString('default', { month: 'short' }).substring(0, 3)}</div>
            <div className="datetooltipdiv8">-</div>
            <div className="datetooltipdiv9">{props.tsk.end.getDate()}</div>
            <div className="datetooltipdiv10">{(props.tsk.end.toLocaleString('default', { month: 'short' }).substring(0, 3))}</div>
        </div>
    );
  }
  