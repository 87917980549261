// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Datepicker, Dialog, Dropdown, DropdownProps, Flex, Grid, Loader, Pill, Popup, Segment, TextArea, Text, Slider, Tooltip, Avatar, TrashCanIcon, AddIcon, FlexItem, ParticipantAddIcon, CloseIcon, TagIcon, DropdownItem, DropdownSelectedItem } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { Task } from "../../../gantt";
import { getStartEndDateForProject, getTaskAfter, log } from "../../../helper";
import { Assignment, GanttPlanDetails, GanttTask, Label, Person } from "../../../../ganttmods/publictypes";
import { AvatarIcon } from "../../AvatarIcon";
import { PepleGraph } from "../../PeopleGraph";
import { TeamsFx } from "@microsoft/teamsfx";
import { AssignmentsSection } from "./AssignmentsSection";

export type TaskAction = "update" | "insertabove" | "insertbelow" | "moveup" |"movedown";
export function GanttTaskDialog(
  props: 
  {
    teamsfx?: TeamsFx,
    tasks:GanttTask[], 
    open:boolean, 
    task:GanttTask | null, 
    planName:string, 
    plan:GanttPlanDetails | null,
    action:TaskAction, 
    askforpermissions:()=>void,
    people:Person[] | null,
    peopleLoaded:(people:Person[])=>void,
    updateTask:(tasks:any[], planid:string, taskid:string, taskStart:Date, taskDue:Date, taskName:string, percentComplete:number, action:TaskAction, labels:Label[])=>void, 
    insertTask:(planid:string, bucketid:string, tasks:any[], taskafterid:string, taskStart:Date, taskDue:Date, taskName:string, action:TaskAction, beforetask:Task | undefined, aftertask:Task | undefined)=>Promise<void> 
    cancelUpdate:()=>void 
    addAssignment:(planid:string, taskid:string, userid:string)=>Promise<void> 
    deleteAssignment:(planid:string, taskid:string, assignmentid:string)=>Promise<void>
  }) 
{
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
  };

  const [taskName, setTaskName] = useState<string>("");
  const [taskStart, setTaskStart] = useState<Date>();
  const [taskDue, setTaskDue] = useState<Date>();
  const [pctCompleteSliderValue, setPctCompleteSliderValue] = useState(0);
  const [closing, setClosing] = useState<boolean>(false);
  const [labelsDropDown, setLabelsDropDown] = useState<any[]>([]);
  const [selectedlabelsDropDown, setSelectedLabelsDropDown] = useState<Label[]>([]);

  useEffect(()=>{setClosing(false);log('persons', props.people);}, [props.open]);
  
  useEffect(()=>{
    log("Task", props.task);
    
    if (props.task != null)
    {
      if (props.plan)
      {
        setLabelsDropDown(props.plan.labels);
      }
      
      if (props.action == "update")
      {
        setTaskName(props.task.name);
        setTaskStart(props.task.start);
        setTaskDue(props.task.end);
        setPctCompleteSliderValue(props.task.progress);
        setSelectedLabelsDropDown(props.task.labels);
      } else if (props.action == "insertabove" || props.action == "insertbelow")
      {
        setTaskName("");
        setTaskStart(props.task.start);
        setTaskDue(props.task.end);
        setSelectedLabelsDropDown([]);
      }
    }
  }, [props.task]);
  const taskdialogclose = () => {
    setClosing(true);
    let newlables:boolean = false;
    if (props.task && selectedlabelsDropDown.length !== props.task.labels.length) {
      newlables = true;
    } else if (props.task && selectedlabelsDropDown.length === props.task.labels.length) {
      for (let i = 0; i < selectedlabelsDropDown.length; i++) {
        if (selectedlabelsDropDown[i].key !== props.task.labels[i].key) {
          newlables = true;
          break;
        }
      }
    }
    if ((taskName != "" && taskName != props.task?.name) || taskStart != props.task?.start || taskDue != props.task?.end || pctCompleteSliderValue != props.task?.progress || newlables)
    {
      log('Task update', props.task?.id, taskName, taskStart, taskDue, props.action);
      if (props.action == "insertabove" )
      {
        insertTaskAbove();
      } if (props.action == "insertbelow")
      {
        insertTaskBelow();
      } else if (props.action == "update")
      {
        updateTask();
      }
    } else
    {
      log('Task update, no changes');
      props.cancelUpdate();
    }
  }
  const updateTask = () => {
    
    let newTasks = props.tasks.map(t => {
      if (t.id === props.task?.id)
      {
        let newtask:GanttTask = {...t};
        newtask.name = taskName;
        newtask.start = taskStart as Date;
        newtask.end = taskDue as Date;
        newtask.progress = pctCompleteSliderValue;
        newtask.labels = selectedlabelsDropDown;
        return newtask;
      } else 
      {
        return t;
      }
    });
    
    let updatedTask =  props.tasks.find(t => t.id == props.task?.id);
    if (updatedTask != undefined && updatedTask.project) 
    {
      const [start, end] = getStartEndDateForProject(newTasks, updatedTask.project);
      const project = newTasks[newTasks.findIndex(t => t.id === updatedTask?.project)];
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
      {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t => t.id === updatedTask?.project ? changedProject : t);
      }
    }
   
    props.updateTask(newTasks, props.task?.planid as string, props.task?.id as string, taskStart as Date, taskDue as Date, taskName, pctCompleteSliderValue, props.action, selectedlabelsDropDown);
   /* setTasks(newTasks);
    if (data?.updateDetails != undefined)
    {
      data?.updateDetails(taskid, taskStart, taskDue, taskName);
    } else
    {setTimeout(() => {
      updateTaskDetails(taskid, taskStart, taskDue, taskName, action);
    }, 2000);}*/
  }
  const insertTaskAbove = () => 
  {
    let newTasks:GanttTask[] = props.tasks.map(t => {return t;}) as GanttTask[];
    if (props.action == "insertabove") //new task
    {
        //create task
        let taskafter =  props.tasks.find(t => t.id == props.task?.id);
        if (taskafter == undefined)
        { return;}
        log('taskafter', taskafter);
        let taskbefore =  undefined;
        let newdisplayorder = 1000;
        for (let i=0; i<newTasks.length;i++)
        {
          if (newTasks[i].type == "task" && newTasks[i].project == taskafter?.project)
          {
            
            if ((taskbefore == undefined || (newTasks[i]?.displayOrder as number) > (taskbefore?.displayOrder as number) )
              && (newTasks[i]?.displayOrder as number) < (taskafter?.displayOrder as number))
            {
              taskbefore = newTasks[i];
            }
          }
        }
        if (taskafter != undefined && taskafter.displayOrder != undefined)
        {
          if (taskbefore == undefined || taskbefore.displayOrder == undefined)
          {
            let projectentity = props.tasks.find(t => t.id == props.task?.project);
            log("projectentity", projectentity);
            if (projectentity != undefined && projectentity.displayOrder != undefined)
            {
              newdisplayorder = taskafter?.displayOrder - (taskafter?.displayOrder-projectentity?.displayOrder)/2;
            } else
            {
              log("new display order could not be defined");
            }
          } else
          {
            newdisplayorder = taskafter?.displayOrder - (taskafter?.displayOrder-taskbefore?.displayOrder)/2;
          }
        }
        
        log('taskbefore', taskbefore, taskafter, newdisplayorder);
        newTasks.push({
          start: taskStart as Date,
          end: taskDue as Date,
          name: taskName,
          id:  taskName,
          progress: 0,
          type: "task",
          project:taskafter?.project,
          styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },
          description: 'task description heere',
          //bucketorder: taskafter?.bucketorder,
          displayOrder: newdisplayorder,
          planid:taskafter.planid,
          bucketid:taskafter.bucketid,
          orderHint:"",
          assignments:[],
          isplan: false,
          expanded: false,
          groupid: (taskafter as GanttTask)?.groupid,
          labels:[]
        });
        log('newplan', newTasks);
    
      let updatedTask =  props.tasks.find(t => t.id == props.task?.id);
      if (updatedTask != undefined && updatedTask.project) 
      {
        const [start, end] = getStartEndDateForProject(newTasks, updatedTask.project);
        const project = newTasks[newTasks.findIndex(t => t.id === updatedTask?.project)];
        if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
        {
          const changedProject = { ...project, start, end };
          newTasks = newTasks.map(t => t.id === updatedTask?.project ? changedProject : t);
        }
      }
      
     
      props.insertTask(taskafter.planid, taskafter.bucketid, newTasks, props.task?.id as string, taskStart as Date, taskDue as Date, taskName, props.action, taskbefore, taskafter).then((v)=>{log("task created to update", v)});
    }
  }
  const insertTaskBelow = () => 
  {
    let newTasks:GanttTask[] = props.tasks.map(t => {return t;}) as GanttTask[];
    if (props.action == "insertbelow") //new task
    {
        //create task
        let taskbefore =  props.tasks.find(t => t.id == props.task?.id);
        log('B: taskbefore?.displayOrder', taskbefore?.displayOrder);
        if (taskbefore == undefined)
        { return;}
        log('taskbefore', taskbefore);
        let taskafter =  undefined;
        let newdisplayorder = 1000;
        taskafter = getTaskAfter(taskbefore, newTasks);
        log('B: taskafter?.displayOrder', taskafter?.displayOrder);
        //calculate display order
        if (taskbefore != undefined && taskbefore.displayOrder != undefined)
        {
          if (taskafter == undefined || taskafter.displayOrder == undefined)
          {
            let projectentity = props.tasks.find(t => t.id == props.task?.project);
            log("projectentity", projectentity);
            if (projectentity != undefined && projectentity.displayOrder != undefined)
            {
              newdisplayorder = taskbefore?.displayOrder + 1;
            } else
            {
              log("new display order could not be defined");
            }
          } else
          {
            newdisplayorder = taskbefore?.displayOrder - (taskbefore?.displayOrder-taskafter?.displayOrder)/2;
          }
        }
        log('B: newdisplayorder',newdisplayorder);
        log('tasks around & display order', taskbefore, taskafter, newdisplayorder);
        newTasks.push({
          start: taskStart as Date,
          end: taskDue as Date,
          name: taskName,
          id:  taskName,
          progress: 0,
          type: "task",
          project:taskbefore?.project,
          styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },
          description: '...',
          //bucketorder: taskafter?.bucketorder,
          displayOrder: newdisplayorder,
          planid:taskbefore.planid,
          bucketid:taskbefore.bucketid,
          orderHint:"",
          assignments:[],
          isplan: false,
          expanded: false,
          groupid: (taskafter as GanttTask)?.groupid,
          labels:[]
        });
        log('Updated plan tasks', newTasks);
    
      let updatedTask =  props.tasks.find(t => t.id == props.task?.id);
      if (updatedTask != undefined && updatedTask.project) 
      {
        const [start, end] = getStartEndDateForProject(newTasks, updatedTask.project);
        const project = newTasks[newTasks.findIndex(t => t.id === updatedTask?.project)];
        if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
        {
          const changedProject = { ...project, start, end };
          newTasks = newTasks.map(t => t.id === updatedTask?.project ? changedProject : t);
        }
      }
      
      props.insertTask(taskbefore.planid, taskbefore.bucketid, newTasks, props.task?.id as string, taskStart as Date, taskDue as Date, taskName, props.action, taskbefore, taskafter).then((v)=>{log("task created to update", v)});
    }
  }
 
  return (
    <>
        {props.open &&
        <>
            <Dialog
                //cancelButton=""
                confirmButton={closing?<div style={{userSelect:'none'}}>Saving...</div>:<div>Done</div>}
                content={
                  <div style={{margin:10}} onClick={()=>{}}>
                    <Flex>
                      <Flex.Item><span style={{color:' rgb(33, 115, 70)', fontWeight:600, userSelect:'none'}}>{props.planName}</span></Flex.Item>
                    </Flex>
                    <Flex>
                      <Flex.Item><TextArea style={{backgroundColor:'transparent', fontSize:'18px', height:'35px', overflow:'hidden', paddingLeft:1}} placeholder="Type task name here..." value={taskName} disabled={closing}  onChange={(tev, evvalue)=>{setTaskName(evvalue?.value?evvalue?.value:"")}} styles={{gridColumn: 'span 2',height:32}} required={true} /></Flex.Item>
                    </Flex>
                    <Flex style={{marginTop:10}}>
                      <Flex.Item>
                        <TagIcon outline size="medium" style={{margin:'auto'}} />
                      </Flex.Item>
                      <Flex.Item>
                        <Dropdown
                          multiple
                          value={selectedlabelsDropDown}
                          items={labelsDropDown}
                          onChange={(e, a:DropdownProps)=>
                            {
                              if (a.value != undefined)
                              {
                                setSelectedLabelsDropDown(a.value as any[]);
                              }
                            }}
                          search
                          fluid
                          inverted
                          style={{width:'100%'}}
                          placeholder="Search for label"
                          renderItem={(Component, props:any) => 
                            {
                              return (
                                <Component {...props} 
                                  header= 
                                  {
                                    <div style={{backgroundColor:props.categoryBackgroundColor, color:props.categoryColor, borderRadius:3}}>
                                      <div style={{padding:5}}>{props.header}</div>
                                    </div>
                                  } style={{cursor:'pointer'}} >

                                </Component>)
                            }
                          }
                          renderSelectedItem={(Component, props:any) => 
                            {
                              return (
                                <Component {...props} styles={{backgroundColor:props.categoryBackgroundColor + "!Important", color:props.categoryColor+ "!Important", borderBottomLeftRadius:2, borderTopLeftRadius:2, borderBottomRightRadius:2, borderTopRightRadius:2}}
                                  header= 
                                  {
                                    <div >
                                      <div style={{padding:5}}>{props.header}</div>
                                    </div>
                                  } style={{ cursor:'pointer'}} >

                                </Component>)
                            }
                          }
                          getA11ySelectionMessage={{
                            onAdd: (item) => {log("onAdd", item); return `${item} selected. Press left or right arrow keys to navigate selected items.`},
                            onRemove: (item) => {log("onRemove", item); return `${item} has been removed.`},
                          }}
                          noResultsMessage="We couldn't find any matches."
                          a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                        />
                      </Flex.Item>
                    </Flex>
                    <Grid 
                        columns="2"
                        data-builder-id="gmjq274e5dv"
                        rows="50px 50px 80px 80px *"
                        style={{marginTop:10}}
                    >
                      <Text weight="semibold">Start date</Text>
                      <Text weight="semibold">Due date</Text>
                      <Datepicker disabled={closing} data-builder-id="23suieemq2bh" style={{marginTop:5}} selectedDate={taskStart} onDateChange={(ev, evvalue)=>{ setTaskStart(evvalue?.value)}} />
                      <Datepicker disabled={closing} data-builder-id="pp0n4k1ixz" style={{marginTop:5}} selectedDate={taskDue} onDateChange={(ev, evvalue)=>{ setTaskDue(evvalue?.value)}} />
                    </Grid>
                    <Flex style={{marginTop:16}}>
                      <Flex.Item><Text weight="semibold">Progress</Text></Flex.Item>
                    </Flex>
                    <Flex>
                      <Flex.Item><Slider disabled={closing} fluid min={0} max={100} value={pctCompleteSliderValue} onChange={(e:any, a:any)=>{setPctCompleteSliderValue(a?.value)}} ></Slider></Flex.Item>
                    </Flex>
                    <Flex>
                      <Flex.Item><Text style={{color:'gray', width:'100%'}} align="center">{pctCompleteSliderValue == 0?'Not Started':(pctCompleteSliderValue == 100?'Completed': pctCompleteSliderValue + '% Complete')}</Text></Flex.Item>
                    </Flex>
                    <AssignmentsSection teamsfx={teamsfx} task={props.task} addAssignment={props.addAssignment} deleteAssignment={props.deleteAssignment} people={props.people} peopleLoaded={props.peopleLoaded} />
                  </div>
                }
                
                //headerAction="Task #1"
                //onAbort={()=>{setDetailsMenuVisible(false)}}
                onConfirm={()=>{if (!closing) {log('Confirm');taskdialogclose();}}}
                onCancel={()=>{if (!closing) {log('Cancel');taskdialogclose();}}}
                open={props.open}
            ></Dialog>
        
        </>
        }
    </>
  );
}
