// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Datepicker, Dialog, Dropdown, DropdownProps, Flex, Grid, Loader, Pill, Popup, Segment, TextArea, Text, Slider, Tooltip, Avatar, TrashCanIcon, AddIcon, FlexItem, ParticipantAddIcon, CloseIcon } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { Task } from "../../../gantt";
import { getStartEndDateForProject, log } from "../../../helper";
import { Assignment, GanttTask, Person } from "../../../../ganttmods/publictypes";
import { AvatarIcon } from "../../AvatarIcon";
import { PepleGraph } from "../../PeopleGraph";
import { TeamsFx } from "@microsoft/teamsfx";

export function AssignmentsSection(
  props: 
  {
    teamsfx?: TeamsFx,
    task:GanttTask | null, 
    people:Person[] | null,
    peopleLoaded:(people:Person[])=>void,
    addAssignment:(planid:string, taskid:string, userid:string)=>Promise<void> 
    deleteAssignment:(planid:string, taskid:string, assignmentid:string)=>Promise<void> 
  }) 
{
    const { teamsfx } = {
    teamsfx: undefined,
    ...props,
    };
    const [emptyResource, setEmptyResource] = useState<boolean>(false);
    const [newperson, setNewPerson] = useState<string>("");
    const [filteredpersons, setfilteredpersons] = useState<Person[] | null>(null);
    
    const [assignments, setAssignments] = useState<Assignment[]>([]);


    useEffect(()=>
    {
        log('assignments loading', props.people);
        setfilteredpersons(props.people);
        
        setEmptyResource(false);
        if (props.task != null && props.task.assignments != undefined && props.task.assignments != null && props.task.assignments.length > 0 && props.people != null)
        {
            let mappedassignments:Assignment[] = (props.task as GanttTask).assignments.map((assn:Assignment)=>
            {
                let resname = props.people?.find((p:Person)=>{return p.id == assn.id})?.name;
                return {id:assn.id, name:resname == undefined || resname == null?'N/A':resname};
            });
            setAssignments(mappedassignments.sort((a:Assignment, b:Assignment)=>{return a.name.localeCompare(b.name)}));
        } else if (props.task != null && props.task.assignments != undefined && props.task.assignments != null && props.task.assignments.length > 0 )
        {
            setAssignments((props.task as GanttTask).assignments.sort((a:Assignment, b:Assignment)=>{return a.name.localeCompare(b.name)}));
        } else
        {setAssignments([]);}
    }, []); 




    return (
    <>
        
        {filteredpersons != null ?  
            <Popup  pointing trigger={
            <Flex  style={{marginTop:16, height:40, textAlign:'center', verticalAlign:'middle', cursor:'pointer'}}>
                <Flex.Item><Button text iconOnly icon={<ParticipantAddIcon size="medium" style={{marginBottom:6, marginRight:0}} onClick={()=>{log("ParticipantAddIcon clicked")}} />} title=""/></Flex.Item>
                {assignments.map((assn:Assignment)=>
                    {
                        return <Flex.Item><div style={{marginLeft:4}}><AvatarIcon name={assn.name} /></div></Flex.Item>
                    }
                )}
            </Flex>
            } 
            onOpenChange=
            {
                (e, d)=>
                {
                if (d?.open == true && assignments.length > 0) 
                {
                    setEmptyResource(false);
                } else
                {setEmptyResource(true);}
                }
            } content={
            
            <div style={{margin:10}}>
            <Grid
                style={{margin:0}}
                columns="30px 200px 25px"
                data-builder-id="gmjq274e5dv"
                
            >
            
                {assignments.map((assn:Assignment)=>{
                    return <>
                
                    <AvatarIcon name={assn.name} />
                    
                        <>{assn.name}</> 
                    
                    <Button  icon={<CloseIcon  />} style={{marginBottom:5}} title="Delete assignment" size="small" text iconOnly={true} onClick={
                        ()=>
                        {
                        if (props.task != null ) 
                        {
                            props.deleteAssignment(props.task?.planid, props.task?.id, assn.id)
                            .then((v)=>
                            {
                                log("assignment deleted");
                                let newassignments = assignments.filter((a:Assignment)=>{return a.id != assn.id});
                                setAssignments(newassignments);
                            });
                        }
                        }} />
                    
                    
                    </>
                })}
            </Grid>
            
            {emptyResource?
                <Dropdown
                fluid
                styles={{height:32, gridColumn:'span 2'}}
                disabled={false}
                search
                items={filteredpersons.filter((p:Person)=>{return assignments.findIndex((a:Assignment)=>{return a.id == p.id}) == -1})}
                placeholder="Select user..." 
                value={newperson}  
                onChange={(tev, evvalue)=>
                    {
                    log('onChange',evvalue?.value);
                    setNewPerson(evvalue?.value?evvalue?.value as string:"");
                    if (evvalue?.value != undefined && evvalue?.value != null)
                    {
                        props.addAssignment(props.task?.planid as string, props.task?.id as string, (evvalue?.value as Person).id as string)
                        .then((v)=>
                        {
                            log("assignment added");
                            let newassn:Assignment = {id:(evvalue?.value as Person).id as string, name:(evvalue?.value as Person).name};
                            setAssignments([...assignments, newassn]);
                            setEmptyResource(false);
                        });
                    }
                    }}
                onSearchQueryChange={(e,v)=>
                    {
                        let searchquery = (v == undefined || v?.searchQuery == undefined)?"":v?.searchQuery;
                        log('onSearchQueryChange',v?.searchQuery);
                        let newemailsdropdown:any[] = [];
                        setfilteredpersons([...filteredpersons]);
                    
                    }}
                renderSelectedItem={(SelectedItem, props) => (
                    <SelectedItem {...props} header={`${props.header} (selected)`} />
                )}
                />
            :
                <div style={{textAlign:'center', width:'100%'}}><Button text primary icon={<ParticipantAddIcon />}  title="Add user" size="small" iconOnly={true} onClick={()=>{setEmptyResource(!emptyResource)}} /></div>
            }
                
                
            </div>} position="below" />
        :
            <Popup pointing trigger={<Button text content={assignments.length + " people assigned"} />} content={<div><Flex gap="gap.small"><Text style={{width:300}}>To see assignment details we must request a few more permissions. Please authenticate access to read out people names by clicking Grant Permissions below.</Text></Flex>
            <Flex gap="gap.small">
            <PepleGraph peopleloaded={(people:Person[])=>
                {
                    let mappedassignments:Assignment[] = assignments.map((assn:Assignment)=>
                    {
                        let resname = people.find((p:Person)=>{return p.id == assn.id})?.name;
                        return {id:assn.id, name:resname == undefined || resname == null?'N/A':resname};
                    });
                    setAssignments([...mappedassignments]);
                    setfilteredpersons(people);
                    props.peopleLoaded(people);
                }} teamsfx={teamsfx} askforpermissions={true} />
            {/*<Button text content="Grant Permissions" style={{margin:'auto'}} onClick={()=>{props.askforpermissions();log('Close dialog and Ask For Permissions');taskdialogclose();}} />*/}
            </Flex></div>} position="below" />
        }
                    
    </>
    );
}
