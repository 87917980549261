
import { useGraphWithCredential } from "@microsoft/teamsfx-react";
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider';
import { Button } from "@fluentui/react-northstar";

import { useContext } from "react";
import { TeamsFxContext } from "../Context";

export function LoginTestGraph() {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, error, data, reload } = useGraphWithCredential(
    async (graph, teamsUserCredential, scope) => {
        try
        {
            // Call graph api directly to get user profile information
            console.log("teamsUserCredential", teamsUserCredential);
            let token = await teamsUserCredential.getToken(scope);
            console.log("token", token);
            //teamsUserCredential.login(scope);
            const profile = await graph.api("/me").get();
                console.log("profile", profile);
            // Initialize Graph Toolkit TeamsFx provider
            const provider = new TeamsFxProvider(teamsUserCredential, scope);
            Providers.globalProvider = provider;
            Providers.globalProvider.setState(ProviderState.SignedIn);
            return { profile };   
        } catch (ex)
        {
            console.log("LoginTestGraph error", ex);
        }      
    },
    { scope: ["User.Read", "offline_access"], credential: teamsUserCredential }
  );

  return (
    <div>
      
      <h3>V11 Example: Get the user's profile</h3>
      <div className="section-margin">
        <p>Click below to authorize button to grant permission to using Microsoft Graph.</p>
        <pre>{`credential.login(scope);`}</pre>
        <Button primary content="Authorize" disabled={loading} onClick={reload} />
        <div>{data?.profile.displayName}</div>
       
        
      </div>
    </div>
  );
}
