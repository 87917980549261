import { Button, Dropdown, Flex, Grid, TextArea } from '@fluentui/react-northstar';

import { useState } from 'react';

export default function NewFeature(props:{}) {
    const [subject, setSubject] = useState<string|undefined>("");
    const [phone, setPhone] = useState<string|undefined>("");
    const [reason, setReason] = useState<string|undefined>("");
    const [description, setDescription] = useState<string|undefined>("");
    return (
        <div className="contactarea">
        
        <div className="divTableContact">
        <div style={{margin:10}}>
            <Grid
                columns="1"
                data-builder-id="gmjq274e5dv"
                rows="40px 80px 80px 280px 80px"
            >
                <Flex column>
                    
                    <Dropdown aria-required={true} style={{zIndex:0}}
                        items={['Request a feature', 'Report a bug', 'Get in contact', 'Other']}
                        placeholder="Please select..."
                        checkable
                        onChange={(e,ddp)=>{setReason(ddp.value?.toString());}}
                    />
                </Flex>
                <Flex column>
                    Subject
                    <TextArea readOnly={false} placeholder="Subject" value={subject} onChange={(e,v)=>{setSubject(v?.value?v?.value:"");}} disabled={false} styles={{height:32}}  required={true} />
                </Flex>
                <Flex column>
                    Phone
                    <TextArea placeholder="+1 555 555 555" value={phone} onChange={(e,v)=>{setPhone(v?.value?v?.value:"");}} disabled={false} styles={{height:32}}  required={true} />
                </Flex>
                <Flex column>
                    Description
                    <TextArea placeholder="Please type what your inquiry is readarding here..." value={description} onChange={(e,v)=>{setDescription(v?.value?v?.value:"");}} disabled={false}  styles={{height:200}} required={true} resize="both"  />
                </Flex>
                <Button primary style={{width:160, margin:'auto'}} onClick={()=>{window.location.href = "mailto:help@annabell.online?subject=" + reason +" - " + subject + "&body=" + description?.replaceAll("\n", '%0D%0A') + '%0D%0A'+ '%0D%0APhone: ' + phone + '%0D%0A'+ '%0D%0A' }}>Send</Button>
            </Grid>
        </div>
           
                
            
        </div>
       
   </div>);
}