import "./PlannerGantt.css";

export default function PlannerGantt() {
  return (
    <div>
    
    <nav>
        <a href="#" className="logo">Khawla</a>

        <ul>
            <a href="#home">Home</a>
            <a href="#about">About</a>
            <a href="#portfolio">Portfolio</a>
            <a href="#blog">Blog</a>
            <a href="#contact">Contact</a>
        </ul>

    </nav>

    <section className="home" id="home">
        <div className="left">
            <h1>Your Big Idea Starts Here</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Facere, ducimus jfishf
            </p>
            <a href="#" className="btn">Explore</a>
        </div>
        <div className="img">
            <img src="https://i.ibb.co/Yjyq2XC/Hero.jpg" alt="" />
        </div>
    </section>

    <section className="about" id="about">
        <div className="title">
            <h1>Your Big Ideas Start Here</h1>
            <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Vel unde possimus exercitationem <br /> quo alias sequi perspiciatis 
                corporis porro voluptates ea fuga cupiditate dignissimos, 
            </p>
        </div>

        <div className="abt">
            <div className="left">
                <h2>Everything you need to build your site in one place</h2>
                <p>
                    We could create anything. Let’s do it together. Start growing 
                    in half the time with an all-in-one website builder 
                    - no more long hours spent on the boring stuff!
                </p>

            </div>
            <div className="img">
                <img src="https://i.ibb.co/MBvKM3C/hero.png" alt="" />
            </div>
        </div>
        <div className="abt">
            <div className="img">
                <img src="https://i.ibb.co/CV0jspF/about2.png" alt="" />
            </div>

            <div className="left">
                <h2>We could create anything. Let’s do it together</h2>
                <p>
                    Everything you need to build your site in one place. 
                    All-in-one website builder for designers and creators. Your big idea starts here.
                </p>

            </div>

        </div>
    </section>

    <section className="portfolio" id="portfolio">
        <div className="title">
            <h1>Explore Our Latest Work</h1>
            <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Quibusdam quam fuga magni sunt at neque inventore! Nam vitae iusto debitis
            </p>
        </div>

        <div className="cards">
            <div className="card">
                <img src="https://i.ibb.co/XtWgrX7/img-2.jpg" alt="" />
            </div>
            <div className="card">
                <img src="https://i.ibb.co/nwNMkFz/img-3.jpg" alt="" />
            </div>
            <div className="card">
                <img src="https://i.ibb.co/bHfMRFP/img-5.jpg" alt="" />
            </div>
            <div className="card">
                <img src=" https://i.ibb.co/wCN7Mx7/img-6.jpg " alt="" />
            </div>
        </div>
    </section>

    <section className="blog" id="blog">
        <div className="title">
            <h1> Everything you need to build your site in one place</h1>
            <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni expedita maxime <br />
                consequuntur illum hic ullam voluptas harum deleniti rem corporis?

            </p>
        </div>
        <div className="posts">
            <div className="post">
                <div className="pic">
                    <img src="https://i.ibb.co/1T9Jdt2/blog5.jpg" alt="" />
                </div>
                <div className="detail">
                    <p>Branding</p>
                    <h2>Variety of Designs</h2>
                    <p>Imagine presentation on your device</p>
                </div>
            </div>
            <div className="post">
                <div className="pic">
                    <img src="https://i.ibb.co/MNgKQQB/blog6.webp" alt="" />
                </div>
                <div className="detail">
                    <p>Branding</p>
                    <h2>Variety of Designs</h2>
                    <p>Imagine presentation on your device</p>
                </div>
            </div>
            <div className="post">
                <div className="pic">
                    <img src="https://i.ibb.co/H7XcjS8/blog4.webp" alt="" />
                </div>
                <div className="detail">
                    <p>Branding</p>
                    <h2>Variety of Designs</h2>
                    <p>Imagine presentation on your device</p>
                </div>
            </div>
            <div className="post">
                <div className="pic">
                    <img src="https://i.ibb.co/Hn8XY0q/blog3.webp" alt="" />
                </div>
                <div className="detail">
                    <p>Branding</p>
                    <h2>Variety of Designs</h2>
                    <p>Imagine presentation on your device</p>
                </div>
            </div>
            
            <div className="post">
                <div className="pic">
                    <img src="https://i.ibb.co/mzRzrFy/blog2.jpg" alt="" />
                </div>
                <div className="detail">
                    <p>Branding</p>
                    <h2>Variety of Designs</h2>
                    <p>Imagine presentation on your device</p>
                </div>
            </div>
            <div className="post">
                <div className="pic">
                    <img src="https://i.ibb.co/WKSdzhF/blog1.webp" alt="" />
                </div>
                <div className="detail">
                    <p>Branding</p>
                    <h2>Variety of Designs</h2>
                    <p>Imagine presentation on your device</p>
                </div>
            </div>
        </div>
    </section>

    <section className="contact" id="contact">
        <div className="title">
            <h1>Gather incredible ideas for your own startup </h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, culpa ipsum iusto ratione 
                quaerat deserunt esse. Temporibus deserunt reprehenderit inventore.
            </p>
        </div>


        <form>
            <input type="text" placeholder="Name" />
            <input type="email" placeholder="E-mail" />
            <textarea  placeholder="Message"></textarea>

            <a href="#" className="btn">Submitt</a>
        </form>
    </section>

    <footer>
        <nav>
            <a href="#" className="logo">Khawla</a>
    
            <ul>
                <a href="#home">Home</a>
                <a href="#about">About</a>
                <a href="#portfolio">Portfolio</a>
                <a href="#blog">Blog</a>
                <a href="#contact">Contact</a>
            </ul>
    
        </nav>
        <hr />
        <div className="copy">
            by <a href="https://linktr.ee/khawlazhi">khawla</a>@2022
        </div>
    </footer>

    </div>
  );
}
