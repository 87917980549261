import React, { useRef, useEffect, useState } from "react";
import { GridProps, Grid } from "../grid/grid";
import { CalendarProps, Calendar } from "../calendar/calendar";
import { TaskGanttContentProps, TaskGanttContent } from "./task-gantt-content";
import styles from "./gantt.module.css";
import { BarTask } from "../../types/bar-task";

export type TaskGanttProps = {
  gridProps: GridProps;
  calendarProps: CalendarProps;
  barProps: TaskGanttContentProps;
  ganttHeight: number;
  scrollY: number;
  scrollX: number;
  zoomLevel:number;
  linkcreated: (from:string, to:string)=>void;
  linkdeleted: (from:string, to:string)=>void;
  externalScrollY?: number;
  printmode?:boolean;
  printStartDate?:Date;
  printEndDate?:Date;
};
export const TaskGantt: React.FC<TaskGanttProps> = ({
  gridProps,
  calendarProps,
  barProps,
  ganttHeight,
  scrollY,
  scrollX,
  zoomLevel,
  linkcreated,
  linkdeleted,
  externalScrollY,
  printmode=false,
  printStartDate,
  printEndDate
}) => {
  const ganttSVGRef = useRef<SVGSVGElement>(null);
  const horizontalContainerRef = useRef<HTMLDivElement>(null);
  const verticalGanttContainerRef = useRef<HTMLDivElement>(null);
  
  const newBarProps = { ...barProps, svg: ganttSVGRef };
  const [showlink, setshowlink] = useState<boolean>(false);
  const [linkx1, setlinkx1] = useState<number>(0);
  const [linkx2, setlinkx2] = useState<number>(0);
  const [linky1, setlinky1] = useState<number>(0);
  const [linky2, setlinky2] = useState<number>(0);
  const [linkedfrom, setlinkedfrom] = useState<string | null>(null);
  const [linkedto, setlinkedto] = useState<string | null>(null);
  const [arrowSelected, setarrowselected] = useState<boolean>(false);
  const [dialogx, setdialogx] = React.useState(0);
  const [dialogy, setdialogy] = React.useState(0);
  const [arrowUnselectedNotifier, setarrowUnselectedNotifier] = useState<Date>(new Date());

  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY;
    }
  }, [scrollY]);

  useEffect(() => {
    if (verticalGanttContainerRef.current) {
      verticalGanttContainerRef.current.scrollLeft = scrollX;
    }
    
  }, [scrollX]);

  return (
    <div
      className={styles.ganttVerticalContainer}
      ref={verticalGanttContainerRef}
      dir="ltr"
    >
      
      {!printmode && <svg
        xmlns="http://www.w3.org/2000/svg"
        width={gridProps.svgWidth}
        height={calendarProps.headerHeight}
        fontFamily={barProps.fontFamily}
      >
        <Calendar {...calendarProps} scrollY={0} />
      </svg>}
      <div
        ref={horizontalContainerRef}
        className={styles.horizontalContainer}
        style={
          ganttHeight
            ? { height: ganttHeight, width: gridProps.svgWidth }
            : { width: gridProps.svgWidth }
        }
      >
        {printmode && <svg
          xmlns="http://www.w3.org/2000/svg"
          width={gridProps.svgWidth}
          height={calendarProps.headerHeight}
          fontFamily={barProps.fontFamily}>
            <Calendar {...calendarProps} scrollY={0} />
        </svg>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={gridProps.svgWidth}
          height={barProps.rowHeight * barProps.tasks.length}
          fontFamily={barProps.fontFamily}
          ref={ganttSVGRef}
          onMouseMove={(e)=>
            {
              setlinkx2(e.nativeEvent.offsetX);
              setlinky2(e.nativeEvent.offsetY);
            }}
          onMouseUp={(e)=>
            {
              setshowlink(false);
              
            }}
            onClick={(e)=>{
              if (arrowSelected)
              {
                setarrowselected(false);
                setarrowUnselectedNotifier(new Date());
                setlinkedfrom(null);
                setlinkedto(null);
              }
            }}
        >
          
          <Grid {...gridProps} />
          {showlink && <line id="link" x1={linkx1} y1={linky1} x2={linkx2} y2={linky2} style={{stroke: "gray"}} strokeDasharray="4 8" strokeLinecap="round" strokeWidth="3" />}
          <TaskGanttContent {...newBarProps} zoomLevel={zoomLevel} 
          onLinkStartDrag={(t)=>{
              console.log("linked from", t);
              setlinkx1(t.x2+15);
              setlinky1(t.y+t.height/2);
              setlinkx2(t.x2+15);
              setlinky2(t.y+t.height/2);
              setshowlink(true);
              setlinkedfrom(t.id);
            }}
            onLinkStopDrag={(t)=>{
              console.log("linked to", t);
              setlinkedto(t.id);
              if (linkedfrom != null)
              {
                linkcreated(linkedfrom!, t.id);
              }
              setlinkedfrom(null);
              setlinkedto(null);
            }}
            highlightOnMouseHover={showlink}
            arrowClick={(taskFrom: BarTask, taskTo: BarTask, x:number, y:number)=>{setdialogx(x);setdialogy(y);setarrowselected(true);setlinkedfrom(taskFrom.id);setlinkedto(taskTo.id);}}
            arrowSelected={arrowUnselectedNotifier}
            />
            {arrowSelected && 
            <g style={{cursor:'pointer'}} onClick={(e)=>
            {
              e.stopPropagation();
              if (arrowSelected)
              {
                linkdeleted(linkedfrom!, linkedto!);
              }
              setlinkedfrom(null);
              setlinkedto(null);
              setarrowselected(false);
              setarrowUnselectedNotifier(new Date());
            }}>
              <rect x={dialogx-22} y={dialogy+5} width={45} height={45} fill="#666" rx="5" style={{opacity:1}}></rect>
              
              <path transform={"translate("+(dialogx+10-22)+","+(dialogy+15)+") scale(1.2 1.2)"} stroke="white"  d="M11.5 4a1.5 1.5 0 00-3 0h-1a2.5 2.5 0 015 0H17a.5.5 0 010 1h-.554L15.15 16.23A2 2 0 0113.163 18H6.837a2 2 0 01-1.987-1.77L3.553 5H3a.5.5 0 01-.492-.41L2.5 4.5A.5.5 0 013 4h8.5zm3.938 1H4.561l1.282 11.115a1 1 0 00.994.885h6.326a1 1 0 00.993-.885L15.438 5zM8.5 7.5c.245 0 .45.155.492.359L9 7.938v6.125c0 .241-.224.437-.5.437-.245 0-.45-.155-.492-.359L8 14.062V7.939c0-.242.224-.438.5-.438zm3 0c.245 0 .45.155.492.359l.008.079v6.125c0 .241-.224.437-.5.437-.245 0-.45-.155-.492-.359L11 14.062V7.939c0-.242.224-.438.5-.438z"></path>
            </g>
             
            }
           {externalScrollY != undefined && externalScrollY > 50 && !printmode?<Calendar {...calendarProps} scrollY={externalScrollY?externalScrollY:0} overlay={true} />:<></>}
           
        </svg>
        
        
      </div>
    </div>
  );
};
