// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will initialize TeamsFx SDK and show `GetUserProfile` component after initialization.

import { Button, Checkbox, Dropdown, DropdownProps, Flex, Loader, Popup, Slider, Tooltip } from "@fluentui/react-northstar";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { start } from "repl";
import { log } from "../helper";
import { TeamsFx } from "@microsoft/teamsfx";
import { Person } from "../../ganttmods/publictypes";



export function PepleGraph(props: {  teamsfx?: TeamsFx, askforpermissions:boolean, peopleloaded:(people:Person[]) => void}) {
    const { teamsfx } = {
        teamsfx: undefined,
        ...props,
      };
  
      const [newUser, setNewUser] = useState<boolean>(false);
      const [peopleloaded, setPeopleloaded] = useState<boolean>(false);
      const [showAuthorize, setShowAuthorize] = useState<boolean>(true);

      const { loading, error, data, reload } = useGraph(
        async (graph, teamsfx, scope) => {
           
          try
          {
            //log('PepleGraph profile read start');
            //const profile = await graph.api("/me").get();
            //log('PepleGraph profile read end', profile);
            
            log('PepleGraph people read start');
            let people =  await graph.api("/me/people??$top=500&$select=displayName,id&$filter=personType/class eq 'Person'").get().catch((ex:any)=>
            {
              log("People Load Failed", ex);
            });
            log('PeopleGraph people read end', people);
            log('PeopleGraph check for any errors?', error);
            if (people == undefined || people == null)
            {
              return { people:undefined };
            }
            setPeopleloaded(true);
            let peoplelist:Person[] = [];
            if (people != undefined && people != null)
            {
                people.value.forEach((p:any) => {
                    peoplelist.push({id:p.id, name:p.displayName, header:p.displayName});
                });
            }

            props.peopleloaded(peoplelist);
            return { people };
          } catch (ex)
          {
            log('PepleGraph Error', ex);
          }
        
      
    },
    
    { scope: ["User.Read", "Mail.Read", "Tasks.ReadWrite", "offline_access", "People.Read"], teamsfx: teamsfx }
  );
  
 useEffect(()=>{
  log('props.askforpermissions', props.askforpermissions);
  log('data?.people', data?.people);
 }, [props.askforpermissions, data?.people]);
  
  return (
    <>
      {!props.askforpermissions || (data?.people != undefined && data?.people != null && data?.people.length > 0)? 
        <></>
      :
        <div >
          {(loading)? 
            <Flex vAlign="center" hAlign="center" style={{width:'10vw', height:'10vh'}}><Loader label={'Authenticating user...'} /></Flex>
          :
            <>
              
                
              <div className="authContent">
                {!loading &&  (<>
                    <h3>We need to request a few more permissions, please click the link below to apprve additional permissions..</h3>
                    <br />
                    {showAuthorize?
                      <a onClick={()=>{setShowAuthorize(false);}} href="https://login.microsoftonline.com/common/adminconsent?client_id=b74f8709-41af-4896-92fb-716333c25d97&state=12345&redirect_uri=https://planner.annabell.online/extraperm.html?clientId=b74f8709-41af-4896-92fb-716333c25d97" target="_blank">Approve Additional Permissions</a>
                      :
                      <h4>Please reload the app to apply the new permissions</h4>
                    }
                  </>
                  )}
                
              </div>
            
            </>
          }
    
          
        </div>
      }
    </>
  );
}




  

