import { useEffect, useState } from "react";
import { getFromLocalCache, log, randomNumberFromText, saveToLocalCache, shhlog } from "../../../helper";
import { PlanCard } from "../PlanCard";
import { Text, Button, Card, CloseIcon, Flex, TextArea, Avatar, ArrowRightIcon, Tooltip } from "@fluentui/react-northstar";
import { GanttPlan } from "../../../../ganttmods/publictypes";



export function OpenMultiplePlans(props: {allplans:GanttPlan[], plansselected:(plans:GanttPlan[])=>void}) 
{
    const [searchstring, setSearchstring] = useState<string | undefined>(undefined);
    const [selectedplans, setSelectedplans] = useState<string[]>([]);
    useEffect(()=>
    {
        
    }, []);

    return (<>
      <Flex>
        <div style={{display:'inline-flex',  flexWrap:'wrap', alignItems: 'center', overflowY:'scroll', minWidth:'90vw', marginTop:20}}>
          {selectedplans.map((planid:string)=>{
            let newselectedplans = props.allplans.filter((sp:GanttPlan)=>sp.planid==planid)[0];
            
            return (
              <Avatar key={newselectedplans.planid} name={newselectedplans.planName} square size={'large'} style={{marginRight:10}} className={"planavatarclstxt planavatarcls" + randomNumberFromText(newselectedplans.planid, 20)} />
            )
          })}
          {selectedplans.length==0? 
            <Text content={"Please choose the appropriate plans and select the arrow button to proceed"} style={{alignItems:'center'}} />
          :
            <Tooltip content="Open selected plans">
              <Button primary content="" style={{display:'flex', float:'right', height:'2.60rem', width:'2.60rem'}} icon={<ArrowRightIcon />} iconOnly={true} onClick={()=>
              {
                  let newselectedplans = props.allplans.filter((sp:GanttPlan)=>selectedplans.indexOf(sp.planid)>=0);
                  props.plansselected(newselectedplans);
              }} />
            </Tooltip>
          }
        </div>
      </Flex>
      <TextArea value={searchstring} onChange={(e, v)=>{setSearchstring(v?.value)}} fluid styles={{height:32, marginTop:'2rem', width:'90vw'}} placeholder="Type to search..."></TextArea>

      {props.allplans.filter((fplan:GanttPlan)=>
        {
            if (searchstring==undefined || searchstring==null )
            {return true;}
            if (fplan.planName.toLowerCase().indexOf((searchstring as string).toLowerCase()) >= 0)
            {return true;}
            return false; 
        }).map((plan:GanttPlan)=>
        {
          let isselected  = selectedplans.indexOf(plan.planid)>=0;
          log('Plan', plan);
          return <PlanCard key={plan.planid} title={plan.planName} subtitle={plan.ownerName} plan={plan} closeBtn={false} selectected={isselected}
            onSelect={(plan:GanttPlan)=>
              {
                //Remove if already selected
                if (selectedplans.indexOf(plan.planid)>=0)
                {
                  let newselectedplans = selectedplans.filter((sp)=>sp!=plan.planid);
                  setSelectedplans(newselectedplans);
                  log('Selected Plans', newselectedplans);
                }
                else
                {
                  setSelectedplans([...selectedplans, plan.planid]);
                  log('Selected Plans', selectedplans);
                }
              }
            } ></PlanCard>
        })
      }        
    </>);
}