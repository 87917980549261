import { useEffect, useState } from "react";
import { PlanCard } from "../PlanCard";
import { TextArea } from "@fluentui/react-northstar";
import { GanttPlan } from "../../../../ganttmods/publictypes";


export function AllPlansSearch(props: {allplans:GanttPlan[], planselected:(plan:GanttPlan, isnew:boolean)=>void}) 
{
    const [searchstring, setSearchstring] = useState<string | undefined>(undefined);
    useEffect(()=>
    {
        
    }, []);
    return (<>
        <TextArea value={searchstring} onChange={(e, v)=>{setSearchstring(v?.value)}} fluid styles={{height:32, marginTop:'2rem', width:'90vw'}} placeholder="Type to search..."></TextArea>
        {props.allplans.filter((fplan:GanttPlan)=>
                {
                    if (searchstring==undefined || searchstring==null )
                    {return true;}
                    if (fplan.planName.toLowerCase().indexOf((searchstring as string).toLowerCase()) >= 0)
                    {return true;}
                    return false; 
                }).map((plan:GanttPlan)=>
                {
                  return <PlanCard key={plan.planid} title={plan.planName}  subtitle={plan.ownerName} plan={plan} closeBtn={false}
                    onSelect={(selectedplan:GanttPlan)=>
                    {
                        props.planselected(selectedplan, false);
                        //setcontrolmaxheight('20vh');
                        /*let planfound = props.allplans.find((p:any) => p.id == planid);
                        props.planselected(planfound.title, planfound.id, false);
                        let recentplans = getFromLocalCache("recentplans");
                        if (recentplans == null)
                        {recentplans = [];}
                        recentplans.push({title:planfound.title, id:planfound.id, opened:new Date()});
                        cacheedrecentplans.push({title:planfound.title, id:planfound.id, opened:new Date()});
                        saveToLocalCache("recentplans", recentplans);
                        setPlanchosen(true);
                        setPlanchosenid(planfound.id);
                        setPlanchosentitle(planfound.title);
                        setPlanchosenowner(plan.ownername);*/
                      }
                    } ></PlanCard>
                })
              }        
    </>);
}