

// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will initialize TeamsFx SDK and show `GetUserProfile` component after initialization.

import { AddIcon, Avatar, Button, Checkbox, Dropdown, DropdownProps, Flex, Loader, Popup, Slider, Tooltip, Text, TrashCanIcon } from "@fluentui/react-northstar";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { start } from "repl";
import { TeamsFx } from "@microsoft/teamsfx";
import { GanttTask } from "./publictypes";
import styles from "../components/gantt/components/task-list/task-list-table.module.css";
import { arghlog, log } from "../components/helper";
import { AvatarIcon } from "../components/coreapp/AvatarIcon";


export function AssignmentAvatar(props: { task:GanttTask, onAssignmentsMenuClick: (task: GanttTask) => void; }) {
    const { teamsfx } = {
        teamsfx: undefined,
        ...props,
      };
    const [task, setTask] = useState<GanttTask>(props.task);
    const [assignmentsText, setAssignmentText] = useState<string>("");
    const [popupopen, setPopupopen] = useState<boolean>(false);
    const [emptyResource, setEmptyResource] = useState<boolean>(false);
    const [newperson, setNewPerson] = useState<string>("");
    const [inputItems, setInputItems] = useState<any[]>([]);
      useEffect(() => {
        if (props.task == undefined || props.task.assignments == undefined)
        {
          arghlog('Assignments are empty, must not happen');
          setAssignmentText("0");
        } else 
        {
          if ((props.task as GanttTask).assignments.length == 0)
          {
              setAssignmentText("0");
          } else if ((props.task as GanttTask).assignments.length == 1 && (props.task as GanttTask).assignments[0].name != 'NL')
          {
              setAssignmentText((props.task as GanttTask).assignments[0].name);
          }
          else
          {
              setAssignmentText((props.task as GanttTask).assignments.length.toString());
          }
        }
        setInputItems(inputItems2);
      }, [props.task]);
 
  
  return (
    <>
        {props.task.type == "task"?
        <div  onClick={()=>{setPopupopen(true);props.onAssignmentsMenuClick(props.task)}}><AvatarIcon name={assignmentsText} /></div>
       
        /*<Popup pointing position="after" align="center" onOpenChange={()=>{setPopupopen(false)}} open={popupopen} content=
        {
            <Flex gap="gap.smaller" column hAlign="start" >
                {props.task.assignments.map((assignment, index) => 
                {
                    return <Flex><Text content={assignment.name} weight="semibold"/> <Button  icon={<TrashCanIcon />}  title="Add user" size="small" iconOnly={true} onClick={()=>{}} /></Flex>
                })}
                {emptyResource &&
                   <Dropdown
                   fluid
                   styles={{height:32, gridColumn:'span 2'}}
                   disabled={false}
                   search
                   items={inputItems}
                   placeholder="User email or principal name..." 
                   value={newperson}  
                   onChange={(tev, evvalue)=>
                     {
                       log('onChange',evvalue?.value);
                       setNewPerson(evvalue?.value?evvalue?.value as string:"")
                     }}
                   onSearchQueryChange={(e,v)=>
                     {
                        let searchquery = (v == undefined || v?.searchQuery == undefined)?"":v?.searchQuery;
                        log('onSearchQueryChange',v?.searchQuery);
                        let newemailsdropdown:any[] = [];
                        setInputItems([...inputItems2, searchquery]);
                       
                     }}
                   renderSelectedItem={(SelectedItem, props) => (
                     <SelectedItem {...props} header={`${props.header} (selected)`} />
                   )}
                 />
                }
                <div style={{margin:'auto'}}><Button  icon={<AddIcon />} primary title="Add user" size="small" iconOnly={true} onClick={()=>{setEmptyResource(!emptyResource)}} /></div>
            </Flex>
        } 
        trigger=
        {
            <Avatar  name={assignmentsText}  size={'smaller'} className={(props.task as GanttTask).assignments.length == 0?styles.noassignments:styles.assignments} onClick={()=>{setPopupopen(true)}} />
        } />*/
        :
        <></>}
    </>
  );
}




  
const inputItems2 = [
    {
      header: 'Bruce Wayne',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/matt.jpg',
      content: 'Software Engineer',
    },
    {
      header: 'Natasha Romanoff',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/jenny.jpg',
      content: 'UX Designer 2',
    },
    {
      header: 'Steven Strange',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/joe.jpg',
      content: 'Principal Software Engineering Manager',
    },
    {
      header: 'Alfred Pennyworth',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/justen.jpg',
      content: 'Technology Consultant',
    },
    {
      header: `Scarlett O'Hara`,
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/laura.jpg',
      content: 'Software Engineer 2',
    },
    {
      header: 'Imperator Furiosa',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/veronika.jpg',
      content: 'Boss',
    },
    {
      header: 'Bruce Banner',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/chris.jpg',
      content: 'Senior Computer Scientist',
    },
    {
      header: 'Peter Parker',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/daniel.jpg',
      content: 'Partner Software Engineer',
    },
    {
      header: 'Selina Kyle',
      image: 'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/ade.jpg',
      content: 'Graphic Designer',
    },
  ]
const getA11ySelectionMessage = {
    onAdd: (item:any) => `${item} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item:any) => `${item} has been removed.`,
  }