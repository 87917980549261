import { AddIcon, Button, ContentIcon, ArrowDownIcon, FilesTxtIcon, FilesUploadIcon, ArrowUpIcon, Flex, IndentIcon, NotesIcon, Popup } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { Task } from "../../types/public-types";

import styles from "./task-list-table.module.css";
export default function MarkCompleted(props:{tsk:Task, onMarkCompletedClick: (task: Task) => void;}) 
{
  const [submenuVisible, setSubmenuVisible] = useState<Boolean>(false);
  const [popupopen, setPopupopen] = useState<boolean>(false);
  return (
    <div  className={styles.submenudots} onClick={()=>{props.onMarkCompletedClick(props.tsk)}}>
        {props.tsk.progress == 100?
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="rgb(33, 115, 70)" focusable="false">
                <path d="M10 2a8 8 0 110 16 8 8 0 010-16zm3.36 5.65a.5.5 0 00-.64-.06l-.07.06L9 11.3 7.35 9.65l-.07-.06a.5.5 0 00-.7.7l.07.07 2 2 .07.06c.17.11.4.11.56 0l.07-.06 4-4 .07-.08a.5.5 0 00-.06-.63z"></path>
            </svg>
        :
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="rgb(96, 94, 92)" focusable="false">
                <path d="M10 3a7 7 0 100 14 7 7 0 000-14zm-8 7a8 8 0 1116 0 8 8 0 01-16 0z"></path>
            </svg>
        }
      
    </div>
  );
}
