import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { isColorDark } from "../helper";


export function AvatarIcon(props: { name:string}) {
    const { teamsfx } = {
        teamsfx: undefined,
        ...props,
      };
   
    const [initials, setInitials] = useState<string>('');
    const [color, setColor] = useState<string>('rgb(232, 232, 232)');
      useEffect(() => {
        let words = props.name.toLocaleLowerCase().replace(/[^a-z ]/g, "").split(' ');

        if (words.length >= 2) {
          let firstWord = words[0];
          let secondWord = words[1];
        
          let firstLetterOfFirstWord = firstWord.charAt(0);
          let firstLetterOfSecondWord = secondWord.charAt(0);
          setInitials(firstLetterOfFirstWord.toUpperCase() + firstLetterOfSecondWord.toUpperCase());
          const red = (firstLetterOfFirstWord.charCodeAt(0)* 13) % 256;
          const green = (firstLetterOfSecondWord.charCodeAt(0)* 17) % 256;
          const blue = (firstLetterOfFirstWord.charCodeAt(0) + firstLetterOfSecondWord.charCodeAt(0)+ 128) % 256;
          setColor(`rgb(${red},${green},${blue}, 0.8)`);
        } else if (words.length == 1 && words[0] != '') {
          let firstWord = words[0];
        
          let firstLetterOfFirstWord = firstWord.charAt(0);
          let secondLetterOfFirstWord = firstWord.charAt(1);
          setInitials(firstLetterOfFirstWord.toUpperCase() + secondLetterOfFirstWord.toUpperCase());
          const red = (firstLetterOfFirstWord.charCodeAt(0)* 13) % 256;
          const green = (secondLetterOfFirstWord.charCodeAt(0)* 17) % 256;
          const blue = (firstLetterOfFirstWord.charCodeAt(0) + secondLetterOfFirstWord.charCodeAt(0)+ 128) % 256;
          setColor(`rgb(${red},${green},${blue}, 0.8)`);
          
        } else if (props.name == '0')
        {
          setInitials(props.name);
          setColor(`rgb(232, 232, 232)`);
        } else if (/^[0-9]+$/.test(props.name))
        {
          let firstnumber = props.name.charAt(0);
          let secondnumber = '0';
          if (props.name.length >= 2)
          {
            secondnumber = props.name.charAt(1);
            
          } 
          const red = (firstnumber.charCodeAt(0)* 13) % 256;
          const green = (secondnumber.charCodeAt(0)* 17) % 256;
          const blue = (firstnumber.charCodeAt(0) + secondnumber.charCodeAt(0)+ 128) % 256;
          setColor(`rgb(${red},${green},${blue}, 0.8)`);
          setInitials(props.name);

        } else {
          setInitials(props.name);
        }
      }, [props.name]);
 
  
  return (
    <div>
      <span style={
        {
          backgroundColor:color, 
          borderRadius:9999, fontSize: '0.6429rem', width:'1.5rem', height:'1.5rem', 
          alignItems:'center', overflow:'hidden', verticalAlign:'top', textAlign:'center', 
          lineHeight:'1.5rem', color:(isColorDark(color))?'rgba(0, 0, 0, 0.6)':'rgba(255, 255, 255, 0.6)', display:'inline-block',
          userSelect:'none'}}>{initials}</span>
  </div>
  );
}



