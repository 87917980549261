import React, { useEffect, useState } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
//import Privacy from "./Privacy";
//import TermsOfUse from "./TermsOfUse";

import "./Home.css";
import { debounce } from "../helper";
import PGPresentation from "./PGPresentation";
import Contact from "./Contact";
import Terms from "./terms";
import Privacy from "./privacy";
import NewFeature from "./NewFeature";
import GetStarted from "./GetStarted";
import AuthPeoplePermissions from "./AuthPeoplePermissions";


/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function Home(props:{page:string}) {
  const [pageName, setPageName] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  useEffect(()=>{
    document.body.style.backgroundColor = "white";
    setPageName(props.page);
    switch(props.page)
    {
      case "home":
        setPageTitle("PLANNER GANTT");
        setTimeout(()=>{window.scrollTo(0, 0);}, 50);
        break;
      case "contact":
        setPageTitle("CONTACT");
        setTimeout(()=>{window.scrollTo(0, 400);}, 50);
        break;
      case "terms":
        setPageTitle("TERMS AND CONDITIONS");
        setTimeout(()=>{window.scrollTo(0, 0);}, 50);
        break;
      case "privacy":
        setPageTitle("PRIVACY POLICY");
        setTimeout(()=>{window.scrollTo(0, 0);}, 50);
        break;
      case "getstarted":
        setPageTitle("GET STARTED");
        setTimeout(()=>{window.scrollTo(0, 0);}, 50);
        break;
      case "newfeature":
        setPageTitle("REQUEST A NEW FEATURE");
        setTimeout(()=>{window.scrollTo(0, 400);}, 50);
        break;
        case "authpeoplepermissions":
          setPageTitle("AUTHORIZE PEOPLE PERMISSIONS");
          setTimeout(()=>{window.scrollTo(0, 400);}, 50);
          break;
      default:
        setPageTitle(props.page);
        break;
    }
    
    
  }, []);
    
  return (
    <div className="webpage">
      
        
        <div className="webheadingcon">
          <ul className="webmenu">
          <li><div><a href="#contact">Get in contact</a><div className={"underline" + (pageName=="contact"?" active":"") }></div></div></li>
          <li><div><a href="#privacy">Privacy policy</a><div className={"underline" + (pageName=="about"?" active":"") }></div></div></li>
          <li><div><a href="#terms">Terms of use</a><div className={"underline" + (pageName=="about"?" active":"") }></div></div></li>
          {/*<li><div><a href="#newfeature">Request a feature</a><div className={"underline" + (pageName=="newfeature"?" active":"") }></div></div></li>*/}
          <li><div><a href="#getstarted">Get Started</a><div className={"underline" + (pageName=="getstarted"?" active":"") }></div></div></li>
          <li><div><a href="#home">Planner Gantt</a><div className={"underline" + (pageName=="home"?" active":"") }></div></div></li>
              
              
          </ul>   
          <div className="webheading3">{pageTitle}</div>
          <hr className="webhrline" />
        </div>
       
       
        
       
        {props.page == "home" && <PGPresentation />}
        {props.page == "newfeature" && <NewFeature />}
        {props.page == "privacy" && <Privacy />}
        {props.page == "terms" && <Terms />}
        {props.page == "contact" && <Contact />}
        {props.page == "getstarted" && <GetStarted />}
        {props.page == "authpeoplepermissions" && <AuthPeoplePermissions />}
        <div className="webfooter"></div>
    </div>
  );
}


  // Reads out the scroll position and stores it in the data attribute
  // so we can use it in our stylesheets
  const storeScroll = () => {
    //@ts-ignore
    document.documentElement.dataset.scroll = window.scrollY;
  }
  // Listen for new scroll events, here we debounce our `storeScroll` function
  document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  
  // Update scroll position for first time
  storeScroll();