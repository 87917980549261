import { useContext, useState } from "react";
import { Menu } from "@fluentui/react-northstar";



import { LoginTestGraph } from "./LoginTestGraph";

import { useData } from "@microsoft/teamsfx-react";
import { Deploy } from "./Deploy";
import { Publish } from "./Publish";
import { TeamsFxContext } from "../Context";

export function LoginTest() {
 

  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userName = (loading || error) ? "": data!.displayName;
  return (
    <div className="welcome page">
      <div className="narrow page-padding">
        test
        <h1 className="center">Congratulations{userName ? ", " + userName : ""}!</h1>
        
        
              
              <LoginTestGraph />
          
        
      </div>
    </div>
  );
}
