// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Datepicker, Dialog, Dropdown, DropdownProps, Flex, Grid, Loader, Pill, Popup, Segment, TextArea, Text, Slider, Tooltip } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { Task } from "../../../gantt";
import { getStartEndDateForProject, log } from "../../../helper";

export type TaskAction = "update" | "insertabove" | "insertbelow" | "moveup" |"movedown";
export function GanttTaskDialogMobile(
  props: 
  {
    tasks:Task[], 
    open:boolean, 
    task:Task | null, 
    planName:string, 
    action:TaskAction, 
    updateTask:(tasks:any[], taskid:string, taskStart:Date, taskDue:Date, taskName:string, percentComplete:number, action:TaskAction)=>void, 
    insertTask:(planid:string, bucketid:string, tasks:any[], taskafterid:string, taskStart:Date, taskDue:Date, taskName:string, action:TaskAction, orderHint:string)=>Promise<void> 
    cancelUpdate:()=>void 
  }) 
{
    const [detailsMenuVisible, setDetailsMenuVisible] = useState<boolean>(false);
    const [taskName, setTaskName] = useState<string>("");
    const [taskStart, setTaskStart] = useState<Date>();
    const [taskDue, setTaskDue] = useState<Date>();
    const [pctCompleteSliderValue, setPctCompleteSliderValue] = useState<number>(0);
    const [closing, setClosing] = useState<boolean>(false);

  useEffect(()=>{setClosing(false);}, [props.open]);
  useEffect(()=>{
    log("Task", props.task);
    if (props.task != null)
    {
      if (props.action == "update")
      {
        setTaskName(props.task.name);
        setTaskStart(props.task.start);
        setTaskDue(props.task.end);
        log("Dialog clider value", props.task.progress);
        setPctCompleteSliderValue(props.task.progress)
      } else if (props.action == "insertabove" || props.action == "insertbelow")
      {
        setTaskName("");
        setTaskStart(props.task.start);
        setTaskDue(props.task.end);
      }
    }
  }, [props.task]);
  const taskdialogclose = () => {
    setClosing(true);
    if ((taskName != "" && taskName != props.task?.name) || taskStart != props.task?.start || taskDue != props.task?.end || pctCompleteSliderValue != props.task?.progress)
    {
      log('Task update', props.task?.id, taskName, taskStart, taskDue, props.action);
      if (props.action == "insertabove" )
      {
        insertTaskAbove();
      } if (props.action == "insertbelow")
      {
        insertTaskBelow();
      } else if (props.action == "update")
      {
        updateTask();
      }
    } else
    {
      log('Task update, no changes');
      props.cancelUpdate();
    }
  }
  const updateTask = () => {
    
    let newTasks = props.tasks.map(t => {
      if (t.id === props.task?.id)
      {
        let newtask = {...t};
        newtask.name = taskName;
        newtask.start = taskStart as Date;
        newtask.end = taskDue as Date;
        newtask.progress = pctCompleteSliderValue;
        return newtask;
      } else 
      {
        return t;
      }
    });
    
    let updatedTask =  props.tasks.find(t => t.id == props.task?.id);
    if (updatedTask != undefined && updatedTask.project) 
    {
      const [start, end] = getStartEndDateForProject(newTasks, updatedTask.project);
      const project = newTasks[newTasks.findIndex(t => t.id === updatedTask?.project)];
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
      {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t => t.id === updatedTask?.project ? changedProject : t);
      }
    }
    props.updateTask(newTasks, props.task?.id as string, taskStart as Date, taskDue as Date, taskName, pctCompleteSliderValue, props.action);
   /* setTasks(newTasks);
    if (data?.updateDetails != undefined)
    {
      data?.updateDetails(taskid, taskStart, taskDue, taskName);
    } else
    {setTimeout(() => {
      updateTaskDetails(taskid, taskStart, taskDue, taskName, action);
    }, 2000);}*/
  }
  const insertTaskAbove = () => 
  {
    let newTasks = props.tasks.map(t => {return t;});
    if (props.action == "insertabove") //new task
    {
        //create task
        let taskafter =  props.tasks.find(t => t.id == props.task?.id);
        if (taskafter == undefined)
        { return;}
        log('taskafter', taskafter);
        let taskbefore =  undefined;
        let newdisplayorder = 1000;
        for (let i=0; i<newTasks.length;i++)
        {
          if (newTasks[i].type == "task" && newTasks[i].project == taskafter?.project)
          {
            
            if ((taskbefore == undefined || (newTasks[i]?.displayOrder as number) > (taskbefore?.displayOrder as number) )
              && (newTasks[i]?.displayOrder as number) < (taskafter?.displayOrder as number))
            {
              taskbefore = newTasks[i];
            }
          }
        }
        if (taskafter != undefined && taskafter.displayOrder != undefined)
        {
          if (taskbefore == undefined || taskbefore.displayOrder == undefined)
          {
            let projectentity = props.tasks.find(t => t.id == props.task?.project);
            log("projectentity", projectentity);
            if (projectentity != undefined && projectentity.displayOrder != undefined)
            {
              newdisplayorder = taskafter?.displayOrder - (taskafter?.displayOrder-projectentity?.displayOrder)/2;
            } else
            {
              log("new display order could not be defined");
            }
          } else
          {
            newdisplayorder = taskafter?.displayOrder - (taskafter?.displayOrder-taskbefore?.displayOrder)/2;
          }
        }
        
        log('taskbefore', taskbefore, taskafter, newdisplayorder);
        newTasks.push({
          start: taskStart as Date,
          end: taskDue as Date,
          name: taskName,
          id:  taskName,
          progress: 0,
          type: "task",
          project:taskafter?.project,
          styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },
          description: 'task description heere',
          //bucketorder: taskafter?.bucketorder,
          displayOrder: newdisplayorder,
          planid:taskafter.planid,
          bucketid:taskafter.bucketid,
          orderHint:""
        });
        log('newplan', newTasks);
    
      let updatedTask =  props.tasks.find(t => t.id == props.task?.id);
      if (updatedTask != undefined && updatedTask.project) 
      {
        const [start, end] = getStartEndDateForProject(newTasks, updatedTask.project);
        const project = newTasks[newTasks.findIndex(t => t.id === updatedTask?.project)];
        if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
        {
          const changedProject = { ...project, start, end };
          newTasks = newTasks.map(t => t.id === updatedTask?.project ? changedProject : t);
        }
      }
      let newOrder:string =  (taskbefore?taskbefore.orderHint:"") + ' ' + taskafter.orderHint  + "!";
      newOrder = newOrder.replaceAll('"', '\"');
      newOrder = newOrder.replaceAll('\\', '\\\\');
      props.insertTask(taskafter.planid, taskafter.bucketid, newTasks, props.task?.id as string, taskStart as Date, taskDue as Date, taskName, props.action, newOrder).then((v)=>{log("task created to update", v)});
    }
  }
  const insertTaskBelow = () => 
  {
    let newTasks = props.tasks.map(t => {return t;});
    if (props.action == "insertbelow") //new task
    {
        //create task
        let taskbefore =  props.tasks.find(t => t.id == props.task?.id);
        log('B: taskbefore?.displayOrder', taskbefore?.displayOrder);
        if (taskbefore == undefined)
        { return;}
        log('taskbefore', taskbefore);
        let taskafter =  undefined;
        let newdisplayorder = 1000;
        //Find task after current task
        for (let i=0; i<newTasks.length;i++)
        {
          if (newTasks[i].type == "task" && newTasks[i].project == taskbefore?.project)
          {
            
            if ((taskafter == undefined 
              || (newTasks[i]?.displayOrder as number) < (taskbefore?.displayOrder as number) )
              && (newTasks[i]?.displayOrder as number) > (taskafter?.displayOrder as number))
            {
              taskafter = newTasks[i];
            }
          }
        }
        log('B: taskafter?.displayOrder', taskafter?.displayOrder);
        //calculate display order
        if (taskbefore != undefined && taskbefore.displayOrder != undefined)
        {
          if (taskafter == undefined || taskafter.displayOrder == undefined)
          {
            let projectentity = props.tasks.find(t => t.id == props.task?.project);
            log("projectentity", projectentity);
            if (projectentity != undefined && projectentity.displayOrder != undefined)
            {
              newdisplayorder = taskbefore?.displayOrder + 1;
            } else
            {
              log("new display order could not be defined");
            }
          } else
          {
            newdisplayorder = taskbefore?.displayOrder - (taskbefore?.displayOrder-taskafter?.displayOrder)/2;
          }
        }
        log('B: newdisplayorder',newdisplayorder);
        log('tasks around & display order', taskbefore, taskafter, newdisplayorder);
        newTasks.push({
          start: taskStart as Date,
          end: taskDue as Date,
          name: taskName,
          id:  taskName,
          progress: 0,
          type: "task",
          project:taskbefore?.project,
          styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },
          description: '...',
          //bucketorder: taskafter?.bucketorder,
          displayOrder: newdisplayorder,
          planid:taskbefore.planid,
          bucketid:taskbefore.bucketid,
          orderHint:""
        });
        log('Updated plan tasks', newTasks);
    
      let updatedTask =  props.tasks.find(t => t.id == props.task?.id);
      if (updatedTask != undefined && updatedTask.project) 
      {
        const [start, end] = getStartEndDateForProject(newTasks, updatedTask.project);
        const project = newTasks[newTasks.findIndex(t => t.id === updatedTask?.project)];
        if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
        {
          const changedProject = { ...project, start, end };
          newTasks = newTasks.map(t => t.id === updatedTask?.project ? changedProject : t);
        }
      }
      let newOrder:string =  (taskafter?taskafter.orderHint:"") + ' ' + taskbefore.orderHint  + "!";
      newOrder = newOrder.replaceAll('"', '\"');
      newOrder = newOrder.replaceAll('\\', '\\\\');
      props.insertTask(taskbefore.planid, taskbefore.bucketid, newTasks, props.task?.id as string, taskStart as Date, taskDue as Date, taskName, props.action, newOrder).then((v)=>{log("task created to update", v)});
    }
  }
 
  return (
    <>
        {props.open &&
        <>
            <Dialog style={{zoom:'95%'}}/*style={{width:400}} */
                //cancelButton=""
                confirmButton={closing?<div style={{userSelect:'none'}}>Saving...</div>:<div>Done</div>}
                content={
                  <div style={{marginLeft:30, marginRight:30}}>
                    <Grid
                        columns="1"
                        data-builder-id="gmjq274e5dv"
                        rows="50px * 80px"
                    >
                        <span style={{marginTop:10}}>Name</span>
                        <TextArea placeholder="Type task name here..." value={taskName} disabled={closing}  onChange={(tev, evvalue)=>{setTaskName(evvalue?.value?evvalue?.value:"")}} styles={{height:32}} required={true} />
                        <Flex column style={{marginTop:10}}>
                            <span style={{marginTop:10}}>Start date</span>
                            <Datepicker disabled={closing} data-builder-id="23suieemq2bh" style={{marginTop:5}} selectedDate={taskStart} onDateChange={(ev, evvalue)=>{ setTaskStart(evvalue?.value)}} />
                            <span style={{marginTop:10}}>Due date</span>
                            <Datepicker disabled={closing} data-builder-id="pp0n4k1ixz" style={{marginTop:5}} selectedDate={taskDue} onDateChange={(ev, evvalue)=>{ setTaskDue(evvalue?.value)}} />
                        </Flex>
                        
                        <Flex column  >
                        <span style={{marginTop:10}}>Progress</span>
                        {/*<Slider disabled={closing} fluid min={0} max={100} value={pctCompleteSliderValue} onChange={(e:any, a:any)=>{setPctCompleteSliderValue(a?.value)}} ></Slider>*/}
                        <Dropdown
                            
                            items={['Not Started', 'In Progress', 'Completed']}
                            placeholder="Select an option"
                            checkable
                            onChange={(e:any, a:any)=>{setPctCompleteSliderValue(a?.value=='In Progress'?50:(a?.value=='Completed'?100:0))}}
                            value={pctCompleteSliderValue == 0?'Not Started':(pctCompleteSliderValue == 100?'Completed': 'In Progress')}
                            disabled={closing}
                        />
                        </Flex>
                        
                    </Grid>
                  </div>
                }
                header={props.planName}
                //headerAction="Task #1"
                //onAbort={()=>{setDetailsMenuVisible(false)}}
                onConfirm={()=>{if (!closing) {log('Confirm');taskdialogclose();}}}
                onCancel={()=>{if (!closing) {log('Cancel');taskdialogclose();}}}
                open={props.open}
            ></Dialog>
        
        </>
        }
    </>
  );
}
