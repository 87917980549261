import React, { useContext, useMemo, useState } from "react";
import styles from "./task-list-table.module.css";
import { Task } from "../../types/public-types";
import GanttTaskSubMenu from "./GanttTaskSubMenu";
import { ArrowDownIcon, Avatar, Tooltip } from "@fluentui/react-northstar";
import arrow from '../../../../images/arrow.png';
import whitearrow from '../../../../images/whitearrow.png';
import MarkCompleted from "./MarkCompleted";
import { TeamsFxContext } from "../../../Context";
import { GanttTask } from "../../../../ganttmods/publictypes";
import { cutoffText, randomNumberFromText } from "../../../helper";
import { AssignmentAvatar } from "../../../../ganttmods/AssignmentAvatar";

const localeDateStringCache = {};
const toLocaleDateStringFactory =
  (locale: string) =>
  (date: Date, dateTimeOptions: Intl.DateTimeFormatOptions) => {
    const key = date.toString();
    let lds = (localeDateStringCache as any)[key];
    if (!lds) {
      lds = date.toLocaleDateString(locale, dateTimeOptions);
      (localeDateStringCache as any)[key] = lds;
    }
    return lds;
  };
const dateTimeOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
  onDetailsMenuClick: (task: Task, action:number) => void;
  onMarkCompletedClick: (task: Task) => void;
  onPlanClick: (task: Task) => void;
  onAssignmentsMenuClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
  onDetailsMenuClick,
  onMarkCompletedClick,
  onPlanClick,
  onAssignmentsMenuClick
}) => {
  const toLocaleDateString = useMemo(
    () => toLocaleDateStringFactory(locale),
    [locale]
  );
  const { themeString } = useContext(TeamsFxContext);
  
  return (
    <div
      className={styles.taskListWrapper}
      style={{
        //fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {tasks.map(t => {
        let expanderSymbol = "";
        if (t.hideChildren === false) {
          expanderSymbol = "▼";
        } else if (t.hideChildren === true) {
          expanderSymbol = "▶";
        }

        return (
          <div 
            draggable={t.type === "task"?false:false}
            onDragStart={(event:any)=>{
              event.target.style.opacity=1;
              //sourceElement = event.target;
              event.dataTransfer.effectAllowed = 'move';
            }}
            className={themeString === "default" ? styles.taskListTableRow : styles.taskListTableRowDark}
            style={{ height: rowHeight }}
            key={`${t.id}row`}
          >
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
              title={t.name}
            >
              <div className={styles.taskListNameWrapper}>
                <div
                  className={
                    expanderSymbol
                      ? styles.taskListExpander
                      : styles.taskListEmptyExpander
                  }
                  onClick={() => onExpanderClick(t)}
                >
                  
                  {t.type != "project"?<MarkCompleted tsk={t}  onMarkCompletedClick={onMarkCompletedClick}  />:<></>}
                  {
                    expanderSymbol && (t as GanttTask).isplan != true
                      ? <img className={styles.taskListExpanderImage} style={{transform:expanderSymbol=='▼'?'rotate(90deg)':'rotate(0deg)'}} src={themeString === "default" ? arrow:whitearrow}></img>
                      : <></>
                  }
                </div>
                {(t as GanttTask).isplan == true &&  
                  <Tooltip content="Expand/collapse all tasks" pointing trigger=
                  {
                    <div style={{display:"flex", cursor:"pointer", userSelect:"none"}} onClick={()=>{onPlanClick(t)}}>
                      <Avatar key={(t as GanttTask).planid} name={(t as GanttTask).name} square size={'small'} style={{marginRight:10}} className={"planavatarclstxt planavatarcls" + randomNumberFromText((t as GanttTask).id, 20)} />
                      <div className={styles.taskplanheadertext}>{t.name}</div>
                    </div>
                  } />
                }
                {(t as GanttTask).isplan != true && 
                  <div className={(t.type === "project"?styles.taskprojectheadertext:styles.tasktaskheadertext)}>{cutoffText(t.name, 32)}</div>
                }
              </div>
            </div>
            <div
              className={styles.taskListCell}
              style={{
                minWidth: 30,
                maxWidth: 30,
              }}
              
            >
              <AssignmentAvatar task={t as GanttTask} onAssignmentsMenuClick={onAssignmentsMenuClick} />
            </div>
            <div
              className={styles.taskListCell}
              style={{
                minWidth: 30,
                maxWidth: 30,
              }}
              
            >
            {t.type != "project"?<GanttTaskSubMenu tsk={t}  onDetailsMenuClick={onDetailsMenuClick}  />:<></>}
            </div>
            
            {/*<div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              &nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
            </div>
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              &nbsp;{toLocaleDateString(t.end, dateTimeOptions)}
            </div>*/}
          </div>
        );
      })}
    </div>
  );
};
