import React, { useEffect } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import "./App.css";
import TabConfig from "./TabConfig";
import { useTeams } from "msteams-react-base-component";

import { Playground } from "./coreapp/teams/Playground";

import  Home  from "./website/Home";
import  PlannerGantt  from "./website/PlannerGantt";
import { Me } from "./authentication/Me";
import { LoginTest } from "./authentication/LoginTest";
import { TeamsFxContext } from "./Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { log } from "./helper";
import { ResourceOverview } from "./coreapp/teams/availability/ResourceOverview";


const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
}
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  //const { theme } = useTeams({})[0];
  const { loading, theme, themeString, teamsUserCredential} = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!
  });
  const themeupdate = useTeams({setThemeHandler(theme: string | undefined) {
    log("setThemeHandler", theme);
  },});
  
  useEffect(()=>{
    log("color theme", themeString, theme);
  }, [themeString, theme, teamsUserCredential, themeupdate, themeupdate[0].themeString]);

  return (
    <TeamsFxContext.Provider value={{theme, themeString, teamsUserCredential}}>
    <Provider theme={theme || teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
      <Router>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        {loading ? (<>loading...</>) : (<>
          <Route exact path="/privacy" component={()=><Home page="privacy" />} />
          <Route exact path="/terms" component={()=><Home page="terms" />} />
          <Route exact path="/me" component={LoginTest} />
          {/*<Route exact path="/tab" component={Playground} />
          <Route exact path="/playground" component={Playground} />*/}
          <Route exact path="/tab" component={Playground} />
          <Route exact path="/playground" component={Playground} />
          <Route exact path="/config" component={TabConfig} />
          <Route exact path="/home" component={()=><Home page="home" />} />
          <Route exact path="/about" component={()=><Home page="about" />} />
          <Route exact path="/contact" component={()=><Home page="contact" />} />
          <Route exact path="/getstarted" component={()=><Home page="getstarted" />} />
          <Route exact path="/newfeature" component={()=><Home page="newfeature" />} />
          <Route exact path="/authpeoplepermissions" component={()=><Home page="authpeoplepermissions" />} />
          <Route exact path="/plannergantt" component={PlannerGantt} />
        </>)}
      </Router>
    </Provider>
    </TeamsFxContext.Provider>
  );
}
