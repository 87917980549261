import * as React from 'react';

import { Dialog,  DialogFooter, Flex, Grid, TextArea, Text, CloseIcon, Button, FilesTextColoredIcon, Datepicker, Loader } from "@fluentui/react-northstar";
import { log } from '../../../helper';
import ReactToPrint from 'react-to-print';
import { PlanGanttMultiple } from '../PlanGanttMultiple';
import { TeamsFx } from '@microsoft/teamsfx';
import { GanttPlan, GanttTask, Person, PrintGanttTasks } from '../../../../ganttmods/publictypes';
import { Gantt, Task, ViewMode } from '../../../gantt';
import { GanttTableHeader } from '../../../../ganttmods/GanttTableHeader';
import { addToDate, ganttDateRange, seedDates, startOfDate } from '../../../gantt/helpers/date-helper';
import { ZoomControlPrint } from '../ZoomControlPrint';
import { PrintIcon } from '../../../images/PrintIcon';



export function  PrintGantt(
        props:
        {
            teamsfx?: TeamsFx, 
            plans:GanttPlan[], 
            people:Person[] | null, 
            getTasksForPrint:((printplans:GanttPlan[]) => Promise<PrintGanttTasks[]>) | null
        }) 
{
    const [closing, setClosing] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [printtasks, setPrintTasks] = React.useState<PrintGanttTasks[]>([]);
    const [displayPrinttasks, setDisplayPrintTasks] = React.useState<PrintGanttTasks[]>([]);
    const [nocolumns, setNoColumns] = React.useState<number>(10);
    const [preStepsCount, setPreStepsCount] = React.useState<number>(0);
    const [viewMode, setViewMode] = React.useState<ViewMode>();
    const [loading, setLoading] = React.useState<boolean>(true);

    const gantcontentref = React.useRef<HTMLDivElement>(null);
    const { teamsfx } = {
        teamsfx: undefined,
        ...props,
    };
    React.useEffect(() => {
        if (open == true)
        {
            setLoading(true);
            log('Print Gantt', props.plans);
            if (props.getTasksForPrint != null)
            {
                props.getTasksForPrint(props.plans).then((printtasks:PrintGanttTasks[])=>
                    {
                        log('Tasks Loaded', printtasks);
                        if (printtasks.length > 0) 
                        {
                            
                            let tasks:Task[] = printtasks.flatMap((printplan:PrintGanttTasks, index:number)=> {return printplan.tasks as Task[]});
                            let lprintStartDate:Date = tasks.filter((t:Task)=>{return t.start != undefined}).sort((a,b)=>{return a.start.getTime() - b.start.getTime()})[0].start;
                            lprintStartDate = startOfDate(lprintStartDate, "day");
                            
                            let lprintEndDate:Date = tasks.filter((t)=>{return t.end != undefined}).sort((a,b)=>{return b.end.getTime() - a.end.getTime()})[0].end;
                            lprintEndDate = startOfDate(lprintEndDate, "day");
                            
                            
                            let daysbetween = Math.ceil((lprintEndDate.getTime() - lprintStartDate.getTime()) / (1000 * 60 * 60 * 24));
                            let lviewmode:ViewMode = ViewMode.Day;
                            if (daysbetween > 360)
                            {
                                lviewmode = ViewMode.Year;
                            } else if (daysbetween > 30)
                            {
                                lviewmode = ViewMode.Month;
                            } else  if (daysbetween > 14)
                            {
                                lviewmode = ViewMode.Week;
                            } else 
                            {
                                lviewmode = ViewMode.Day;
                            } 
                            
                            let lpreStepsCount = 0;
                            setPreStepsCount(lpreStepsCount);
                            
                            let [startDate, endDate] = ganttDateRange(
                                tasks,
                                lviewmode,
                                lpreStepsCount,
                                );

                            let newDates = seedDates(startDate, endDate, lviewmode);
                            setViewMode(lviewmode);
                            setNoColumns(newDates.length);
                            setDisplayPrintTasks(printtasks);
                            log("printtasks", printtasks);  
                            setLoading(false);
                        }
                    });
            }
        }
    }, [open]);
    React.useEffect(() => {
        
            
        
    }, [printtasks]);
   
    return (
        <>
            <PrintIcon width={32} height={32} color="black" onClick={()=>{setOpen(true);}}/>
            {open && <Dialog style={{width: '850px', height: '80vh'}} backdrop={false}
                //cancelButton=""
                //confirmButton={closing?<div style={{userSelect:'none'}}>Printing...</div>:<div>Print</div>}
                onConfirm={()=>{if (!closing) {log('Print');setOpen(false);}}}
                open={open}
                
                content=
                {
                    <>
                        <div style={{overflow:'scroll', height:'calc(80vh - 191px)'}}>
                            
                            {loading && <Loader label="Preparing page(s)..." style={{marginTop:'25vh'}} />}
                            {!loading && <div style={{zoom:'1'}}>
                                <div ref={gantcontentref} style={{ overflow:'visible', opacity:1, transition:'none', zIndex:999, animation:'none', width:750, marginLeft:'auto', marginRight:'auto', marginTop:'0', marginBottom:'0'}}>
                                    {displayPrinttasks.map((printplan:PrintGanttTasks, index:number)=>
                                        {
                                            return <div key={printplan.pagenumber} style={{border:'0px solid gray'}}>
                                                <Gantt  printmode={true} viewMode={viewMode}
                                                    tasks={(true)?printplan.tasks:printplan.tasks.filter((t)=>{return t.progress == undefined || t.progress < 100 || t.isplan == true || t.type == 'project'})}
                                                    onDetailsMenuClick={()=>{}}
                                                    onMarkCompletedClick={()=>{}}
                                                    onPlanClick={()=>{}} 
                                                    onAssignmentsMenuClick={()=>{}}
                                                    
                                                    todayColor='transparent'
                                                    barCornerRadius={2}
                                                    preStepsCount={preStepsCount}
                                                    //listCellWidth={true ? 155+"px" : ""}
                                                    columnWidth={Math.floor(750/nocolumns)}
                                                    
                                                    
                                                    hidechart={false}
                                                /></div>;
                                        })
                                    }
                                    
                                </div>
                            </div>}
                        </div>
                        <div style={{float:'right', display:'flex', marginRight:'30px', marginTop:'40px'}}>
                            <Button content="Close" onClick={()=>{setOpen(false);}} style={{margin:'5px'}}  />
                            <div style={{margin:'5px'}}><ReactToPrint copyStyles={true}            
                                trigger={() => <Button content="Print" primary disabled={loading}  />}
                                content={() => gantcontentref.current as any}
                                onAfterPrint={()=>{setOpen(false);}}
                            /></div>
                            
                        </div>
                    </>
                }
                headerAction={{
                    icon: <CloseIcon />,
                    title: 'Close',
                    onClick: () => {if (!closing) {setOpen(false);}},
                }}
                
            ></Dialog>}
        </>
    );
};

