import { useEffect, useState } from "react";
import { BarTask } from "../../types/bar-task";
export type TaskGanttTodaymarkerContentProps = {
    tasks: BarTask[];
    dates: Date[];
    rowHeight: number;
    columnWidth: number;
  } ;
export const TaskGanttTodaymarker: React.FC<TaskGanttTodaymarkerContentProps> = ({
    tasks,
    dates,
    rowHeight,
    columnWidth,
  }) => {
    
    const [todaysdatexindex, setTodaysdatexindex] = useState(-1);

    useEffect(() => 
    {
        let xDate = new Date();
        const index = dates.findIndex(d => d.getTime() >= xDate.getTime()) - 1;
        if (index < 0) {
            return;
        }
        const remainderMillis = xDate.getTime() - dates[index].getTime();
        const percentOfInterval = remainderMillis / (dates[index + 1].getTime() - dates[index].getTime());
        const x = index * columnWidth + percentOfInterval * columnWidth;
        setTodaysdatexindex(x);
    }, [dates, columnWidth]);
    return (
        <>
            {(todaysdatexindex >= 0) && 
                <g>
                    
                    <circle cx={todaysdatexindex} cy={5} r={5} fill="#0a7cff" />
                    <line x1={todaysdatexindex} y1={0} x2={todaysdatexindex} opacity={.8} y2={rowHeight * tasks.length} stroke="#0a7cff" strokeWidth={2} />
                    
                </g>
            }
        </>
        );
    };
    