import React from "react";




export const GanttTableHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
  return (
    <div
      //className={styles.ganttTable}
      style={{
        display: 'table',
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      <div
        //className={styles.ganttTable_Header}
        style={{
          display: 'table-row',
          height: headerHeight - 2,
        }}
      >
        <div
          //className={styles.ganttTable_HeaderItem}
          style={{
            
            minWidth: rowWidth,
          }}
        >
        
        </div>
        
      </div>
    </div>
  );
};
