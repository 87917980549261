import React, { useEffect } from "react";
import style from "./bar.module.css";
import { Label, iLabel } from "../../../../../ganttmods/publictypes";
import { countCapitalLetters } from "../../../../helper";
import { BarLabels } from "./bar-labels";

type BarDisplayProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  isSelected: boolean;
  /* progress start point */
  progressX: number;
  progressWidth: number;
  barCornerRadius: number;
  styles: {
    backgroundColor: string;
    backgroundSelectedColor: string;
    progressColor: string;
    progressSelectedColor: string;
  };
  onMouseDown: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void;
  onMouseEnter: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void;
  onMouseLeave: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void;
  onMouseUp: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void;
  highlight: boolean;
  labels:Label[];
  labelsclicked:()=>void;
  showLabelTitles:boolean;
};
export const BarDisplay: React.FC<BarDisplayProps> = ({
  x,
  y,
  width,
  height,
  isSelected,
  progressX,
  progressWidth,
  barCornerRadius,
  styles,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
  highlight,
  labels,
  labelsclicked,
  showLabelTitles,
}) => {

  const getProcessColor = () => {
    return isSelected ? styles.progressSelectedColor : styles.progressColor;
  };

  const getBarColor = () => {
    return isSelected ? styles.backgroundSelectedColor : styles.backgroundColor;
  };


  return (
    <>
      
      <BarLabels x={x} y={y} 
        barCornerRadius={barCornerRadius} 
        labels={labels} 
        labelsclicked={labelsclicked}
        showLabelTitles={showLabelTitles} />
      <g onMouseDown={onMouseDown} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseUp={onMouseUp}>
        
        <rect
          x={x}
          width={width}
          y={y}
          height={height}
          ry={barCornerRadius}
          rx={barCornerRadius}
          fill={getBarColor()}
          className={style.barBackground}
        />
        <rect
          x={progressX}
          width={progressWidth}
          y={y}
          height={height}
          ry={barCornerRadius}
          rx={barCornerRadius}
          fill={getProcessColor()}
        />
        {highlight && <rect
          stroke="#eaa300"
          strokeWidth={2}
          x={x+1}
          width={width-2}
          y={y+1}
          height={height-2}
          ry={barCornerRadius}
          rx={barCornerRadius}
          fill={'transparent'}
        />}
      </g>
      
    </>
  );
};
