// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Dialog, Dropdown, DropdownProps, Flex, Grid, Loader, Pill, Popup, Segment, Text } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState, useContext } from "react";
import { GanttTableHeader } from "../../../ganttmods/GanttTableHeader";
import GanttTooltipContent from "../../../ganttmods/GanttTooltipContent";
import { ErrorSection } from "../../errorandlogs/ErrorSection";
import { Gantt, Task, ViewMode } from "../../gantt";
import { TaskListTableDefault } from "../../gantt/components/task-list/task-list-table";
import { PlanColors, getDependenciesFromAttachments, getDependenciesFromDescription, getDependencyKeyFromId, getFirstDate, getLabelsforplan, getLabelsfortask, getLastDate, getMaxDate, getMinDate, getNextTaskOrProject, getStartEndDateForProject, getTaskAfter, getTaskBefore, getTaskProject, log, ordinalCompareStr1BeforeStr2, randomNumberFromText, replaceDependenciesToDescription } from "../../helper";
import { GanttTaskDialog, TaskAction } from "./taskdetails/GanttTaskDialog";
import { addDaysToDate } from "../../helper";
import { TeamsFxContext } from "../../Context";
import { PlanGanttOperator } from "./PlanGanttOperator";
import { Assignment, GanttPlan, GanttPlanDetails, GanttTask, Label, Person, PrintGanttTasks } from "../../../ganttmods/publictypes";

export function PlanGanttMultiple(props: 
  { 
    teamsfx?: TeamsFx, 
    plans:GanttPlan[],  
    viewMode:ViewMode, 
    viewModeZoomLevel:number, 
    showCompletedTasks:boolean, 
    planloaded:Function, 
    hidechart:boolean, 
    askforpermissions:()=>void, 
    people:Person[] | null,
    peopleLoaded:(people:Person[])=>void,
    getTasksForPrintReady:(f:Function)=>void
  }) {
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
  };
  const [loadedplansprogress, setloadedplansprogress] = useState<number>(0);
  const [tasks, setTasks] = useState<GanttTask[]>([]);
  const [plandetails, setPlandetails] = useState<GanttPlanDetails[]>([]);
  const [loadedPlanIds, setLoadedPlanIds] = useState<string | null>(null);
  //const [loadedPlanName, setLoadedPlanName] = useState<string>(props.planName?props.planName:"");
  //const [showCompletedTasks, setShowCompletedTasks] = useState<boolean>(props.showCompletedTasks);
  const [detailsMenuVisible, setDetailsMenuVisible] = useState<boolean>(false);
  const [detailsMenuTask, setDetailsMenuTask] = useState<Task | null>(null);
  const [menuaction, setMenuaction] = useState<TaskAction>("update");
  const { themeString } = useContext(TeamsFxContext);
  const [scrollx, setscrollx] = useState<number>(0);
  // For usage of useGraph(), please refer to: https://www.npmjs.com/package/@microsoft/teamsfx-react#usegraph.
  const { loading, error, data, reload } = useGraph(
    async (graph, teamsfx, scope) => 
    {
        let planids:string = "";
        props.plans.forEach((plan)=>{planids += plan.planid + ",";});
        setLoadedPlanIds(planids); 

        //setShowCompletedTasks(props.showCompletedTasks);
        setloadedplansprogress(0);
        log('0.', loading, error, data, reload, graph, teamsfx, scope )
        
        //setLoadedPlanName(props.planName?props.planName:"");

        let newplans:GanttTask[] = [];
        let newplandetails:GanttPlanDetails[] = [];
        for (let i=0; i<props.plans.length; i++)
        {
          let p = props.plans[i];
          newplandetails.push({groupid:p.groupid, planid:p.planid, planName:p.planName, ownerName:p.ownerName, ownerId:p.ownerId, labels:[]});
        }
        for (let m=0;m<props.plans.length;m++)
        {
            let groupid = props.plans[m].groupid;
            let planid = props.plans[m].planid;
            let planname = props.plans[m].planName;
            log(m + ".1. Loading Plan & Tasks", planname, planid); 
            let plandetails =  await graph.api("/planner/plans/"+planid+"/details").get();
            log(m + ".1.1. Plan details", plandetails);
            
            let newplanlabels:Label[] =  getLabelsforplan(plandetails.categoryDescriptions);
            let newplandetail = newplandetails.find((pd)=>{return pd.planid == planid;});
            if (newplandetail != null && newplandetail != undefined)
            {
              newplandetail.labels = newplanlabels;
            }
            let plantasksunsorted =  await graph.api("/planner/plans/"+planid+"/tasks?$expand=bucketTaskBoardFormat,details($select=references)").get();
            log(m + ".2. Raw Tasks", plantasksunsorted, "/planner/plans/"+planid+"/tasks?$expand=bucketTaskBoardFormat,details($select=references)");
            if (plantasksunsorted.value.length > 0)
            {
                
                /*let batchrequest = {requests:[]} as any; */
                /*Find which order the tasks should be displayed in */
                for (let i=0; i<plantasksunsorted.value.length; i++)
                {  
                    setloadedplansprogress(Math.round((m/props.plans.length + (1/props.plans.length) * i/plantasksunsorted.value.length) * 100 ));
                    /*batchrequest.requests.push({id:plantasksunsorted.value[i].id, method:"GET", url:"/planner/tasks/" + plantasksunsorted.value[i].id + "/bucketTaskBoardFormat"});*/
                    //let bucktskboardformat = await graph.api("/planner/tasks/" + plantasksunsorted.value[i].id + "/bucketTaskBoardFormat").get();
                    plantasksunsorted.value[i].bucktskboardformatOrderHint = plantasksunsorted.value[i].orderHint; //default order
                    plantasksunsorted.value[i].orderHint= plantasksunsorted.value[i].bucketTaskBoardFormat.orderHint; //order in buckets
                }
                
                /*let batch = await graph.api("/$batch").post(JSON.stringify(batchrequest));*/
                let plantasks = plantasksunsorted.value.sort((a:any, b:any)=>{return (ordinalCompareStr1BeforeStr2(a.orderHint,b.orderHint));}); 
                log(m + '.3. Sorted Tasks', plantasks);  
                let bucketsunsorted =  await graph.api("/planner/plans/"+planid+"/buckets").get();
                log(m + ".4. Raw Buckets", bucketsunsorted);
                let buckets = bucketsunsorted.value.sort((a:any, b:any)=>{return (ordinalCompareStr1BeforeStr2(b.orderHint, a.orderHint));});
                log(m + '.5 Sorted Buckets', buckets);
                
            
                let today:Date = new Date();
                let planstart = getFirstDate(plantasks);
                if (planstart)
                {
                    planstart = new Date(planstart);
                } else
                {
                    planstart = today;
                }
                let planend =  getLastDate(plantasks);
                if (planstart >= planend || planend == null || planend == undefined)
                {
                    planend = addDaysToDate(planstart, 10);
                }
                 
                let plancolor:string = PlanColors[randomNumberFromText(planid, 20)-1];
                let plangantttask:GanttTask = 
                    {
                        groupid:groupid,
                        planid: planid,
                        expanded:true,
                        isplan:true,
                        bucketid: "",
                        orderHint: "",
                        start: new Date(planstart),
                        end: new Date(planend),
                        name: planname,
                        id:  planid,
                        progress: 100,
                        //percentComplete: 100,
                        type: "project",
                        styles: { progressColor: plancolor, progressSelectedColor: plancolor,backgroundColor:plancolor, backgroundSelectedColor:plancolor },//{ progressColor: '#7B68EE', progressSelectedColor: '#7B68EE',backgroundColor:'#7B68EE', backgroundSelectedColor:'#7B68EE' }, //4BC984, 318456
                        description: planname,
                        hideChildren: false,
                        displayOrder: (m+1)*10000,
                        assignments:[],
                        labels:[]
                    };
                if (props.plans.length > 1)
                {
                  newplans.push(plangantttask);
                }
                for (let b=0; b<buckets.length; b++)
                {
                    let bucket = buckets[b];
                    let bucketstart = plantasks.reduce(function (a:any, b:any) { return a.startDateTime < b.startDateTime ? a : b; }).startDateTime;
                    let bucketend =  plantasks.reduce(function (a:any, b:any) { return a.dueDateTime > b.dueDateTime ? a : b; }).dueDateTime;
                    let bucketgantttask:GanttTask = 
                    {
                        groupid:groupid,
                        planid: planid,
                        expanded:true,
                        isplan:false,
                        bucketid: "",
                        orderHint: "",
                        start: bucketstart?new Date(bucketstart):today,
                        end: bucketend?new Date(bucketend):today,
                        name: bucket.name,
                        id:  bucket.id,
                        progress: 100,
                        //percentComplete: 100,
                        type: "project",
                        styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },//{ progressColor: '#7B68EE', progressSelectedColor: '#7B68EE',backgroundColor:'#7B68EE', backgroundSelectedColor:'#7B68EE' }, //4BC984, 318456
                        description: bucket.name,
                        hideChildren: false,
                        displayOrder: (m+1)*10000+(b+1)*1000,
                        assignments:[],
                        labels:[]
                    };
                    if (plantasks.filter((tsk:any)=>{return tsk.bucketId == bucket.id;}).length >= 1)
                    {
                        let buckettasks = plantasks.filter((tsk:any)=>{return tsk.bucketId == bucket.id;});
                        bucketgantttask.start = new Date(buckettasks.reduce(function (a:any, b:any) //Find first start date
                            { 
                                if (b.startDateTime == null) return a;
                                if (a.startDateTime == null) return b;
                                if (new Date(a.startDateTime).getTime() < new Date(b.startDateTime).getTime()) 
                                    return  a; 
                                else 
                                    return b;
                            }).startDateTime); 
                        bucketgantttask.end = new Date(buckettasks.reduce(function (a:any, b:any) //Find last end date
                            { 
                                if (b.dueDateTime == null) return a;
                                if (a.dueDateTime == null) return b;
                                if (new Date(a.dueDateTime).getTime() > new Date(b.dueDateTime).getTime()) 
                                    return  a; 
                                else 
                                    return b;
                            }).dueDateTime);
                        
                        bucketgantttask.start = (bucketgantttask.start.getTime() <= new Date(1970, 1, 2).getTime())?today:bucketgantttask.start;
                        bucketgantttask.end = (bucketgantttask.end.getTime() <= new Date(1970, 1, 2).getTime())?today:bucketgantttask.end;
                        if (bucketgantttask.end.getTime()<=bucketgantttask.start.getTime())
                        {
                            bucketgantttask.end = addDaysToDate(bucketgantttask.start, 1);
                        }
                    
                        newplans.push(bucketgantttask);
                    

                        for (let i=0; i<plantasks.length; i++)
                        {              
                        let t = plantasks[i];
                        if (t.bucketId == bucket.id)
                        {
                            //setloadedplansprogress(Math.round(newplan.length/(plantasks.length + buckets.length-1) * 100));
                            
                            let startDate = t.startDateTime?new Date(t.startDateTime):bucketgantttask.start;
                            let endDate = t.dueDateTime?new Date(t.dueDateTime): addDaysToDate(startDate, 1);
                            if (endDate.getTime()<=startDate.getTime())
                            {
                                endDate = addDaysToDate(startDate, 1);
                            }
                            
                            let dependencies:string[] = getDependenciesFromAttachments(t.details.references);
                            //let bucktskboardformat = await graph.api("/planner/tasks/" + t.id + "/bucketTaskBoardFormat").get();
                            let assns:Assignment[] = [];
                            for (var assn in t.assignments) 
                            {
                              if (Object.prototype.hasOwnProperty.call(t.assignments, assn)) 
                              {
                                if (props.people == null || props.people == undefined)
                                {
                                  let assignment:Assignment = {name:'NL', id:assn};
                                  assns.push(assignment);
                                } else
                                {
                                  if (props.people.some((p:Person)=>{return p.id == assn}))
                                  {
                                    let name = props.people.find((p:Person)=>{return p.id == assn})?.name;
                                    if (name != undefined && name != null)
                                    {
                                      let assignment:Assignment = {name:name, id:assn};
                                      assns.push(assignment);
                                    } else
                                    {
                                      let assignment:Assignment = {name:'N/A', id:assn};
                                      assns.push(assignment);
                                    }
                                  }
                                }

                              }
                            }
                            let labels:Label[] = getLabelsfortask(t.appliedCategories, plandetails.categoryDescriptions);
                            
                            
                            let gantttask:GanttTask = 
                            {
                                groupid: groupid,
                                planid: planid,
                                expanded:true,
                                isplan:false,
                                start: startDate,
                                end: endDate,
                                name: t.title,
                                id:  t.id,
                                progress: t.percentComplete,
                                //percentComplete: t.percentComplete,
                                type: "task",
                                project:bucket.id,
                                styles: 
                                    t.percentComplete == 100?
                                    (
                                        themeString === "default" ? 
                                        {progressColor: '#e1e1e1', progressSelectedColor: '#bababa',backgroundColor:'#e1e1e1', backgroundSelectedColor:'#bababa ', barLabel:'#000000'} 
                                        : 
                                        {progressColor: '#5c5c5c', progressSelectedColor: '#999999',backgroundColor:'#5c5c5c', backgroundSelectedColor:'#999999 ', barLabel:'#fff'} 
                                    )
                                    :
                                    { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF'},
                                description: t.title,
                                orderHint: t.orderHint,//bucktskboardformat.orderHint,
                                displayOrder: (m+1)*10000+(b+1)*1000+i+1,
                                bucketid: t.bucketId,
                                dependencies: dependencies,
                                assignments:assns,
                                labels:getLabelsfortask(t.appliedCategories, plandetails.categoryDescriptions)

                            };
                            
                            
                            newplans.push(gantttask);
                        }
                        }
                    }
                }
                
                log("6. Tasks loaded", newplans);
            } else
            {
                log("no tasks found in this plan", planname);            
            }
        }
        
        
        
        let savePlannerTask = async (planid:string, taskid:string, start:Date, end:Date):Promise<string> =>
        {
            graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
            {
                let plantasks =  graph.api("/planner/tasks/"+taskid)
                    .headers({'If-Match':tsk['@odata.etag']})
                    .patch('{"startDateTime":"'+start.toISOString()+'", "dueDateTime":"'+end.toISOString()+'"}')
                    .then(()=>{log("Task Saved");})
                    .catch((ex:any)=>{log("Task Save Failed", ex);});
            });
            return "Success";
        }
        let savePlannerTaskDetails = async (planid:string, taskid:string, start:Date, end:Date, name:string, percentComplete:number, labels:Label[]):Promise<string> =>
        {
          
            graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
            {
                let plantasks =  graph.api("/planner/tasks/"+taskid)
                    .headers({'If-Match':tsk['@odata.etag']})
                    .patch('{"startDateTime":"'+start.toISOString()+'", "dueDateTime":"'+end.toISOString()+'", "title":"'+name + '", "percentComplete":'+ percentComplete + '}')
                    .then(()=>
                      {
                        log("Task Details Saved");
                        let labelspatch:any = {};
                        for (let l=1;l<25;l++)
                        {
                          let catid = "category" + l;
                          if (labels.some((label:Label)=>{return label.key == catid;}) == true)
                          {
                            labelspatch["category" + l] = true;
                          } else
                          {
                            labelspatch["category" + l] = false;
                          }
                        }
                        let d = JSON.stringify(labelspatch);
                        return graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
                        {
                            let plantasks =  graph.api("/planner/tasks/"+taskid)
                                .headers({'If-Match':tsk['@odata.etag']})
                                .patch('{"appliedCategories": ' + JSON.stringify(labelspatch) + "}")
                                .then(()=>{log("Labels Updated");})
                                .catch((ex:any)=>{log("Labels Update Failed", ex);});
                        });
                      })
                    .catch((ex:any)=>{log("Task Details Save Failed", ex);});
            });
            return "Success";
        }
        let newPlannerTask = async (planid:string, bucketid:string, start:Date, end:Date, name:string, beforetask:Task | undefined, aftertask:Task | undefined):Promise<any> =>
        {
            log("New Planner Task", planid, bucketid, name, start, end,  beforetask, aftertask);
            let newtask =  await graph.api("/planner/tasks")
                .post('{"planId": "'+planid+'","bucketId": "' + bucketid + '","title": "'+name+'","startDateTime":"'+start.toISOString()+'", "dueDateTime":"'+end.toISOString()+'"}')
                .then((v)=>
                {
                  log("Task Created, next get and update orderHint", v); 
                  let bucktskboardformat = graph.api("/planner/tasks/" + v.id + "/bucketTaskBoardFormat").get().then((btoh)=>
                  {
                    let newtaskorder = rearrangetasks(planid, beforetask, aftertask, v);
                    
                    return newtaskorder;
                  });
                  

                  return v;
                })
                .catch((ex:any)=>{log("New Task Save Failed", ex);});  
            return newtask;          
        }
        /*let newTaskOrder = async (planid:string, taskid:string, orderHint:string):Promise<any> =>
        {
            log("New Order", taskid, orderHint);
            let newOrder = orderHint.replaceAll('"', '\"');
            newOrder = newOrder.replaceAll('\\', '\\\\');
            let bucktskboardformat = graph.api("/planner/tasks/" + taskid + "/bucketTaskBoardFormat").get().then((btoh)=>
            {
              log("Old Task Order and etag", btoh);
              let plantasks =  graph.api("/planner/tasks/"+taskid+"/bucketTaskBoardFormat")
              .headers({'If-Match':btoh['@odata.etag']})
              .patch('{"orderHint":"'+newOrder+'"}')
              .then((ov)=>{
                log("New Task Order Updated", ov);
                let newbucktskboardformat = graph.api("/planner/tasks/" + taskid + "/bucketTaskBoardFormat").get().then((newbtoh)=>
                {
                  log("New Task Order", newbtoh);
                  return newbtoh;
                });
                return newbucktskboardformat;
              })
              .catch((ex:any)=>{log("New Task OrderHint Save Failed", ex);});
              return plantasks;
            });
            return bucktskboardformat;
        }*/
        let rearrangetasks = async (planid:string, beforetask:Task | undefined, aftertask:Task | undefined, tasktomove:Task):Promise<string> =>
          {

              log("Rearrange Tasks", beforetask, aftertask, tasktomove);
              let beforetaskorderhint = "";
              if (beforetask && beforetask.id)
              {
                let refreshedbeforetask = await graph.api("/planner/tasks/" + beforetask.id + "/bucketTaskBoardFormat").get();
                beforetaskorderhint = refreshedbeforetask.orderHint;
              }
              let aftertaskorderhint = "";
              if (aftertask && aftertask.id)
              {
                let refreshedaftertask = await graph.api("/planner/tasks/" + aftertask.id + "/bucketTaskBoardFormat").get();
                aftertaskorderhint = refreshedaftertask.orderHint;
              }
              
              let etag = await graph.api("/planner/tasks/" + tasktomove.id + "/bucketTaskBoardFormat").get();

              let newOrder = beforetaskorderhint + ' ' + aftertaskorderhint + "!";
              newOrder = newOrder.replaceAll('"', '\"');
              newOrder = newOrder.replaceAll('\\', '\\\\');
              let rearrangerslt =  await graph.api("/planner/tasks/"+tasktomove.id+"/bucketTaskBoardFormat")
                .headers({'If-Match':etag['@odata.etag']})
                .patch('{"orderHint":"'+newOrder+'"}');
              
              let newbucktskboardformat = await graph.api("/planner/tasks/" + tasktomove.id + "/bucketTaskBoardFormat").get()

              return (newbucktskboardformat && newbucktskboardformat.orderHint)?newbucktskboardformat.orderHint: " ";
          }
        let savePlannerDependencies = async (taskid:string, dependencies:string[]):Promise<void> =>
        {
          
            graph.api("/planner/tasks/"+taskid + "/details?$select=description").get().then((tsk:any)=>
            {
              let etag:string  = tsk['@odata.etag'];
              let description:string = tsk.description;
              let newdescription:string = replaceDependenciesToDescription(description, dependencies);
              //newdescription = description.trimStart().trimEnd().replaceAll('\r\n\r\n', '\r\n').replaceAll('\r\n', '\n')  + " ";
              var match =  description.match(/(?<!\\r\\n)\\r\\n/g);
              if (match != null && match.length > 0)
              {
                newdescription = description.replace(match[0], "").replaceAll('\r\n', '\n');
                
              }
              //newdescription = description.trimStart().trimEnd().replaceAll('\r', '\\r').replaceAll('\\n', '\\n')  + " ";
              let plantasks =  graph.api("/planner/tasks/"+taskid + "/details")
                  .headers({'If-Match':tsk['@odata.etag']})
                  .patch('{"description":"'+newdescription+'"}')
                  .then(()=>{log("Task Description/Dependencies Saved");})
                  .catch((ex:any)=>{log("Task Description/Dependencies Save Failed", ex);});
              });
            return;
        }
        let savePlannerAttacmentDependencies = async (taskid:string, dependentOnTaskId:string, dependentOnTaskName:string, dependentOnPlanId:string, dependentOnGroupId:string, add:boolean):Promise<void> =>
        {
            graph.api("/planner/tasks/"+taskid + "/details?$select=references").get().then((tsk:any)=>
            {
              let etag:string  = tsk['@odata.etag'];
              
              let existingdependencies = getDependenciesFromAttachments(tsk.references);
              let dependencyExists:boolean = existingdependencies.indexOf(dependentOnTaskId) >= 0;
              
              if (add && !dependencyExists) //add new dependency
              {
                let newtaskkey:string = "https%3A//tasks%2Eoffice%2Ecom/Home/Planner/%23/plantaskboard?groupId="+dependentOnGroupId+"&planId="+dependentOnPlanId+"&taskId=" + dependentOnTaskId;
                let newreferences:any = {};
                newreferences[newtaskkey] = {"@odata.type": "microsoft.graph.plannerExternalReference","alias": dependentOnTaskName,"previewPriority": " !","type": "Other"};
                let plantasks =  graph.api("/planner/tasks/"+taskid + "/details")
                .headers({'If-Match':tsk['@odata.etag']})
                .patch('{"references":' + JSON.stringify(newreferences) + '}')
                //.patch('{"references":{"https%3A//tasks%2Eoffice%2Ecom/Home/Planner/%23/plantaskboard?groupId=185999f4-b893-4a96-ba0b-fc328980f75d&planId=5Hc67RY21UC-t96t5THHbZgABmSi&taskId='+dependentOnTaskId+'":{"@odata.type": "microsoft.graph.plannerExternalReference","alias": "'+dependentOnTaskName+'","previewPriority": " !","type": "Other"}}}')
                .then(()=>{log("Task Attachment/Dependencies Saved");})
                .catch((ex:any)=>{log("Task Attachment/Dependencies Save Failed", ex);});
              } else if (!add && dependencyExists) //remove existing dependency
              {
                let newreferences:any = {...tsk.references};
                let taskkey = getDependencyKeyFromId(tsk.references, dependentOnTaskId);
                let newref:any = {};
                newref[taskkey] = null;
                let plantasks =  graph.api("/planner/tasks/"+taskid + "/details")
                .headers({'If-Match':tsk['@odata.etag']})
                .patch('{"references":' + JSON.stringify(newref) + '}')
                //.patch('{"references":{"https%3A//tasks%2Eoffice%2Ecom/Home/Planner/%23/plantaskboard?groupId=185999f4-b893-4a96-ba0b-fc328980f75d&planId=5Hc67RY21UC-t96t5THHbZgABmSi&taskId='+dependentOnTaskId+'":{"@odata.type": "microsoft.graph.plannerExternalReference","alias": "'+dependentOnTaskName+'","previewPriority": " !","type": "Other"}}}')
                .then(()=>{log("Task Attachment/Dependencies Deleted");})
                .catch((ex:any)=>{log("Task Attachment/Dependencies Delete Failed", ex);});

              } else //no change
              {
                  log("No change to dependencies, add?, dependencyExists?", add, dependencyExists);
              }
              
            });
            return;
        }
        let addAssignment = async (planid:string, taskid:string, userid:string):Promise<void> =>
        {
          return graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
          {
              let plantasks =  graph.api("/planner/tasks/"+taskid)
                  .headers({'If-Match':tsk['@odata.etag']})
                  .patch('{"assignments":{"' + userid + '":{"@odata.type": "microsoft.graph.plannerAssignment","orderHint": " !"}}}')
                  .then(()=>{log("Assignment Updated");})
                  .catch((ex:any)=>{log("Assignment Update Failed", ex);});
          });
          
        }
        let deleteAssignment = async (planid:string, taskid:string, assignmentid:string):Promise<void> =>
        {
          return graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
          {
              let plantasks =  graph.api("/planner/tasks/"+taskid)
                  .headers({'If-Match':tsk['@odata.etag']})
                  .patch('{"assignments":{"' + assignmentid + '":null}}')
                  .then(()=>{log("Assignment Details Deleted");})
                  .catch((ex:any)=>{log("Assignment Details Deleted Failed", ex);});
          });
        }
        let getTasksForPrint = async (printplans:GanttPlan[]):Promise<PrintGanttTasks[]> =>
        {
          if (printplans == null || printplans.length == 0)
          {
            return [];
          }
          log("Get Tasks for Print", printplans);
          let newprinttaskpages:PrintGanttTasks[] = [];
          let tasksinpage:number = 0;
          let pagenumber:number = 1;
          let newprinttasks:GanttTask[] = [];
          
          let minDate:Date | undefined = undefined;
          let maxDate:Date | undefined = undefined;

          for (let m=0;m<printplans.length;m++)
          {
            
            let printplan:GanttPlan = printplans[m];
            let groupid = printplan.groupid;
            let planid = printplan.planid;
            let planname = printplan.planName;
            let plandetails =  await graph.api("/planner/plans/"+planid+"/details").get();
            log(m + ".1.1. Plan details", plandetails);
            let plantasksunsorted =  await graph.api("/planner/plans/"+planid+"/tasks?$expand=bucketTaskBoardFormat,details($select=references)").get();
            
            if (plantasksunsorted.value.length > 0)
            {
                let planminDate = plantasksunsorted.value.reduce(function (a:any, b:any) { return a.startDateTime < b.startDateTime ? a : b; }).startDateTime;
                if (planminDate != undefined && planminDate!= null && (minDate == undefined || new Date(planminDate) < minDate))
                {
                  minDate = new Date(planminDate);
                }
                let planmaxDate = plantasksunsorted.value.reduce(function (a:any, b:any) { return a.dueDateTime > b.dueDateTime ? a : b; }).dueDateTime;
                if (planmaxDate != undefined && planmaxDate!= null && (maxDate == undefined || new Date(planmaxDate) > maxDate))
                {
                  maxDate = new Date(planmaxDate);
                }
                /*let batchrequest = {requests:[]} as any; */
                /*Find which order the tasks should be displayed in */
                for (let i=0; i<plantasksunsorted.value.length; i++)
                {  
                    
                    plantasksunsorted.value[i].bucktskboardformatOrderHint = plantasksunsorted.value[i].orderHint; //default order
                    plantasksunsorted.value[i].orderHint= plantasksunsorted.value[i].bucketTaskBoardFormat.orderHint; //order in buckets
                }
                
                /*let batch = await graph.api("/$batch").post(JSON.stringify(batchrequest));*/
                let plantasks = plantasksunsorted.value.sort((a:any, b:any)=>{return (ordinalCompareStr1BeforeStr2(a.orderHint,b.orderHint));});  
                let bucketsunsorted =  await graph.api("/planner/plans/"+planid+"/buckets").get();
                let buckets = bucketsunsorted.value.sort((a:any, b:any)=>{return (ordinalCompareStr1BeforeStr2(b.orderHint, a.orderHint));});
                
            
                let today:Date = new Date();
                let planstart = getFirstDate(plantasks);
                if (planstart)
                {
                    planstart = new Date(planstart);
                } else
                {
                    planstart = today;
                }
                let planend =  getLastDate(plantasks);
                if (planstart >= planend || planend == null || planend == undefined)
                {
                    planend = addDaysToDate(planstart, 10);
                }
                
                let plancolor:string = PlanColors[randomNumberFromText(planid, 20)-1];
                let plangantttask:GanttTask = 
                    {
                        groupid:groupid,
                        planid: planid,
                        expanded:true,
                        isplan:true,
                        bucketid: "",
                        orderHint: "",
                        start: new Date(planstart),
                        end: new Date(planend),
                        name: planname,
                        id:  planid,
                        progress: 100,
                        //percentComplete: 100,
                        type: "project",
                        styles: { progressColor: plancolor, progressSelectedColor: plancolor,backgroundColor:plancolor, backgroundSelectedColor:plancolor },//{ progressColor: '#7B68EE', progressSelectedColor: '#7B68EE',backgroundColor:'#7B68EE', backgroundSelectedColor:'#7B68EE' }, //4BC984, 318456
                        description: planname,
                        hideChildren: false,
                        displayOrder: (m+1)*10000,
                        assignments:[],
                        labels:[]
                    };
                if (props.plans.length > 1)
                {
                  newprinttasks.push(plangantttask);
                  tasksinpage++;
                }
                for (let b=0; b<buckets.length; b++)
                {
                    let bucket = buckets[b];
                    let bucketstart = plantasks.reduce(function (a:any, b:any) { return a.startDateTime < b.startDateTime ? a : b; }).startDateTime;
                    let bucketend =  plantasks.reduce(function (a:any, b:any) { return a.dueDateTime > b.dueDateTime ? a : b; }).dueDateTime;
                    let bucketgantttask:GanttTask = 
                    {
                        groupid:groupid,
                        planid: planid,
                        expanded:true,
                        isplan:false,
                        bucketid: "",
                        orderHint: "",
                        start: bucketstart?new Date(bucketstart):today,
                        end: bucketend?new Date(bucketend):today,
                        name: bucket.name,
                        id:  bucket.id,
                        progress: 100,
                        //percentComplete: 100,
                        type: "project",
                        styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },//{ progressColor: '#7B68EE', progressSelectedColor: '#7B68EE',backgroundColor:'#7B68EE', backgroundSelectedColor:'#7B68EE' }, //4BC984, 318456
                        description: bucket.name,
                        hideChildren: false,
                        displayOrder: (m+1)*10000+(b+1)*1000,
                        assignments:[],
                        labels:[]
                    };
                    if (plantasks.filter((tsk:any)=>{return tsk.bucketId == bucket.id;}).length >= 1)
                    {
                        let buckettasks = plantasks.filter((tsk:any)=>{return tsk.bucketId == bucket.id;});
                        bucketgantttask.start = new Date(buckettasks.reduce(function (a:any, b:any) //Find first start date
                            { 
                                if (b.startDateTime == null) return a;
                                if (a.startDateTime == null) return b;
                                if (new Date(a.startDateTime).getTime() < new Date(b.startDateTime).getTime()) 
                                    return  a; 
                                else 
                                    return b;
                            }).startDateTime); 
                        bucketgantttask.end = new Date(buckettasks.reduce(function (a:any, b:any) //Find last end date
                            { 
                                if (b.dueDateTime == null) return a;
                                if (a.dueDateTime == null) return b;
                                if (new Date(a.dueDateTime).getTime() > new Date(b.dueDateTime).getTime()) 
                                    return  a; 
                                else 
                                    return b;
                            }).dueDateTime);
                        
                        bucketgantttask.start = (bucketgantttask.start.getTime() <= new Date(1970, 1, 2).getTime())?today:bucketgantttask.start;
                        bucketgantttask.end = (bucketgantttask.end.getTime() <= new Date(1970, 1, 2).getTime())?today:bucketgantttask.end;
                        if (bucketgantttask.end.getTime()<=bucketgantttask.start.getTime())
                        {
                            bucketgantttask.end = addDaysToDate(bucketgantttask.start, 1);
                        }
                    
                        newprinttasks.push(bucketgantttask);
                        tasksinpage++;

                        for (let i=0; i<plantasks.length; i++)
                        {             
                          if (tasksinpage >= 19)
                          {
                            newprinttaskpages.push({pagenumber:pagenumber, tasks:newprinttasks});
                            newprinttasks = [];
                            tasksinpage = 0;
                            pagenumber++;
                          } 
                          let t = plantasks[i];
                          if (t.bucketId == bucket.id)
                          {
                              //setloadedplansprogress(Math.round(newplan.length/(plantasks.length + buckets.length-1) * 100));
                              
                              let startDate = t.startDateTime?new Date(t.startDateTime):bucketgantttask.start;
                              let endDate = t.dueDateTime?new Date(t.dueDateTime): addDaysToDate(startDate, 1);
                              if (endDate.getTime()<=startDate.getTime())
                              {
                                  endDate = addDaysToDate(startDate, 1);
                              }
                              
                              let dependencies:string[] = getDependenciesFromAttachments(t.details.references);
                              //let bucktskboardformat = await graph.api("/planner/tasks/" + t.id + "/bucketTaskBoardFormat").get();
                              let assns:Assignment[] = [];
                              for (var assn in t.assignments) 
                              {
                                if (Object.prototype.hasOwnProperty.call(t.assignments, assn)) 
                                {
                                  if (props.people == null || props.people == undefined)
                                  {
                                    let assignment:Assignment = {name:'NL', id:assn};
                                    assns.push(assignment);
                                  } else
                                  {
                                    if (props.people.some((p:Person)=>{return p.id == assn}))
                                    {
                                      let name = props.people.find((p:Person)=>{return p.id == assn})?.name;
                                      if (name != undefined && name != null)
                                      {
                                        let assignment:Assignment = {name:name, id:assn};
                                        assns.push(assignment);
                                      } else
                                      {
                                        let assignment:Assignment = {name:'N/A', id:assn};
                                        assns.push(assignment);
                                      }
                                    }
                                  }

                                }
                              }

                              let gantttask:GanttTask = 
                              {
                                  groupid: groupid,
                                  planid: planid,
                                  expanded:true,
                                  isplan:false,
                                  start: startDate,
                                  end: endDate,
                                  name: t.title,
                                  id:  t.id,
                                  progress: t.percentComplete,
                                  //percentComplete: t.percentComplete,
                                  type: "task",
                                  project:bucket.id,
                                  styles: 
                                      t.percentComplete == 100?
                                      (
                                          themeString === "default" ? 
                                          {progressColor: '#e1e1e1', progressSelectedColor: '#bababa',backgroundColor:'#e1e1e1', backgroundSelectedColor:'#bababa ', barLabel:'#000000'} 
                                          : 
                                          {progressColor: '#5c5c5c', progressSelectedColor: '#999999',backgroundColor:'#5c5c5c', backgroundSelectedColor:'#999999 ', barLabel:'#fff'} 
                                      )
                                      :
                                      { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },
                                  description: t.title,
                                  orderHint: t.orderHint,//bucktskboardformat.orderHint,
                                  displayOrder: (m+1)*10000+(b+1)*1000+i+1,
                                  bucketid: t.bucketId,
                                  dependencies: dependencies,
                                  assignments:assns,
                                  labels:getLabelsfortask(t.appliedCategories, plandetails.categoryDescriptions)

                              };
                              
                              
                              newprinttasks.push(gantttask);
                              tasksinpage++;
                          }
                        }
                    }
                }
            } else
            {
                log("no tasks found in this plan to print", planname);            
            }
          }
          newprinttaskpages.push({pagenumber:pagenumber, tasks:newprinttasks});
          for (let p=0;p<newprinttaskpages.length;p++)
          {
            minDate = minDate?minDate:new Date();
            maxDate = maxDate?maxDate:addDaysToDate(new Date(), 10);
            if (minDate >= maxDate)
            {
              maxDate = addDaysToDate(minDate, 10);
            }
            newprinttaskpages[p].tasks = [...newprinttaskpages[p].tasks, {groupid:"groupid",
              planid: "planid",
              expanded:true,
              isplan:false,
              bucketid: "",
              orderHint: "",
              start: minDate,
              end: maxDate,
              name: " ",
              id:  "Page" + newprinttaskpages[p].pagenumber,
              progress: 100,
              //percentComplete: 100,
              type: "project",
              styles: { progressColor: 'transparent', progressSelectedColor: 'transparent',backgroundColor:'transparent', backgroundSelectedColor:'transparent' },//{ progressColor: '#7B68EE', progressSelectedColor: '#7B68EE',backgroundColor:'#7B68EE', backgroundSelectedColor:'#7B68EE' }, //4BC984, 318456
              description: " ",
              hideChildren: false,
              displayOrder:9999999999,
              assignments:[], labels:[]}];
          }
          return newprinttaskpages;
          
        }
        
      
      log("7. Graph methods initiated", newplans);
      let viewstart = getMinDate(newplans);//plantasks.reduce(function (a:any, b:any) { return a.startDateTime < b.startDateTime ? a : b; }).startDateTime;
      let viewend =  getMaxDate(newplans);//plantasks.reduce(function (a:any, b:any) { return a.dueDateTime > b.dueDateTime ? a : b; }).dueDateTime;
      log("8. View Calculated",viewstart, viewend);
      props.planloaded(viewstart, viewend);
      setTasks(newplans);
      setPlandetails(newplandetails);
      log("9. Ready");
      props.getTasksForPrintReady(getTasksForPrint);
      return { plan:newplans, update:savePlannerTask, updateDetails:savePlannerTaskDetails, createNewTask:newPlannerTask, /*newTaskOrder: newTaskOrder,*/ rearrangetasks:rearrangetasks, savePlannerAttacmentDependencies:savePlannerAttacmentDependencies, addAssignment:addAssignment, deleteAssignment:deleteAssignment, getTasksForPrint:getTasksForPrint};
    },
    
    { scope: ["User.Read", "Mail.Read", "Tasks.ReadWrite", "offline_access"], teamsfx: teamsfx }
  );
  useEffect(()=>{
    let planids:string = "";
    props.plans.forEach((plan)=>{planids += plan.planid + ",";});
    if (loadedPlanIds != null && planids != loadedPlanIds)
    {
      log("Re-Loading Plan & Tasks", planids);
      reload();
    }
  }, [props.plans]);

  useEffect(()=>{if (!loading && error) {log("load error", error);}},[loading]);
  useEffect(()=>{log("Zoom", props.viewMode, props.viewModeZoomLevel);},[props.viewModeZoomLevel]);
  return (
    <div className="ganttcontainer"  >
      {loading && 
      <>
        <Flex vAlign="center" hAlign="center" style={{height:400}}>
            <div className="loadprogressarea" style={{width:200}}>
            <div className="loadprogressbar" style={{width:(loadedplansprogress) + '%'}}></div>
            </div>
        </Flex>
      </>}
      <ErrorSection loading={loading} error={error} reload={reload} />
      {!loading && data && 
      (
        (tasks.length > 0?
          <>
            <PlanGanttOperator 
              teamsfx={teamsfx} 
              plans={plandetails}
              tasks={tasks}
              viewMode={props.viewMode}
              viewModeZoomLevel={props.viewModeZoomLevel}
              showCompletedTasks={props.showCompletedTasks}
              planloaded={props.planloaded}
              savePlannerTask={data?.update} 
              savePlannerTaskDetails={data?.updateDetails} 
              newPlannerTask={data?.createNewTask} 
              //newTaskOrder={data?.newTaskOrder} 
              rearrangetasks={data?.rearrangetasks}
              savePlannerAttacmentDependencies={data?.savePlannerAttacmentDependencies}
              askforpermissions={props.askforpermissions}
              addAssignment={data?.addAssignment}
              deleteAssignment={data?.deleteAssignment} 
              people={props.people} 
              peopleLoaded={props.peopleLoaded} />

            {/*<Gantt viewMode={props.viewMode}
              tasks={tasks}
              onDateChange={handleTaskChange}
              TooltipContent={(tsk)=>{
                return <GanttTooltipContent tsk={tsk.task} />}}
              todayColor='transparent'
              barCornerRadius={2}
              //listCellWidth={true ? 155+"px" : ""}
              columnWidth={props.viewModeZoomLevel}
              onExpanderClick={handleExpanderClick}
              onDoubleClick={ganttbardoubleclick}
              onSelect={(tsk,a)=>{log('Task Selected',tsk,a);}}
              TaskListHeader={({headerHeight,
                rowWidth,
                fontFamily,
                fontSize})=>{return <GanttTableHeader headerHeight={headerHeight} rowWidth={rowWidth} fontFamily={fontFamily} fontSize={fontSize} />}}
              onDetailsMenuClick={detailsmenuaction}
              onMarkCompletedClick={()=>{alert('123)s')}}
              hidechart={props.hidechart}
            
            />
            <GanttTaskDialog tasks={tasks} open={detailsMenuVisible} task={detailsMenuTask} planName={loadedPlanName} action={menuaction} updateTask={updateTask} insertTask={insertTask} cancelUpdate={cancelUpdate}  />
              */}
          </>
          :
          <>
              {props.plans.map((p:GanttPlan)=>
                  {
                      return <Flex style={{height:300}} hAlign="center" vAlign="center"><h3>No tasks found for {p.planName}. Please go <a target="_blank" href={"https://tasks.office.com/Home/Planner/#/plantaskboard?planId=" + p.planid} >http://tasks.office.com</a> and create at least one task to start planning</h3></Flex>
                  })
              }
          </>
        )
      )}
      
    </div>
  );
}
