// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will initialize TeamsFx SDK and show `GetUserProfile` component after initialization.

import { Button, Checkbox, Dropdown, DropdownProps, Flex, Popup, Slider, Tooltip } from "@fluentui/react-northstar";
import { useGraph, useTeamsFx, useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { start } from "repl";
import { Gantt, ViewMode } from "../../gantt";
import { arghlog, getFromLocalCache, log, saveToLocalCache, shhlog } from "../../helper";
import { PlannerGroupsSelector } from "../teams/PlannerGroupsSelector";
import { AuthorizeUser } from "../../authentication/AuthorizeUser";
import { List, Image } from '@fluentui/react-northstar'
import { PlanGanttMobile } from "./PlanGanttMobile";
import { GanttPlan } from "../../../ganttmods/publictypes";




export function PlannerPPMMobile(props: { licensehandler:Function }) {
  // For usage of useTeamsFx(), please refer to: https://www.npmjs.com/package/@microsoft/teamsfx-react#useTeamsfx.
  // You need to wait until `loading == false` to use TeamsFx SDK.
  const { loading, error, teamsfx } = useTeamsFx({
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
  });
  const [planid,setPlanid] = useState<string | null>(null);
  const [planname,setPlanname] = useState<string | null>(null);
  const [viewMode,setViewMode] = useState<ViewMode>(ViewMode.Year);
  const [viewModeZoomLevel,setViewModeZoomLevel] = useState<number>(80);
  const [sliderValue,setSliderValue] = useState<number>(20);
  const [showCompletedTasks,setShowCompletedTasks] = useState<boolean>(true);
  const [authNeeded,setAuthNeeded] = useState<boolean>(false);
  
  
  /*useEffect(()=>{
    log('teamsfx init and login', teamsfx, loading, error);
    teamsfx?.login(["User.Read", "Mail.Read", "Tasks.ReadWrite", "offline_access"]).then((result)=>{
      log('login complete', result);
      setlogincompleted(true);
    }).catch((err)=>{
      setlogincompleted(false);
      setAuthNeeded(true);
    });
  }, []);*/
  
  useEffect(()=>{
    log('Plan Selected', planid, planname);
  }, [planid, planname]);
  const planloaded = (start:Date, end:Date) =>
  {
    try
    { 

      let planszoom = getFromLocalCache('planszoomlevel');
      log("planszoom", planszoom);
      if (planid != null && planszoom != undefined && planszoom != null)
      {
        let planzoom = planszoom[planid];
        log("planzoom", planzoom);
        if (planzoom != undefined && planzoom != null)
        {
          setViewModeZoomLevel(planzoom.zoom);
          setViewMode(planzoom.viewmode);
          setSliderValue(parseInt(planzoom.sliderValue));
          shhlog("Cached view", planzoom, (planzoom.sliderValue), parseInt(planzoom.sliderValue));
          return;
        }
      } 
    } catch (e) {arghlog("getFromLocalCache('planszoomlevel') error", e);}

    const diffTime = Math.abs(new Date(end).getTime() - new Date(start).getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    shhlog("planloaded view", diffDays, start, end);
    switch (true)
    {
      case (diffDays < 10) :
        setViewModeZoomLevel(400*1/25+50);
        setViewMode(ViewMode.Day);
        setSliderValue(76);
        break;
      case (diffDays < 30) :
        setViewModeZoomLevel(400*1/25+50);
        setViewMode(ViewMode.Week);
        setSliderValue(51);
        break;
      case (diffDays < 300) :
        setViewModeZoomLevel(400*5/25+50);
        setViewMode(ViewMode.Month);
        setSliderValue(46);
        break;
      default:
        setViewModeZoomLevel(400*20/25+50);
        setViewMode(ViewMode.Year);
        setSliderValue(20);
        break;
    }
    
    
  }
  useEffect(()=>{
    let planszoom = getFromLocalCache('planszoomlevel');
    if (planszoom == undefined || planszoom == null )
    {planszoom = {};}
    if (planid != null)
    {shhlog('viewModeZoomLevel changed', planid, viewMode, viewModeZoomLevel, sliderValue)
      planszoom[planid] = {zoom:viewModeZoomLevel, viewmode:viewMode, sliderValue:sliderValue};
      saveToLocalCache('planszoomlevel', planszoom);
    }
  }, [viewMode, viewModeZoomLevel]);
  const slidervaluechanged = (e:any, a:any)=>
  {
    
    shhlog('zoomlevelsected', e, a?.value);
    setSliderValue(a?.value);
    if (a != undefined && a != null && a?.value != undefined)
    {
      let slidervalue = parseInt(a?.value);
      switch (true)
      {
        case ((slidervalue >= 0) && (slidervalue <= 40)):
          setViewModeZoomLevel(400*slidervalue/25+50);
          setViewMode(ViewMode.Year);
          break;
        case ((slidervalue > 40) && (slidervalue <= 50)):
          setViewModeZoomLevel(400*(slidervalue-40)/25+50);
          setViewMode(ViewMode.Month);
          break;
        case ((slidervalue > 50) && (slidervalue <= 75)):
          setViewModeZoomLevel(400*(slidervalue-50)/25+50);
          setViewMode(ViewMode.Week);
          break;
        case ((slidervalue > 75) && (slidervalue <= 100)):
          setViewModeZoomLevel(400*(slidervalue-75)/25+50);
          setViewMode(ViewMode.Day);
          break;
        default:
          setViewMode(ViewMode.Month);
          break;
      }
    }
  }
  return (
    <div style={{width:'96vw', marginLeft:'2vw', marginRight:'2vw'}} >
      {!loading && error && (
        <div className="error">Failed init TeamsFx. Please try again later.</div>
      )}
      {!authNeeded  &&
        <>
          <Flex space="between" >
          {!loading && teamsfx && 
            <PlannerGroupsSelector 
              teamsfx={teamsfx} 
              
              multipleplansselected={(plans:GanttPlan[]|null, closing:boolean)=>{
                setPlanid(null);
                  if (plans != null && plans != undefined && plans.length > 0)
                  {
                    setPlanid(plans[0].planid);
                    setPlanname(plans[0].planName);
                    
                  } 
              }}
              authorizeUser={()=>{setAuthNeeded(true);}}
              mobile={true}
              nolicense={props.licensehandler}
              people={[]}
            />}
           
          </Flex>
          {planid != null && 
            <>    
              
              <PlanGanttMobile hidechart={true} teamsfx={teamsfx} planid={planid} planName={planname} viewMode={ViewMode.Year} viewModeZoomLevel={20} planloaded={planloaded} showCompletedTasks={showCompletedTasks} />
              {/*<div><List style={{height:'85vh', overflow:'scroll'}} items={items} /></div>*/}
            </>
          }
        </>
      }
      {authNeeded &&
        
        <AuthorizeUser teamsfx={teamsfx} loggedinComplete={()=>{setAuthNeeded(false);}} reasonmessage={""} />
        
      }
    </div>
  );
}
