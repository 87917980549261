
import overviewimg from './images/overview.png';
import planimg from './images/plan.png';
import plannertasksimg from './images/plannertasks.png';

export default function AuthPeoplePermissions(props:{}) {

    return (
    <div className="getstartedarea">
      <p>Before starting using Planner Gantt you should have access to at least one Microsoft Planner plan and one or more tasks in that plan.</p>
      <p><i>Note: If you do not have a plan. Start by creating one here on <a href="http://tasks.office.com/">http://tasks.office.com/</a>.</i></p>
      <p>Sign up to use Planner Gantt by opening the app in <u><a href="https://teams.microsoft.com/l/app/63cd9420-78db-4656-a2c9-d06215a625fd?source=app-details-dialog">Teams Apps</a></u> and choose a subscription.</p>
      <p>Next open Planner Gantt app in Microsoft Teams.</p>
      <p>On the first screen you are presented with all available Microsoft Planner plans you have access to.
      </p>
     
      <div style={{display:'block', textAlign:'center'}}> 
         <img src={overviewimg} style={{width:'80%'}} />
         <br/>
         <i>Tip: When you open a plan it will afterwards appear in the Recent tab.</i>
      </div>
      <p>Chose a plan to open it.</p>
      <div style={{display:'block', textAlign:'center'}}> 
         <img src={planimg} style={{width:'80%'}} />
      </div>
      <p>Drag the tasks to give them a new start and end date.</p>
      <p>When the plan is planned and finalized close it by clicking on the X in the top left next to the plan name.</p>
      <p>To see the changes in planner, find the plan in http://tasks.office.com/ and see the start and due date have been updated together with all other properties in the plan.</p>
      <div style={{display:'block', textAlign:'center'}}> 
         <img src={plannertasksimg} style={{width:'80%'}} />
      </div>
   </div>);
}