// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Datepicker, Dialog, Dropdown, DropdownProps, Flex, Grid, Loader, Pill, Popup, Segment, TextArea, Text } from "@fluentui/react-northstar";
import { registerUserSettingsChangeHandler } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { log } from "../helper";



export function ErrorSection(props: { loading:boolean, error:any, reload:() => void }) 
{
    const [detailsMenuVisible, setDetailsMenuVisible] = useState<boolean>(false);
    const [taskName, setTaskName] = useState<string>("");
    const [taskStart, setTaskStart] = useState<Date>();
    const [taskDue, setTaskDue] = useState<Date>();

  useEffect(()=>{
    
  }, []);
  const getErrorDesc = (err:any) =>
  {
    try
    {
        log('Error', err);
        if (err.toString().indexof('') >= 0)
        {
            return 'Too many requests, please wait 1 min and refresh the app';
        }
        return 'Failed to read your profile. Please Authorize by clicking the button below.';
    } catch (ex)
    {
        log('Unknown error');
        return 'Unknown error';
    }
  }
  return (
    <>
        {!props.loading && props.error && (
            <Flex  column gap="gap.medium">
                <div className="error">
                    {getErrorDesc(props.error)}
                </div>
                <Flex gap="gap.medium">
                <Button primary content="Authorize" disabled={props.loading} onClick={props.reload} />
                <Dialog
                    confirmButton="Close"
                    content={<div>{"Error Message: " + props.error}<br /><br /><Text size="smaller" content={props.error?(props.error as any).stack.toString():<>no stack</>}/></div>}
                    /*
                    Too many requests
                    */
                    header="Error Details"
                    trigger={<Button content="Details" onClick={()=>{log("Error Details", props.error?(props.error as any).stack.toString():'no stack');}} />}
                /></Flex>
            </Flex>
        )}
    </>
  );
}
