import { Button, Slider, Tooltip } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { ViewMode } from "../../gantt";
import { addDaysToDate, arghlog, getFromLocalCache, log, saveToLocalCache, shhlog } from "../../helper";

const buttonStyle = {fontSize:'small', margin:'5px'};
export function ZoomControl(props: { periodvalue:ViewMode, onChangeZoom:Function, onChangePeriod:Function, viewstart:Date|undefined, viewend:Date|undefined, planid:string }) 
{
    const [viewSelected,setViewSelected] = useState<ViewMode>(ViewMode.Month);
    const [zoomLevel,setZoomLevel] = useState<number>(20);
    const [zoomMin,setZoomMin] = useState<number>(80);
    const [zoomMax,setZoomMax] = useState<number>(400);
    const [currentplanid,setcurrentplanid] = useState<string|null>(null);
    useEffect(()=>{
        setViewSelected(props.periodvalue);
    }, []);
    useEffect(()=>
    {
        if (props.viewstart != undefined && props.viewend != undefined && props.viewstart != undefined && props.viewend != undefined && new Date(props.viewstart) < new Date(props.viewend))
        {
            if (props.planid != null && props.planid != undefined && props.planid != currentplanid)
            {
                setcurrentplanid(props.planid);
                setZoomFromCache(props.viewstart, props.viewend);
            }
            if (props.planid == null && props.planid == undefined)
            {
                setcurrentplanid(null);
            }
        }
    }, [props.viewend, props.viewstart]);
    const setZoomFromCache = (start:Date, end:Date) =>
    {
        try
        { 
            
            let planszoom = getFromLocalCache('planszoomlevels');
            shhlog("Plans Zoom From Cache", planszoom);
            if (props.planid != null)
            {
                let planzoom = planszoom[props.planid];
                shhlog("planzoom", planzoom);
                if (planzoom != undefined && planzoom != null)
                {
                    onPeriodChange(planzoom.periodvalue);
                    setZoomLevel(planzoom.zoomlevel);
                    props.onChangeZoom(planzoom.zoomlevel);   
                    saveZoomCache(viewSelected, planzoom.zoomlevel); 
                    
                    return;
                }
            } 
        } catch (e) {arghlog("Getting Plan Zoom From Cahce Error", e);}

        const diffTime = Math.abs(new Date(end).getTime() - new Date(start).getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        shhlog("Calculating Best Zoom Value (days, start, end)", diffDays, start, end);
        switch (true)
        {
        case (diffDays < 15) :
            onPeriodChange(ViewMode.Day);
            break;
        case (diffDays < 30) :
            onPeriodChange(ViewMode.Week);
            break;
        case (diffDays < 300) :
            onPeriodChange(ViewMode.Month);
            break;
        default:
            onPeriodChange(ViewMode.Year);
            break;
        }
        
        
    }
    const saveZoomCache = (newperiodvalue:ViewMode, newzoomvalue:number) =>
    {
        log("Saving Zoom Cache", newperiodvalue, newzoomvalue);
        let planszoom = getFromLocalCache('planszoomlevels');
        if (planszoom == undefined || planszoom == null )
        {planszoom = {};}
        if (currentplanid != null)
        {
          planszoom[currentplanid] = {zoomlevel:newzoomvalue, periodvalue:newperiodvalue};
          saveToLocalCache('planszoomlevels', planszoom);
        }
    }
    const onPeriodChange = (view:ViewMode) => 
    {
        setViewSelected(view);   
        props.onChangePeriod(view);
        let zoom = 80;
        switch (view)
        {
            case ViewMode.Year:
                zoom = 200;
                setZoomMin(200);
                setZoomMax(80*12);
                break;
            case ViewMode.Month:
                setZoomMin(80);
                setZoomMax(300);
                zoom = 120;
                break;
            case ViewMode.Week:
                setZoomMin(40);
                setZoomMax(300);
                zoom = 60;
                break;
            case ViewMode.Day:
                setZoomMin(60);
                setZoomMax(300);
                zoom = 80;
                break;
            default:
                zoom = 80;
                break;
        }
        props.onChangeZoom(zoom);
        setZoomLevel(zoom);
        saveZoomCache(view, zoom); 
        
    }
    const onZoomChange = (e:any, a:any) => {
        
        if (a != undefined && a != null && a?.value != undefined)
        {
            let slidervalue = parseInt(a?.value);
            setZoomLevel(slidervalue);
            props.onChangeZoom(slidervalue);   
            saveZoomCache(viewSelected, slidervalue); 
        }
        
    }
    return (
        <Tooltip content={"Select view and slide to zoom in/out"} pointing trigger=
        {
        <div>
            <Button primary={viewSelected == ViewMode.Year}   size="small" style={buttonStyle} text={viewSelected != ViewMode.Year}  onClick={()=>{onPeriodChange(ViewMode.Year)}}>Year</Button>
            <Button primary={viewSelected == ViewMode.Month}  size="small" style={buttonStyle} text={viewSelected != ViewMode.Month} onClick={()=>{onPeriodChange(ViewMode.Month)}}>Month</Button>
            <Button primary={viewSelected == ViewMode.Week}   size="small" style={buttonStyle} text={viewSelected != ViewMode.Week}  onClick={()=>{onPeriodChange(ViewMode.Week)}}>Week</Button>
            <Button primary={viewSelected == ViewMode.Day}    size="small" style={buttonStyle} text={viewSelected != ViewMode.Day}   onClick={()=>{onPeriodChange(ViewMode.Day)}}>Day</Button>
            <Slider style={{marginTop:6}} value={zoomLevel} onChange={onZoomChange} min={zoomMin} max={zoomMax}/>
        </div>
        } />
    );
}