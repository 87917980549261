import { Tooltip } from "@fluentui/react-northstar";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BarTask } from "../../types/bar-task";
import { GanttContentMoveAction } from "../../types/gantt-task-actions";
import { Bar } from "./bar/bar";
import { BarSmall } from "./bar/bar-small";
import { Milestone } from "./milestone/milestone";
import { Project } from "./project/project";
import style from "./task-list.module.css";
import { TeamsFxContext } from "../../../Context";
import { Label } from "../../../../ganttmods/publictypes";

export type TaskItemProps = {
  task: BarTask;
  arrowIndent: number;
  taskHeight: number;
  isProgressChangeable: boolean;
  isDateChangeable: boolean;
  isDelete: boolean;
  isSelected: boolean;
  rtl: boolean;
  onEventStart: (
    action: GanttContentMoveAction,
    selectedTask: BarTask,
    event?: React.MouseEvent | React.KeyboardEvent
  ) => any;
  zoomLevel:number;
  onLinkStartDrag: (
    predecessortask: BarTask,
  ) => any;
  highlightOnMouseHover: boolean;
  onLinkStopDrag: (
    predecessortask: BarTask,
  ) => any;
  labels:Label[];
  labelsclicked:()=>void;
  showLabelTitles:boolean;
};

export const TaskItem: React.FC<TaskItemProps> = props => {
  const {
    task,
    arrowIndent,
    isDelete,
    taskHeight,
    isSelected,
    rtl,
    onEventStart,
    zoomLevel,
    onLinkStartDrag,
    highlightOnMouseHover,
    onLinkStopDrag,
    labels,
    labelsclicked,
    showLabelTitles,
  } = {
    ...props,
  };
  const textRef = useRef<SVGTextElement>(null);
  const [taskItem, setTaskItem] = useState<JSX.Element>(<div />);
  const [isTextInside, setIsTextInside] = useState(true);
  const [txtOpacityAni, setTxtOpacityAni] = useState(1);
  const [txtOpacityAniTimeout, setTxtOpacityAniTimeout] = useState<NodeJS.Timeout>();
  const { themeString } = useContext(TeamsFxContext);
  
  useEffect(() => {
    switch (task.typeInternal) {
      case "milestone":
        setTaskItem(<Milestone {...props} />);
        break;
      case "project":
        setTaskItem(<Project {...props} />);
        break;
      case "smalltask":
        setTaskItem(<BarSmall {...props} highlightOnMouseHover={highlightOnMouseHover} onLinkStartDrag={onLinkStartDrag} onLinkStopDrag={onLinkStopDrag} />);
        break;
      default:
        setTaskItem(<Bar {...props} highlightOnMouseHover={highlightOnMouseHover} onLinkStartDrag={onLinkStartDrag}  onLinkStopDrag={onLinkStopDrag} />);
        break;
    }
  }, [task, isSelected, highlightOnMouseHover, showLabelTitles]);

  useEffect(() => {
    if (textRef.current) {
      setIsTextInside(textRef.current.getBBox().width < task.x2 - task.x1 );
    }
  }, [textRef, task]);
  useEffect(()=>{
    setTxtOpacityAni(0);
    clearTimeout(txtOpacityAniTimeout);
    let timeouthand = setTimeout(() => {
      setTxtOpacityAni(1);
    }, 1000);
    setTxtOpacityAniTimeout(timeouthand);
  }, [zoomLevel]);
  const getX = () => {
    let width = task.x2 - task.x1;
    width = width <16?16:width; //added for min bucket width of 16
    const hasChild = task.barChildren.length > 0;
    if (isTextInside) {
      return task.x1 + width * 0.5;
    }
    if (rtl && textRef.current) {
      return (
        task.x1 -
        textRef.current.getBBox().width -
        arrowIndent * +hasChild -
        arrowIndent * 0.2
      );
    } else {
      return task.x1 + width + arrowIndent * +hasChild + arrowIndent * 0.2;
    }
  };

  return (
    <g
      onKeyDown={e => {
        switch (e.key) {
          case "Delete": {
            if (isDelete) onEventStart("delete", task, e);
            break;
          }
        }
        e.stopPropagation();
      }}
      onMouseEnter={e => {
        onEventStart("mouseenter", task, e);
      }}
      onMouseLeave={e => {
        onEventStart("mouseleave", task, e);
      }}
      onDoubleClick={e => {
        onEventStart("dblclick", task, e);
      }}
      onClick={e => {
        onEventStart("click", task, e);
      }}
      onFocus={() => {
        onEventStart("select", task);
      }}
    >
      {taskItem}
      <text
        x={getX()+(isTextInside?0:28)}
        style={{opacity: txtOpacityAni}}
        y={task.y + taskHeight * 0.5+(!isTextInside?5:0)}
        className={
          isTextInside
            ? style.barLabel  
            : style.barLabel && (themeString === "default" ? style.barLabelOutside : style.barLabelOutsideDark)
        }
        fontSize={(task.typeInternal=='project'?'0.7rem':'0.875rem')}
        fill={
          isTextInside
          ? task.styles.barLabel
          :''
      }
        ref={textRef}
      >
        
        
        {task.name}
      </text>
    </g>
  );
};
