import { useEffect, useState } from "react";
import { getFromLocalCache, saveToLocalCache } from "../../../helper";
import { PlanCard } from "../PlanCard";
import { GanttPlan } from "../../../../ganttmods/publictypes";


export function RecentPlans(props: {allplans:GanttPlan[], planselected:(plan:GanttPlan, isnew:boolean)=>void, cacheedrecentplans:any[]}) 
{
    
    useEffect(()=>
    {
    }, []);
    return (<>
        {props.allplans.filter((fplan:GanttPlan)=>
                {
                    if (props.cacheedrecentplans.some(cplan=>cplan.id == fplan.planid))
                    {return true;}
                    return false;
                }).map((plan:GanttPlan)=>
                {
                  return <PlanCard key={plan.planid} title={plan.planName}  subtitle={plan.ownerName} plan={plan} closeBtn={false}
                    onSelect={(selectedplan:GanttPlan)=>
                    {
                        props.planselected(selectedplan, false);
                        //setcontrolmaxheight('20vh');
                        /*let planfound = props.allplans.find((p:any) => p.id == planid);
                        props.planselected(planfound.title, planfound.id, false);
                        let recentplans = getFromLocalCache("recentplans");
                        if (recentplans == null)
                        {recentplans = [];}
                        recentplans.push({title:planfound.title, id:planfound.id, opened:new Date()});
                        cacheedrecentplans.push({title:planfound.title, id:planfound.id, opened:new Date()});
                        saveToLocalCache("recentplans", recentplans);
                        setPlanchosen(true);
                        setPlanchosenid(planfound.id);
                        setPlanchosentitle(planfound.title);
                        setPlanchosenowner(plan.ownername);*/
                      }
                    } ></PlanCard>
                })
              }        
    </>);
}