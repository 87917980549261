import React, { useEffect } from "react";
import style from "./bar.module.css";
import { Label, iLabel } from "../../../../../ganttmods/publictypes";
import { countCapitalLetters } from "../../../../helper";

type BarLabelsProps = {
  x: number;
  y: number;
  barCornerRadius: number;
  labels:Label[];
  labelsclicked:()=>void;
  showLabelTitles:boolean;
};
export const BarLabels: React.FC<BarLabelsProps> = ({
  x,
  y,
  barCornerRadius,
  labels,
  labelsclicked,
  showLabelTitles,
}) => {
  
  const [labelsgrouped, setLabelsgrouped] = React.useState<iLabel[][]>([]);
 

  
  useEffect(() => {
    if (labels.length == 0)
    {
      setLabelsgrouped([]);
      return;
    }
    
    let row = 1;
    let col = 1;
    
    let newlabelsgrouped:iLabel[][] = [];
    let labelsgroup:iLabel[] = [];
    for (let i = 0; i < labels.length; i++) 
    {
      if (row > 2)
      {
        row = 1;
        newlabelsgrouped.push(labelsgroup);
        labelsgroup = [];
        col++;
      }
      let uppercapitalletters = countCapitalLetters(labels[i].header);
      let lowercapitalletters = labels[i].header.length - uppercapitalletters;
      labelsgroup.push(
        {
          ...labels[i], 
          xOffsetLocation:- 24 - (col-1)*24, 
          width:20 + lowercapitalletters*5+ uppercapitalletters*5.2,
          xOffsetLocationExpanded: 0,
        });
      row++;
    }
    newlabelsgrouped.push(labelsgroup);
    let curxOffsetLocationExpanded = 0;
    let lastxOffsetLocationExpanded = 0;
    for (let i = 0; i < newlabelsgrouped.length; i++) 
    {
      curxOffsetLocationExpanded = lastxOffsetLocationExpanded-newlabelsgrouped[i][0].width-4;
      if (newlabelsgrouped[i].length >= 2)
      {
        if (newlabelsgrouped[i][0].width < newlabelsgrouped[i][1].width)
        {
          curxOffsetLocationExpanded = lastxOffsetLocationExpanded-newlabelsgrouped[i][1].width-4;
        }
        newlabelsgrouped[i][1].xOffsetLocationExpanded = curxOffsetLocationExpanded;
      }
      newlabelsgrouped[i][0].xOffsetLocationExpanded = curxOffsetLocationExpanded;
      lastxOffsetLocationExpanded = curxOffsetLocationExpanded;
    }
    
    setLabelsgrouped(newlabelsgrouped);
  }, [labels]);

  return (
    <>
      <g onClick={()=>{labelsclicked();}} >
        {labelsgrouped.map((labelgroup:iLabel[], labelsgroupedindex:number) => 
          {
            return <>
            {labelgroup.map((label:iLabel, labelgroupindex:number) => {
              return <g >
                <rect style={{cursor:'pointer', filter:'drop-shadow(3px 3px 3px rgba(0, 0, 0, .2))',strokeWidth:(showLabelTitles?0:1),stroke:label.categoryColor, transition: 'none'}}
                  x={x + (showLabelTitles?label.xOffsetLocationExpanded:label.xOffsetLocation)}
                  width={showLabelTitles?label.width:20}
                  y={y+labelgroupindex*16}
                  height={showLabelTitles?14:10}
                  ry={barCornerRadius}
                  rx={barCornerRadius}
                  fill={label.categoryBackgroundColor}
                
                />
                {showLabelTitles && <text style={{userSelect:'none'}}
                  x={x + (showLabelTitles?label.xOffsetLocationExpanded:label.xOffsetLocation) + 6}
                  y={y+labelgroupindex*16+11}
                  fill={label.categoryColor}
                  fontSize="0.7rem" >{label.header}</text>
                }
              </g>
            })}
          </>
          })
        }
      </g>  
    </>
  );
};
