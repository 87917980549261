import React, { useEffect, useState } from "react";
import { Loader, Provider, teamsTheme, TeamsIcon } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";


import "../website/Home.css";
import { getQueryParams, log, randomText } from "../helper";

/**
 * The main app which handles subscription thowards MS
 * of the app.
 */

export default function LandingPage() {
  const [loadingtext, setLoadingtext] = useState('Activating subscription..');
  const [activationresult, setActivationResult] = useState<any>({});
  const [hastoken, setHastoken] = useState(true);
  const [activationcomplete, setActivationcomplete] = useState(false);

  useEffect(()=>{
    document.body.style.backgroundColor = "white";   
    let token = getQueryParams("token");
    log("Landing page token", token);

    if (token == null || token == undefined || token == "" || token.length < 60) 
    {
      setHastoken(false);
    } else {
      setHastoken(true);
      log("initiate activation");
      setLoadingtext("Activating subscription...");
      setTimeout(() => {
        setLoadingtext("Subscribing...");
        log("start activation");
        activateSubscription();
       
       
        //setActivationcomplete(true);
      }, 3000);
    }
  }, []);
  const activateSubscription = () => {
    log("COMPLETING PURCHASE", getQueryParams("token"));
    log("Getting token for fullfillment subscription");
    let encodedbody = encodeURIComponent("grant_type=client_credentials&client_id=b74f8709-41af-4896-92fb-716333c25d97&client_secret=AN~8Q~NTt-RaXza2j3MgobWS37gmjUI16zHxnaSg&resource=20e940b3-4c77-4b0b-9a53-9e16a1b010a7");
    log("encodedbody", encodedbody);
    const fullfillmentrequestOptions = {
        method: 'GET',
        
    };
    fetch('https://plannerganttapi.azurewebsites.net/ActivateSubscription?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&token=' + encodeURIComponent(getQueryParams("token") as string), fullfillmentrequestOptions)
        .then(response => 
          {
            log("start activation");
            response.json().then((v:any)=>{

              log("Activation result", v.state, v.message);
              setActivationResult(v);
              setLoadingtext("Activated!");
              setActivationcomplete(true);
            });
            
          });

  }
  return (
    <Provider theme={teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
      <div className="webpage">
        
         
          <div className="subscriptionwebheadingcon">
            {(hastoken )? 
              <>
                <div className="subscriptionwebheading">THANK YOUR</div>
                  <hr className="webhrline" />
                  <div className="suibscriptiontext">Thank you for choosing Planner Gantt to plan your projects. Please wait a minute while we activate the subscription.</div>
                  {/*<button onClick={activateSubscription}>Activate</button>*/}
                  {!activationcomplete?
                    <Loader label={loadingtext} />
                    :
                    <>
                      {activationresult != null && activationresult != undefined && activationresult.state != null && activationresult.state != undefined && activationresult.state != "Success" ?
                        <div className="suibscriptiontext">{"Activation failed, error message was: " + activationresult.message}</div>
                        :
                        <>
                          <div className="suibscriptiontext">{"Subscription activated"}</div>
                          <div className="suibscriptiontext subwebheading4">Please open <a href="https://teams.microsoft.com/l/app/63cd9420-78db-4656-a2c9-d06215a625fd?source=app-details-dialog"><u> Microsoft Teams</u> <TeamsIcon size="larger" /></a> to assign licenses and start using the app.</div>  
                        </>
                      }
                    </>
                  }
              </> 
            : <div className="webheading1">Activation Complete</div>
            }
          </div>
        
        
          <div className="webfooter"></div>
      </div>
    </Provider>
  );
}


  