import { AddIcon, Button, ContentIcon, ArrowDownIcon, FilesTxtIcon, FilesUploadIcon, ArrowUpIcon, Flex, IndentIcon, NotesIcon, Popup } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { Task } from "../../types/public-types";

import styles from "./task-list-table.module.css";
export default function GanttTaskSubMenu(props:{tsk:Task, onDetailsMenuClick: (task: Task, action:number) => void;}) 
{
  const [submenuVisible, setSubmenuVisible] = useState<Boolean>(false);
  const [popupopen, setPopupopen] = useState<boolean>(false);
  return (
    <div  className={styles.submenudots}>
    
      <Popup pointing position="after" align="center" onOpenChange={()=>{setPopupopen(false)}} open={popupopen} content=
      {
        <Flex gap="gap.smaller" column hAlign="start" >
          <Button icon={<NotesIcon />} text content="Open details" onClick={()=>{props.onDetailsMenuClick(props.tsk, 1);setPopupopen(false);}} />            
          <Button icon={<IndentIcon />} text content="New task above" onClick={()=>{props.onDetailsMenuClick(props.tsk, 2);setPopupopen(false);}} />
          <Button icon={<IndentIcon />} text content="New task below" onClick={()=>{props.onDetailsMenuClick(props.tsk, 3);setPopupopen(false);}} />
          <Button icon={<ArrowUpIcon />} text content="Move task up" onClick={()=>{props.onDetailsMenuClick(props.tsk, 4);setPopupopen(false);}} />
          <Button icon={<ArrowDownIcon />} text content="Move task down" onClick={()=>{props.onDetailsMenuClick(props.tsk, 5);setPopupopen(false);}} />
          
        </Flex>
      }  trigger={
        <svg onClick={()=>{setPopupopen(true)}} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
            <path d="M6.25 10a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"></path>
            <path d="M11.25 10a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"></path>
            <path d="M15 11.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
        </svg>} />
        {submenuVisible && <div style={{position:"absolute", left:200}}>Submenu</div>}
    </div>
  );
}
