import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import LandingPage from "./components/subscription/LandingPage";

if (document.location.href.indexOf("token") < 0)
{
    ReactDOM.render(<App />, document.getElementById("root"));
} else 
{
    //Token is present, start MICROSOFT FLOW
    ReactDOM.render(<LandingPage />, document.getElementById("root"));
}

  
  