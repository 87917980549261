// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Checkbox, ComponentEventHandler, Dialog, Dropdown, DropdownProps, Flex, Grid, Input, Loader, Menu, MenuProps, ParticipantRemoveIcon, TeamCreateIcon, Text, TextArea, Tooltip } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";

import { getFromLocalCache, isemail, log, saveToLocalCache, shhlog } from "../helper";
import "./Subscriptions.css"
import { LicensedUser } from "./LicensedUser";


import { AssignLicenseGuide } from "./AssignLicenseGuide";

export function AssignLicenses(props: {orgid:string, usermail:string}) {
 

  const [me, setme] = useState<any>(undefined);
  const [lisencedusers, setLicensedusers] = useState<any>([]);
  const [newuser, setNewuser] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [adminchecked, setAdminchecked] = useState<boolean>(false);
  const [domains, setdomains] = useState<string[]>([]);
  const [inputItems, setInputItems] = useState<any[]>([]);
  const [initialloading, setInitialloading] = useState<boolean>(true);
  const [searcquery, setsearcquery] = useState<string>("");
  const [totalLicensescount, setTotalLicensescount] = useState<number>(0);

  useEffect(()=>{
  
      try
      {
        log('AssignLicenses', props.orgid, props.usermail);
       
        
        try
        {
          getlicenses(props.orgid, props.usermail).then(()=>{setInitialloading(false);setLoading(false);});
          
        } catch (ex:any)
        {log("Verify user error occurred", ex);}
       

        props.usermail.split('@').length > 1 ? setdomains([props.usermail.split('@')[1]]) : setdomains([]);
        
        
      }
      catch (graphex)
      {
        log("API Load error", graphex);
        
      }
    }, []);
  
  const getlicenses = async (orgid:string, email:string):Promise<void> => 
  {
    let formattedorgid = orgid;
    let usermail = email;
    const fullfillmentrequestOptions = {
      method: 'GET',
    };
    let orglicensesresponse = await fetch('https://plannerganttapi.azurewebsites.net/OrgLicenses?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&orgid=' + formattedorgid, fullfillmentrequestOptions)
    let orglicenses = await orglicensesresponse.json();
    log("orglicenses", orglicenses);

    let userlinesedresponse = await fetch('https://plannerganttapi.azurewebsites.net/LicensedUsers?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&orgid=' + formattedorgid, fullfillmentrequestOptions)
    let userlinesed = await userlinesedresponse.json();
    log("getlicenses", userlinesed);
    

    let adminusersresponse = await fetch('https://plannerganttapi.azurewebsites.net/AdminUsers?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&orgid=' + formattedorgid, fullfillmentrequestOptions)
    let adminusers = await adminusersresponse.json();
    let userlicenses:any[] = [];
    let dropdownitems:any[] = [];
    log("adminuser", adminusers);
    for (let i=0;i<userlinesed.length;i++)
    {
      let adminperm = false;
      if (adminusers.includes(userlinesed[i].toLowerCase()))
      {
        adminperm = true;
      }
      userlicenses.push({mail:userlinesed[i].toLowerCase(), admin:adminperm});
      dropdownitems.push(userlinesed[i].toLowerCase());
    }
    setLicensedusers(userlicenses);
    if (dropdownitems.indexOf(props.usermail.toLowerCase()) == -1)
    {
      setInputItems([...inputItems, props.usermail.toLowerCase()]);
    }
    let alldomains = domains;
    alldomains.push(props.usermail.split('@')[1]);
    for (let i=0;i<userlinesed.length;i++)
    {
      if (userlinesed[i].split('@').length > 1 && alldomains.indexOf(userlinesed[i].split('@')[1]) == -1)
      {
        alldomains.push(userlinesed[i].split('@')[1]);
      }
    }
    setdomains(alldomains);

    let licensescountrsresponse = await fetch('https://plannerganttapi.azurewebsites.net/OrgLicenses?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&orgid=' + formattedorgid, fullfillmentrequestOptions)
    let licensescountrs = await licensescountrsresponse.json();
    let totallicenses = 0;
    if (!isNaN(parseInt(licensescountrs)))
    {
      totallicenses = parseInt(licensescountrs);
    }
    setTotalLicensescount(totallicenses);
    
  }
  const assignlicense = async (email:string):Promise<void> => 
  {
    try
    {
      if (totalLicensescount-lisencedusers.length <= 0)
      {
        alert('No more licenses available, please purchase more licenses from the Microsoft 365 subscription center');
        return;
      }
        setLoading(true);
        if (!isemail(email)) 
        {
          alert('Invalid email address');
          setLoading(false);
          return;
        } 
        let formattedorgid = props.orgid;

        const fullfillmentrequestOptions = {
        method: 'POST',
        };
        let assignresponse = await fetch('https://plannerganttapi.azurewebsites.net/LicenseUser?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&useremail='+email+'&orgid=' + formattedorgid + '&license=assign', fullfillmentrequestOptions)
        if (assignresponse.status == 200)
        {
          setLicensedusers([...lisencedusers, {mail:email, admin:true}]);
          setNewuser("");
          if (email.split('@').length > 1 && domains.indexOf(email.split('@')[1]) == -1)
          {
            setdomains([...domains, email.split('@')[1]]);
          }
          let adminresponse = await fetch('https://plannerganttapi.azurewebsites.net/AdminUser?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&useremail='+email+'&orgid=' + formattedorgid + '&license=assign', fullfillmentrequestOptions)
          if (adminresponse.status != 200)
          {
            log("adminchecked error", adminresponse);
          } 
        } else
        {
            log("assignlicense error", assignresponse);
        }
        inputItems.splice(inputItems.indexOf(email)-1, 1);
        setInputItems([...inputItems]);
        log("assign", assignresponse);
        setLoading(false);
    } catch (ex:any)
    {
      log("assignlicense error", ex);
    }
  }
  
  const unassignlicense = (email:string):void => 
  {
    try
    {
      lisencedusers.splice(lisencedusers.map((u:any) => u.mail).indexOf(email), 1);
      setLicensedusers([...lisencedusers]);
    } catch (ex:any)
    {
      log("unassignlicense error", ex);
    }
  }
  const admincheckchanged = (email:string, adminchecked:boolean):void => 
  {
    try
    {
      
      let newlicensedusers:any[] = [];
      lisencedusers.map((user:any)=>{
        if (user.mail == email)
        {
          newlicensedusers.push({mail:user.mail, admin:adminchecked});
        } else
        {
          newlicensedusers.push(user);
        }
      });
      if (newlicensedusers.filter((u:any)=>u.admin ==true).length <= 0)
      {
        alert('At least one user must be an adble to assign licenses');
        return;
      }
      setLicensedusers([...newlicensedusers]);
    } catch (ex:any)
    {
      log("unassignlicense error", ex);
    }
  }
  
  return (
    <>
      {initialloading? 
        <Loader label="Loading subscription..." style={{height:'95vh'}} />
      :
      <>
        {totalLicensescount <= 0?
          <AssignLicenseGuide />
          :
          <div style={{marginLeft:'30vw', marginRight:'30vw', marginTop:'5vw', height:'90vh', overflowY:'scroll', minWidth:'38rem'}}>
            
              <Flex column space="between" style={{marginTop:20}} >
                <>
                  <Grid
                      style={{marginTop:'1rem'}}
                      columns="30rem 4rem 2rem"
                      rows={"10rem 4rem repeat(" + lisencedusers.length + ", 3rem)"}
                  >
                      <Text styles={{ gridColumn:'span 3', height:'10rem'}}>
                      
                        
                          <h1 style={{textAlign:'center'}}>Licenses</h1>
                          <div>Please allocate licenses by entering the email address of the individual to whom you intend to assign a license, and subsequently selecting the corresponding <TeamCreateIcon  style={{color:'rgb(91, 95, 199)', marginLeft:10, marginRight:10}} /> button.</div>
                          <br /><div>You have <b>{(totalLicensescount-lisencedusers.length)}</b> remaining licenses that can be assigned.</div>

                        
                      </Text>
                      <Dropdown
                        fluid
                        styles={{height:32, gridColumn:'span 2'}}
                        disabled={loading}
                        search
                        items={inputItems}
                        placeholder="User email or principal name..." 
                        value={newuser}  
                        onChange={(tev, evvalue)=>
                          {
                            log('onChange',evvalue?.value);
                            setNewuser(evvalue?.value?evvalue?.value as string:"")
                          }}
                        onSearchQueryChange={(e,v)=>
                          {
                            let searchquery = (v == undefined || v?.searchQuery == undefined)?"":v?.searchQuery;
                            log('onSearchQueryChange',v?.searchQuery);
                            let newemailsdropdown:any[] = [];
                            if (searchquery.length > 2 && searchquery.indexOf('@') >= 2 && searchquery.indexOf('@') == searchquery.length-1)
                            {
                              for (let i=0;i<domains.length;i++)
                              {
                                newemailsdropdown.push(searchquery + domains[i]);
                              }
                              setInputItems(newemailsdropdown);
                            } else if (isemail(searchquery))
                            {
                              setInputItems([searchquery]);
                            } else
                            {
                              setInputItems([props.usermail]);
                            }
                            if (searchquery != "")
                            {
                              setsearcquery(searchquery);
                            }
                            
                          }}
                        renderSelectedItem={(SelectedItem, props) => (
                          <SelectedItem {...props} header={`${props.header} (selected)`} />
                        )}
                      />
                      
                      <Button disabled={loading} onClick={()=>
                        {
                          assignlicense(newuser==""?searcquery:newuser).then(()=>setsearcquery(''));
                        }}  primary icon={<TeamCreateIcon />} title="Assign License" iconOnly />
                      {loading? 
                        <Loader label="Loading..." style={{height:200}} />
                      :
                        <>
                          {lisencedusers.map((user:any)=>{
                          return  <>
                                <LicensedUser orgid={props.orgid} user={user.mail} adminchecked={user.admin} unassignlicense={unassignlicense} admincheckchanged={admincheckchanged} />
                              </>
                          })}
                        </>
                      }
                  </Grid>
                  <div style={{position:'absolute', right:10, bottom:10}}><a href="https://admin.microsoft.com/adminportal/home#/subscriptions" target="_blank">Purchase licenses</a></div>
                </>
              </Flex> 
            
          </div>
        }
      </>
    }
    </>
  );
}
