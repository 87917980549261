import { useContext, useEffect, useState } from "react";
import { Button, Image, Menu, ParticipantRemoveIcon, TeamCreateIcon, Tooltip } from "@fluentui/react-northstar";
import "./Playground.css";
import { EditCode } from "../../authentication/EditCode";
import { Deploy } from "../../authentication/Deploy";
import { Publish } from "../../authentication/Publish";
import { AddSSO } from "../../authentication/AddSSO";
import { PlannerPPM } from "./PlannerPPM";
import {BrowserView, MobileView, TabletView} from 'react-device-detect';

import "../../../index.css";
import { PlannerPPMMobile } from "../mobile/PlannerPPMMobile";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsFxContext } from "../../Context";
import { log } from "../../helper";
import { AssignLicenses } from "../../subscription/AssignLicenses";


export function Playground(props: { environment?: string }) {
  const { environment } = {
    environment: window.location.hostname === "localhost" ? "local" : "azure",
    ...props,
  };
  const { themeString } = useContext(TeamsFxContext);
  const [userlicensed, setUserlicensed] = useState<boolean>(true);
  const [licensenavigated, setLicensenavigated] = useState<boolean>(false);
  const [usermail, setusermail] = useState<string>('');
  const [orgid, setorgid] = useState<string>('');
  const [useradmin, setUseradmin] = useState<boolean>(false);
  const [navigatedToPlan, setNavigatedToPlan] = useState<boolean>(false);

  useEffect(()=>{
    log("color theme", themeString);
    document.body.style.backgroundColor = themeString === "default" ? "#eeeeee" : "rgb(45, 44, 44)";
  }, [themeString]);
  useEffect(()=>{

    setTimeout(() => {
      microsoftTeams.app.initialize();
      microsoftTeams.app.notifyAppLoaded();
      microsoftTeams.app.notifySuccess();
      
      
    }, 500);
    
    
  }, []);
  const  licensehandler = async(licensed:boolean, lorgid:string, lusermail:string, admin:boolean, nootheradmins:boolean) =>{
    if (nootheradmins)
    {
      setorgid(lorgid);
      setusermail(lusermail);
      setUseradmin(true);
      setUserlicensed(true);
      setLicensenavigated(true);
    } else 
    {
      setorgid(lorgid);
      setusermail(lusermail);
      setUseradmin(admin);
      if (licensed)
      {
        setUserlicensed(true);
      } else
      {
        setUserlicensed(false);
      }
    }
  }
  const  licensehandlermobile = async(licensed:boolean, lorgid:string, lusermail:string, admin:boolean, nootheradmins:boolean) =>{
    setorgid(lorgid);
    setusermail(lusermail);
    setUseradmin(false);
    setUserlicensed(true);
    setLicensenavigated(false);
    
  }

  return (
    <div>
      {!navigatedToPlan && useradmin && <div style={{position:'absolute', top:'2rem', right:'8rem'}}>
          <Button className="subemailbtn" onClick={()=>{setLicensenavigated(!licensenavigated)}} content={licensenavigated?"Close":""} icon={<Tooltip content="Add or remove licenses license from users" trigger={<TeamCreateIcon />} />}  iconOnly={!licensenavigated} iconPosition="before" primary={!licensenavigated} style={{zIndex:999}}/>
        </div>}
      {userlicensed && !licensenavigated ?
        <>
        
          <BrowserView>
            <div className="welcome page">
              <div className="narrow page-padding">
                <PlannerPPM licensehandler={licensehandler} navigateToPlan={(n:boolean) => {setNavigatedToPlan(n)}} />
              </div>
            </div>
          </BrowserView>
          <TabletView>
            <div className="welcome page">
              <div className="narrow page-padding">
                <PlannerPPM licensehandler={licensehandler} navigateToPlan={(n:boolean) => {setNavigatedToPlan(n)}} />
              </div>
            </div>
          </TabletView>
          <MobileView>
          <div>
              {/*<div style={{position:'absolute', bottom:5, right:5}}>Mobile V17</div>*/}
              <div className="mobilenarrow mobile-page-padding">
                <PlannerPPMMobile licensehandler={licensehandlermobile} />
              </div>
            </div>
          </MobileView>
        </>
        : 
        (useradmin?
          <AssignLicenses orgid={orgid} usermail={usermail}  />
          :
          <h1 style={{textAlign:'center'}}>You do not have permissions to manage licenses, please ask your administrator to grant you access here</h1>
        )
      }
    </div>
  );
}
